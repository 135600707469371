import React, { useEffect, useState } from "react";
import { commonApiGet } from "../../utils/api";
import { notificationTopup } from "../../utils/NotificationTopup";
import CommonCardView from "../vision/CommonCardView";

export const Holiday = () => {
  const [tradingHoliday, setTradingHoliday] = useState([]);
  const [clearingHoliday, setClearingHoliday] = useState([]);

  const [whichHoliday, setWhichHoliday] = useState("TRADING");
  useEffect(() => {
    (async () => {
      await commonApiGet("/utils/holidays?type=trading")
        .then((res) => {
          setTradingHoliday(res.data.CM);
        })
        .catch((err) => {
          notificationTopup(err.message);
        });
    })();
    (async () => {
      await commonApiGet("/utils/holidays?type=clearing")
        .then((res) => {
          setClearingHoliday(res.data.CM);
        })
        .catch((err) => {
          notificationTopup(err.message);
        });
    })();
  }, []);
  return (
    <CommonCardView
      id="commonCard_holiday"
      header="Market Holidays"
      headerAlign="left"
      style={{
        position: "relative",
        fontSize: "14px",
        fontWeight: "700",
      }}
      headerInfo={{
        show: true,
        desc: "Market Holidays",
        id: "market-holiday",
      }}
    >
      <div className="non-trans-bg row px-1" style={{
        position: 'sticky',
        top: 0,
      }}>
        <div className="p-2 col-6">
          <div
            className={
              (whichHoliday == "TRADING"
                ? "general-nmphc-in-chart-i-des"
                : "general-nmphc-des") + " text-center mt-0 c-pointer"
            }
            onClick={() => {
              setWhichHoliday("TRADING");
            }}
          >
            TRADING
          </div>
        </div>
        <div className="p-2 col-6">
          <div
            className={
              (whichHoliday == "CLEARING"
                ? "general-nmphc-in-chart-i-des"
                : "general-nmphc-des") + " text-center mt-0 c-pointer"
            }
            onClick={() => {
              setWhichHoliday("CLEARING");
            }}
          >
            CLEARING
          </div>
        </div>
      </div>
      <div
        style={{
          overflowY: "scroll",
        }}
      >
        <table className="tab-border">
          <tbody>
            {(whichHoliday == "TRADING" ? tradingHoliday : clearingHoliday).map(
              (data, index) => {
                return (
                  <tr
                    key={index}
                    style={
                      data["weekDay"] == "Saturday" ||
                      data["weekDay"] == "Sunday"
                        ? {
                            opacity: "0.5",
                          }
                        : {}
                    }
                  >
                    <td>{data["tradingDate"]}</td>
                    <td>{data["weekDay"]}</td>
                    <td>{data["description"]}</td>
                  </tr>
                );
              }
            )}
          </tbody>
        </table>
      </div>
    </CommonCardView>
  );
};
