import React, { useCallback, useEffect, useRef, useState } from "react";
import { formatCurrency, prepareCyptoData } from "../utils/functions";
import { defaultValuesForCryptoPrice } from "../utils/defaultCardValue";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import { useSelector } from "react-redux";
import {
  chartColor,
  socketFieldForMultiExchange,
  tickerSymsExchange,
} from "../utils/configs";
import SparklineChart from "./SparklineChart";
import AskBidChart from "./AskBidChart";
import OhlcuclcComp from "./OhlcuclcComp";

export const HomePageCryptoCardCombined = ({
  cryptoInfo,
  forTicker,
  forSpot,
  sparklinesym,
}) => {
  const [cryptoInfoState, setCryptoInfoState] = useState(cryptoInfo);
  const [cryptoInfoPreState, setCryptoInfoPreState] = useState(cryptoInfo);
  const [cryptoInfoSpotPrice, setCryptoInfoSpotPrice] = useState({});

  const cryptoInfoSpotPriceRef = useRef(cryptoInfoSpotPrice);
  const getOldVals = useCallback(() => cryptoInfoSpotPriceRef.current, []);

  useEffect(() => {
    cryptoInfoSpotPriceRef.current = cryptoInfoSpotPrice;
  }, [cryptoInfoSpotPrice]);

  const [ApiLoad, setApiLoad] = useState(0);
  const [DefaultApiLoad, setDefaultApiLoad] = useState(0);
  const exchange = useSelector((state) => state.exchange);
  const [loadohlcuclc, setLoadohlcuclc] = useState(false);
  let prepareDataToLoad = cryptoInfoState;

  useEffect(() => {
    if (ApiLoad !== 0) {
      let sockets = socketFieldForMultiExchange[exchange];
      let socket = [];
      for (const key in sockets) {
        if (sockets.hasOwnProperty(key)) {
          const socketURL = sockets[key];
          socket[key] = new WebSocket(socketURL);

          socket[key].onopen = () => {
            console.log("WebSocket connected");
            const channels = [
              {
                name: "v2/ticker",
                symbols: [tickerSymsExchange[forTicker][key]],
              },
            ];

            const subscriptionMessage = JSON.stringify({
              type: "subscribe",
              payload: {
                channels: channels,
              },
            });

            socket[key].send(subscriptionMessage);
            console.log("Subscribed to v2 ticker");
          };

          socket[key].onmessage = (event) => {
            const data = JSON.parse(event.data);

            if (data.type === "v2/ticker") {
              data[key] = true;

              setCryptoInfoPreState((prevState) => {
                const newState = {
                  ...prevState,
                  [key]: prepareCyptoData(data, prevState[key]),
                };
                return newState;
              });
            }
          };

          socket[key].onclose = () => {
            console.log("WebSocket disconnected");
          };
        }
      }
      return () => {
        for (const key in sockets) {
          if (sockets.hasOwnProperty(key)) {
            socket[key].close();
          }
        }
      };
    }
  }, [ApiLoad, exchange]);

  useEffect(() => {
    if (ApiLoad !== 0) {
      let sockets = socketFieldForMultiExchange[exchange];
      let socket = [];
      for (const key in sockets) {
        if (sockets.hasOwnProperty(key)) {
          if (key !== "INDIA") continue;
          const socketURL = sockets[key];
          socket[key] = new WebSocket(socketURL);

          socket[key].onopen = () => {
            console.log("WebSocket connected");
            const channels = [
              {
                name: "v2/spot_price",
                symbols: [forSpot],
              },
            ];

            const subscriptionMessage = JSON.stringify({
              type: "subscribe",
              payload: {
                channels: channels,
              },
            });

            socket[key].send(subscriptionMessage);
            console.log("Subscribed to Spot_price");
          };

          socket[key].onmessage = (event) => {
            const data = JSON.parse(event.data);
            let currentData = getOldVals();
            let spotDataToSet = {
              inr: formatCurrency(
                parseFloat(data.p || null) * 85,
                "INR",
                false
              ),
              usd: formatCurrency(parseFloat(data.p || null), "USD", false),
              incDec: {
                oldSpot:
                  parseFloat(
                    (currentData.usd || "").replace("$", "").replace(",", "")
                  ) || 0,
                newSpot: parseFloat(data.p || 0),
              },
            };
            setCryptoInfoSpotPrice(spotDataToSet);
          };

          socket[key].onclose = () => {
            console.log("WebSocket disconnected");
          };
        }
      }
      return () => {
        socket["INDIA"].close();
      };
    }
    console.log("Hello")
  }, [ApiLoad, exchange]);

  useEffect(() => {
    async function intiateDefaultValues() {
      let apis = socketFieldForMultiExchange[exchange];
      let objDefaultVals = {};
      for (const key in apis) {
        await defaultValuesForCryptoPrice(forTicker, key).then((data) => {
          objDefaultVals[key] = data;
        });
      }
      setCryptoInfoPreState(objDefaultVals);
    }
    if (DefaultApiLoad) intiateDefaultValues();
  }, [DefaultApiLoad, exchange]);

  useEffect(() => {
    setCryptoInfoState(cryptoInfo);
    setDefaultApiLoad(1);
    setApiLoad(0);
  }, [exchange]);

  useEffect(() => {
    let prevState = cryptoInfoPreState;

    let combinedDataToBe = initDataStruct();
    for (const key in cryptoInfoPreState) {
      if (cryptoInfoPreState.hasOwnProperty(key)) {
        const val = cryptoInfoPreState[key];

        combinedDataToBe.id = val.id;
        combinedDataToBe.symbol = val.symbol;
        combinedDataToBe.markPrice = val.newData.mark_price;
        combinedDataToBe.inr.spotPrice = val.newData.spot_price;
        combinedDataToBe.inr.volume += parseFloat(val.newData.turnover_usd);
        combinedDataToBe.inr.oi += parseFloat(val.newData.oi_value_usd);
        combinedDataToBe.inr.oiChange += parseFloat(
          val.newData.oi_change_usd_6h
        );
        combinedDataToBe.usd.spotPrice = prevState.INDIA.newData.spot_price;
        combinedDataToBe.usd.volume += parseFloat(val.newData.turnover_usd);
        combinedDataToBe.usd.oi += parseFloat(val.newData.oi_value_usd);
        combinedDataToBe.usd.oiChange += parseFloat(
          val.newData.oi_change_usd_6h
        );
        combinedDataToBe.quotes.ask_size += parseFloat(
          val.newData.quotes.ask_size
        );
        combinedDataToBe.quotes.bid_size += parseFloat(
          val.newData.quotes.bid_size
        );
        combinedDataToBe.quotes.best_ask += parseFloat(
          val.newData.quotes.best_ask
        );
        combinedDataToBe.quotes.best_bid += parseFloat(
          val.newData.quotes.best_bid
        );
        combinedDataToBe.quotes.mark_iv += parseFloat(
          val.newData.quotes.mark_iv
        );
        combinedDataToBe.funding_rate += parseFloat(val.newData.funding_rate);
        combinedDataToBe.mark_change_24h += parseFloat(
          val.newData.mark_change_24h
        );
        combinedDataToBe.high = parseFloat(val.newData.high);
        combinedDataToBe.low = parseFloat(val.newData.low);
        combinedDataToBe.open = parseFloat(val.newData.open);
        combinedDataToBe.close = parseFloat(val.newData.close);
        if (val.symbol.indexOf("USDT") > 0) {
          combinedDataToBe.price_band.upper_limit.gl =
            val.newData.price_band?.upper_limit || 0;
          combinedDataToBe.price_band.lower_limit.gl =
            val.newData.price_band?.lower_limit || 0;
        } else {
          combinedDataToBe.price_band.lower_limit.in =
            val.newData.price_band?.lower_limit || 0;
          combinedDataToBe.price_band.upper_limit.in =
            val.newData.price_band?.upper_limit || 0;
        }
      }
    }
    let temp = prepareCyptoData({}, combinedDataToBe);
    setCryptoInfoState(temp);
    setApiLoad(1);
  }, [cryptoInfoPreState]);

  const initDataStruct = () => ({
    id: "",
    symbol: "",
    markPrice: "",
    inr: {
      spotPrice: "",
      volume: 0,
      oi: 0,
      oiChange: 0,
    },
    usd: {
      spotPrice: "",
      volume: 0,
      oi: 0,
      oiChange: 0,
    },
    quotes: {
      ask_size: 0,
      best_ask: 0,
      best_bid: 0,
      bid_size: 0,
      mark_iv: 0,
    },
    funding_rate: 0,
    mark_change_24h: 0,
    high: 0,
    low: 0,
    open: 0,
    close: 0,
    price_band: {
      upper_limit: {
        in: 0,
        gl: 0,
      },
      lower_limit: {
        in: 0,
        gl: 0,
      },
    },
  });

  return (
    <SkeletonTheme inline={true} width={68} borderRadius={30}>
      <div id={cryptoInfoState.id} className="card general-nmphc-des">
        <div className="general-nmphc-des div-pill-right">{exchange}</div>
        <div className="general-nmphc-des div-pill-left">
          {tickerSymsExchange[forTicker]["INDIA"]}
        </div>
        <SparklineChart sparklinesym={sparklinesym} />
        <AskBidChart
          quotes={{
            ask_size: cryptoInfoState.quotes?.ask_size || 0,
            best_ask: (cryptoInfoState.quotes?.best_ask || 0) / 2,
            best_bid: (cryptoInfoState.quotes?.best_bid || 0) / 2,
            bid_size: cryptoInfoState.quotes?.bid_size || 0,
          }}
        />
        <h3>
          {cryptoInfoSpotPrice.inr || cryptoInfoState.inr?.spotPrice || (
            <Skeleton />
          )}{" "}
          <span
            style={{
              color:
                cryptoInfoState.mark_change_24h < 0
                  ? chartColor.green
                  : chartColor.red,
            }}
          >
            (
            {Math.abs(
              parseFloat((cryptoInfoState.mark_change_24h || 0) / 2) || 0
            ).toFixed(2) || <Skeleton />}
            %)
          </span>
        </h3>
        <h1>
          {cryptoInfoSpotPrice.usd || cryptoInfoState.usd?.spotPrice || (
            <Skeleton />
          )}{" "}
          {cryptoInfoSpotPrice.incDec?.newSpot >
          cryptoInfoSpotPrice.incDec?.oldSpot ? (
            <sup
              className="fas fa-caret-up"
              style={{
                color: chartColor.green,
              }}
            ></sup>
          ) : (
            <sup
              className="fas fa-caret-down"
              style={{
                color: chartColor.red,
              }}
            ></sup>
          )}
        </h1>
        <p>
          Mark Price: <span>{cryptoInfoState.markPrice || <Skeleton />}</span>
        </p>
        <p>
          Funding Rate:{" "}
          <span>
            {parseFloat((cryptoInfoState.funding_rate || 0) / 2).toFixed(4) || (
              <Skeleton />
            )}
          </span>{" "}
          | IV:{" "}
          <span>
            {parseFloat((cryptoInfoState.quotes?.mark_iv || 0) / 2).toFixed(
              2
            ) || <Skeleton />}
          </span>
        </p>

        <p>
          Volume 24h: <span>{cryptoInfoState.usd?.volume || <Skeleton />}</span>{" "}
          | <span>{cryptoInfoState.inr?.volume || <Skeleton />}</span>
        </p>
        <p>
          Open Interest: <span>{cryptoInfoState.usd?.oi || <Skeleton />}</span>{" "}
          | <span>{cryptoInfoState.inr?.oi || <Skeleton />}</span>
        </p>
        <p>
          OI change 6h:{" "}
          <span
            style={{
              color:
                (cryptoInfoState.usd?.oiChange || 0)[0] == "-"
                  ? chartColor.red
                  : chartColor.green,
              fontWeight: "bold",
            }}
          >
            {cryptoInfoState.usd?.oiChange || <Skeleton />}
          </span>{" "}
          |{" "}
          <span
            style={{
              color:
                (cryptoInfoState.usd?.oiChange || 0)[0] == "-"
                  ? chartColor.red
                  : chartColor.green,
              fontWeight: "bold",
            }}
          >
            {cryptoInfoState.inr?.oiChange || <Skeleton />}
          </span>
        </p>
        <center>
          <i
            style={{
              cursor: "pointer",
            }}
            className={
              loadohlcuclc ? "fas fa-chevron-up" : "fas fa-chevron-down"
            }
            onClick={() => setLoadohlcuclc(!loadohlcuclc)}
          ></i>
        </center>
        {loadohlcuclc ? (
          <OhlcuclcComp
            open={cryptoInfoState.open}
            high={cryptoInfoState.high}
            low={cryptoInfoState.low}
            close={cryptoInfoState.close}
            uc={cryptoInfoState.price_band.upper_limit}
            lc={cryptoInfoState.price_band.lower_limit}
            combined={true}
          />
        ) : (
          <></>
        )}
      </div>
    </SkeletonTheme>
  );
};
