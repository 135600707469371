import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { exchangeTo } from "../utils/glob-redux/store";
import {
  CACHE_PREFIX,
  commonApiPost,
  getCookie,
  removeUserSession,
  setCookie,
} from "../utils/api";
import VisEquitySearch from "./vision/VisEquitySearch";
import { notificationTopup } from "../utils/NotificationTopup";
import { GridIconNorm } from "../views/svgs/GridIcon";
import {
  ButtonDropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap";
function Navbar() {
  const exchanges = ["INDIA", "GLOBAL", "COMBINED"];

  // const
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const exchange = useSelector((state) => state.exchange);
  const theme = useSelector((state) => state.theme);

  const dispatch = useDispatch();
  const toggle = () => setDropdownOpen((prevState) => !prevState);
  return (
    <div className="navbar general-nmphc-des mt-4">
      <div className="sitename ms-2">
        <VisEquitySearch />
        <Link to="/">
          <img src="/transparent-logo.webp" alt="Quibblefrost" height={36} />
        </Link>
        <Link to="/">
          <i className="">
            {" "}
            <h2 style={{ fontWeight: 900, marginInlineStart: "-9px" }}>
              uibblefrost
            </h2>
          </i>
        </Link>
      </div>
      <div className="accountmenu">
        <ul>
          {getCookie("access_token") !== "" ? (
            <li>
              <i
                className="fas fa-power-off"
                onClick={async () => {
                  await commonApiPost(`/user/signout`)
                    .then((res) => "")
                    .catch((err) => notificationTopup(err.message));
                  Object.keys(sessionStorage).forEach((key) => {
                    if (key.startsWith(CACHE_PREFIX)) {
                      sessionStorage.removeItem(key);
                    }
                  });
                  document.cookie.split(";").forEach((cookie) => {
                    const eqPos = cookie.indexOf("=");
                    const name =
                      eqPos > -1 ? cookie.substring(0, eqPos) : cookie;
                    document.cookie =
                      name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT;";
                  });
                  removeUserSession();
                }}
                style={{
                  cursor: "pointer",
                  transition: "2s",
                  marginTop: "10px",
                }}
              ></i>
            </li>
          ) : (
            <></>
          )}

          {location.href.includes(":3000") ? (
            <li>
              <i
                className="fab fa-d-and-d"
                onClick={() => {
                  setCookie("access_token", "a", 0.04166666667);
                }}
                style={{
                  cursor: "pointer",
                  transition: "2s",
                  marginTop: "10px",
                }}
              ></i>
            </li>
          ) : (
            <></>
          )}

          {/* <li>
            <i
              className="fas fa-file-code"
              onClick={() => location.replace("/nse-docs")}
              style={{
                cursor: "pointer",
                transition: "2s",
                marginTop: "10px",
              }}
            ></i>
          </li> */}
          <li>
            <i
              className={
                theme === "light" ? "fa-solid fa-moon" : "fa-solid fa-sun"
              }
              onClick={() => dispatch({ type: "SWITCH_THEME" })}
              style={{
                cursor: "pointer",
                transition: "2s",
                marginTop: "10px",
              }}
            ></i>
          </li>

          <li>
            <i
              onClick={() => {
                dispatch({ type: "TOOLBOX_TOOLBOX" });
                dispatch({ type: "SHOW_VISSEARCH" });
              }}
              style={{
                cursor: "pointer",
                transition: "2s",
                marginTop: "10px",
              }}
            >
              <GridIconNorm
                style={{
                  marginTop: "10px",
                }}
              />
            </i>
          </li>

          <li>
            <i
              onClick={() => {
                dispatch({ type: "TOOLBOX_SEARCH" });
                dispatch({ type: "SHOW_VISSEARCH" });
              }}
              style={{
                cursor: "pointer",
                transition: "2s",
                marginTop: "10px",
              }}
              className="fas fa-magnifying-glass"
            ></i>
          </li>

          {exchange !== "NSE" ? (
            <li>
              <ButtonDropdown isOpen={dropdownOpen} toggle={toggle}>
                <DropdownToggle
                  caret
                  size="sm"
                  color="transparent"
                  className="general-nmphc-des text-color-themed"
                >
                  {exchange}
                </DropdownToggle>
                <DropdownMenu className="general-nmphc-des non-trans-bg">
                  {exchanges.map((exchange, index) => (
                    <DropdownItem
                      key={index}
                      className="bg-set-on-active text-color-themed"
                      onClick={() => dispatch(exchangeTo(exchange))}
                    >
                      {exchange}
                    </DropdownItem>
                  ))}
                </DropdownMenu>
              </ButtonDropdown>
            </li>
          ) : (
            <></>
          )}
        </ul>
      </div>
    </div>
  );
}

export default Navbar;
