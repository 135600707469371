import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { maxViewContent } from "../../utils/configs";

const ModalQF = ({
  dataClearFunc,
  dataClearTo,
  header,
  desc,
  closeVar,
  closeVal,
  data,
  isExpandBtn,
  expandFunc,
  expandVal,
  secondPage = {
    able: false,
    showSecond: false,
    data: <></>,
    backFunction: () => {
      return;
    },
    header: null,
  },
  responsive = false,
  responsiveH = false,
  closeBtn = true,
}) => {
  const theme = useSelector((state) => state.theme);

  useEffect(() => {
    const handleKeyPress = (event) => {
      if (event.key === "f" || event.key === "F") {
        // Toggle expand on 'F' key press
        if (isExpandBtn) {
          expandFunc(!expandVal);
        }
      }
      if (event.key === "Escape") {
        // Close modal on 'Esc' key press
        dataClearFunc(dataClearTo);
        if (expandVal && isExpandBtn) {
          expandFunc(false);
        }
        closeVar(closeVal);
      }
    };

    document.addEventListener("keydown", handleKeyPress);

    // Cleanup listener on unmount
    return () => {
      document.removeEventListener("keydown", handleKeyPress);
    };
  }, [
    expandFunc,
    expandVal,
    isExpandBtn,
    closeVar,
    closeVal,
    dataClearFunc,
    dataClearTo,
  ]);

  return (
    <div
      className="no-des-shadow"
      style={{
        position: "absolute",
        top: "0px",
        left: "0px",
        height: "100%",
        ...(responsiveH
          ? {}
          : {
              minHeight: "300px",
            }),
        width: "100%",
        zIndex: "98",
        backgroundColor:
          theme === "dark" ? "rgba(0,0,0,0.7)" : "rgba(179,189,199,0.9)",
        overflow: "hidden",
        borderRadius: "15px",
      }}
    >
      <div
        style={{
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          position: "relative",
          maxHeight: "90%",
          ...(responsiveH
            ? {}
            : {
                minHeight: "300px",
              }),
          ...(responsive
            ? {
                display: "inline-block",
              }
            : {
                maxWidth: "90%",
                minWidth: "70%",
              }),
          zIndex: "99",
        }}
        className="general-nmphc-des p-2 non-trans-bg"
      >
        <center>
          <div
            style={{
              position: "relative",
            }}
          >
            <h3>
              {maxViewContent(
                secondPage.able &&
                  secondPage.showSecond &&
                  secondPage.header !== null ? (
                  <div
                    style={{
                      position: "relative",
                    }}
                  >
                    <i
                      onClick={() => {
                        secondPage.backFunction();
                      }}
                      className="fas fa-angle-left div-pill our-border rounded general-nmphc-des non-trans-bg"
                      style={{
                        cursor: "pointer",
                        position: "absolute",
                        top: "0px",
                        left: "10px",
                      }}
                    ></i>
                    <span className="mx-3">{secondPage.header}</span>
                  </div>
                ) : (
                  header
                ),
                30
              )}
            </h3>
            <span>{desc}</span>
          </div>
          <hr />
        </center>
        <div
          className="m-0 mb-1"
          style={{
            maxHeight: "77%",
            borderRadius: "15px",
            overflow: "scroll",
            ...(responsive
              ? {
                  padding: "0px 20px",
                }
              : {}),
          }}
        >
          {secondPage.able && secondPage.showSecond ? secondPage.data : data}
        </div>
      </div>
      <div
        style={{
          fontSize: "20px",
          position: "absolute",
          top: "10px",
          right: "10px",
          fontWeight: 900,
          zIndex: 100,
        }}
      >
        {isExpandBtn ? (
          <i
            onClick={() => expandFunc(!expandVal)}
            className={
              "div-pill our-border rounded general-nmphc-des non-trans-bg mx-3 " +
              (expandVal ? "fas fa-arrows-to-dot" : "fas fa-expand")
            }
            style={{
              cursor: "pointer",
            }}
          ></i>
        ) : (
          <></>
        )}
        {closeBtn ? (
          <i
            onClick={() => {
              // This does not works on expanded view?
              dataClearFunc(dataClearTo);
              if (expandVal && isExpandBtn) {
                expandFunc(false);
              }
              closeVar(closeVal);
            }}
            className="fa fa-close div-pill our-border rounded general-nmphc-des non-trans-bg"
            style={{
              cursor: "pointer",
            }}
          ></i>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};

export default ModalQF;
