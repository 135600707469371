import React, { useEffect, useState } from "react";
import { commonApiGet } from "../../utils/api";
import { notificationTopup } from "../../utils/NotificationTopup";
import QFLoader from "../vision/QFLoader";
import {
  ButtonDropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap";
import CashFiiDiiMonthlyChart from "./ChartComponents/FiiDiiMonthlyComponents/CashFiiDiiMonthlyChart";
import SebiDebtEquityMonthlyChart from "./ChartComponents/FiiDiiMonthlyComponents/SebiDebtEquityMonthlyChart";
import FnoMonthlyChart from "./ChartComponents/FiiDiiMonthlyComponents/FnoMonthlyChart";
import CommonCardView from "../vision/CommonCardView";
import VisOops from "../vision/VisOops";

const FiiDiiMonthly = () => {
  const options = {
    cash: "CASH",
    fno: "FNO INDEX",
    fno_stk: "FNO STOCK",
    mf_sebi: "MF SEBI",
    fii_sebi: "FII SEBI",
  };
  const [selectedOption, setSelectedOption] = useState("cash");
  const [dropdownOptionOpen, setDropdownOptionOpen] = useState(false);
  const ddootoggle = () => setDropdownOptionOpen((prevState) => !prevState);
  const [data, setData] = useState([]);
  const [loaderShow, setLoaderShow] = useState(true);
  const [errorShow, setErrorShow] = useState(false);

  useEffect(() => {
    (async () => {
      await commonApiGet(`/market/fii_dii?reqType=${selectedOption}`)
        .then((res) => {
          setData(res.data);
          setLoaderShow(false);
          setErrorShow(false);
        })
        .catch((err) => {
          notificationTopup(err.message);
          setErrorShow(true);
          setLoaderShow(false);
        });
    })();
  }, [selectedOption]);

  return (
    <CommonCardView
      id="commonCard_FiiDiiMonthly"
      header="FII/DII"
      headerInfo={{
        show: true,
        desc: "FII/DII Monthly Activity",
        id: "fii-dii-monthly",
      }}
      headerAlign="left"
      style={{
        position: "relative",
      }}
    >
      <ButtonDropdown
        isOpen={dropdownOptionOpen}
        toggle={ddootoggle}
        className="tab-weight absolute-tabGrp"
      >
        <DropdownToggle
          caret
          size="sm"
          color="transparent"
          className="general-nmphc-des text-color-themed mt-1"
          style={{
            fontSize: "12px",
          }}
        >
          {options[selectedOption]}
        </DropdownToggle>
        <DropdownMenu
          className="general-nmphc-des non-trans-bg"
          style={{
            maxHeight: "220px",
            overflowY: "scroll",
          }}
        >
          {Object.keys(options).map((val, key) => (
            <DropdownItem
              className="bg-set-on-active text-color-themed"
              key={`QF_HP_FIIDII_MNTH_OPT_${key}`}
              onClick={() => {
                setLoaderShow(true);
                setData([]);
                setSelectedOption(val);
              }}
              style={{
                fontSize: "12px",
              }}
              disabled={val === selectedOption ? true : false}
            >
              {options[val]}
            </DropdownItem>
          ))}
        </DropdownMenu>
      </ButtonDropdown>
      {errorShow ? (
        <VisOops />
      ) : loaderShow ? (
        <QFLoader />
      ) : (
        <div
          style={{
            overflowY: "scroll",
            overflowX: "hidden",
            height: "100%",
            marginTop: "-10px",
          }}
        >
          <div className="p-1">
            <div>
              {data.length === 0 ? (
                <QFLoader />
              ) : selectedOption === "cash" ? (
                <CashFiiDiiMonthlyChart data={data} />
              ) : selectedOption === "mf_sebi" ||
                selectedOption === "fii_sebi" ? (
                <SebiDebtEquityMonthlyChart data={data} />
              ) : selectedOption === "fno" || selectedOption === "fno_stk" ? (
                <FnoMonthlyChart
                  data={data}
                  cat={selectedOption === "fno" ? "Index" : "Stock"}
                />
              ) : (
                <></>
              )}
            </div>
          </div>
        </div>
      )}
    </CommonCardView>
  );
};

export default FiiDiiMonthly;
