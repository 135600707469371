import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Navbar from "../../components/Navbar";
import LivePrice from "../preLogin/components/LivePrice";
import { commonApiGet } from "../../utils/api";
import { notificationTopup } from "../../utils/NotificationTopup";
import QFLoader from "../../components/vision/QFLoader";
import VisOops from "../../components/vision/VisOops";
import VisOHLCUCLC from "../../components/vision/charts/VisOHLCUCLC";
import CommonCardView from "../../components/vision/CommonCardView";
import VisOrderBook from "../../components/vision/charts/VisOrderBook";
import VisPreviewChart from "../../components/vision/charts/VisPreviewChart";
import FundamentalPreview from "./FundamentalPreview";
import { chartColor, isLoggedin } from "../../utils/configs";
import { Helmet } from "react-helmet";
import IndicesFunda from "./IndicesFunda";
import { IconForAddtowl } from "../../components/vision/toolbox/VisAddToWatchlist";

const EquityPage = () => {
  const { exchange, symbol } = useParams();
  const [fullView, setFullView] = useState({});
  const [loaderShow, setLoaderShow] = useState(true);
  const [errorShow, setErrorShow] = useState(false);
  useEffect(() => {
    setLoaderShow(true);
    (async () => {
      await commonApiGet(
        `/stocks/getfullquote?symbol=${encodeURIComponent(
          symbol
        )}&exchange=${exchange.toUpperCase()}`,
        `EQUITY_PAGE_${exchange}_${symbol}`,
        false,
        false
      )
        .then((res) => {
          setFullView(res.data);
          setLoaderShow(false);
          setErrorShow(false);
        })
        .catch((err) => {
          notificationTopup(err.message);
          setLoaderShow(false);
          setErrorShow(true);
        });
    })();
  }, [exchange, symbol]);

  return (
    <div className="container-fluid pb-4">
      <Helmet>
        <title>{symbol} Info - Quibblefrost</title>
        <meta
          name="description"
          content={`Stay updated with the latest stock data and news for ${symbol} on the Quibblefrost. Discover real-time stock prices, market insights, and expert analysis.`}
        />
        <meta
          name="keywords"
          content={`${symbol}, ${exchange}, Quibblefrost, stock market updates, stock news, real-time stock prices, investment insights`}
        />
        <meta
          property="og:title"
          content={`${symbol} Stock Price - Live Updates from the ${exchange} Exchange - Quibblefrost`}
        />
        <meta
          property="og:description"
          content={`Get real-time updates on ${symbol} stock prices and the latest news from the ${exchange} exchange on Quibblefrost. Stay informed with market trends and investment opportunities.`}
        />
        <meta
          name="twitter:title"
          content={`Latest ${symbol} Stock Price - ${exchange} Exchange Updates - Quibblefrost`}
        />
        <meta
          name="twitter:description"
          content={`Follow ${symbol} for the latest stock price and news from the ${exchange} exchange on Quibblefrost. Keep your investments informed with timely updates and analysis.`}
        />
      </Helmet>
      <Navbar />
      <LivePrice />
      <CommonCardView
        id="EQUITY_PAGE_COMP"
        headerInfo={{
          show: true,
          id: `EQUITY_PAGE_COMP_tooltip`,
          desc: "Oh sure, because every investor just magically evolves from frantically clicking buy/sell buttons to sipping coffee while casually ‘diversifying’ their portfolio.",
        }}
        header={
          <span
            style={{
              textTransform: "uppercase",
            }}
          >
            {exchange} / {fullView.symbol}{" "}
            <span
              style={{
                textWrap: "nowrap",
              }}
            >
              <span
                style={{
                  fontSize: "large",
                  textWrap: "nowrap",
                }}
              >
                <i
                  className={
                    fullView.netChange < 0
                      ? "fas fa-arrow-down text-danger"
                      : "fas fa-arrow-up text-success"
                  }
                  style={{
                    marginInlineStart: "10px",
                  }}
                ></i>{" "}
                ₹{fullView.ltp}{" "}
              </span>
              <span
                style={{
                  color:
                    fullView.netChange < 0 ? chartColor.red : chartColor.green,
                }}
              >
                <span
                  style={{
                    fontSize: "small",
                  }}
                >
                  ₹{fullView.netChange} ({fullView.percentChange}%)
                </span>
              </span>{" "}
              {["NFO", "BFO"].includes(fullView.exchange) ? (
                <span
                  className="div-pill our-border general-nmphc-des"
                  style={{ fontSize: "x-small", textWrap: "nowrap" }}
                >{`Lot size: X${fullView.promisedData.lotsize}`}</span>
              ) : (
                <></>
              )}
            </span>
          </span>
        }
        children={
          <div className="main-content pb-2">
            <div className="row g-3 ">
              <div
                className="col-12"
                style={{
                  minHeight: "500px",
                }}
              >
                {loaderShow ? (
                  <QFLoader />
                ) : errorShow ? (
                  <VisOops />
                ) : (
                  <div
                    className="row"
                    style={{
                      fontSize: "12px",
                    }}
                  >
                    <div
                      className="col-md-6 vert-spacebet"
                      style={{ position: "relative" }}
                    >
                      <VisPreviewChart
                        name={
                          fullView?.companyName || fullView?.promisedData?.name
                        }
                        symbol={fullView.tradingSymbol}
                        exchange={fullView.exchange}
                      />

                      <hr />
                      <VisOrderBook data={fullView.depth} />
                      <hr className="d-block d-md-none" />
                    </div>
                    <div className="col-md-6  vert-spacebet">
                      {(
                        fullView.promisedData?.instrumenttype || "NA"
                      ).toUpperCase() === "AMXIDX" ? (
                        <>
                          <IndicesFunda fullView={fullView} />
                        </>
                      ) : (
                        <>
                          <FundamentalPreview fullView={fullView} />
                        </>
                      )}
                      <hr />

                      <VisOHLCUCLC
                        open={fullView.open}
                        high={fullView.high}
                        low={fullView.low}
                        close={fullView.close}
                        uc={fullView.upperCircuit}
                        lc={fullView.lowerCircuit}
                        ltp={fullView.ltp}
                        extraData={{
                          "52WeekHigh": fullView["52WeekHigh"],
                          "52WeekLow": fullView["52WeekLow"],
                          vwap: fullView.vwap,
                          avgPrice: fullView.avgPrice,
                        }}
                      />
                    </div>
                  </div>
                )}
              </div>
            </div>
            {isLoggedin() ? <IconForAddtowl symbol={fullView.symbol} /> : <></>}
          </div>
        }
        responsiveHeight={true}
        className="mt-2"
        style={{
          position: "relative",
        }}
      />
      <br />
      <br />
    </div>
  );
};

export default EquityPage;
