import React, { useEffect, useState } from "react";
import { commonApiGet } from "../../utils/api";
import { notificationTopup } from "../../utils/NotificationTopup";
import CommonCardView from "../vision/CommonCardView";
import {
  ButtonDropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap";
import VisOops from "../vision/VisOops";
import QFLoader from "../vision/QFLoader";
import { groupINRCurrencyNSE } from "../../utils/functions";
import { dbyTodb } from "../../utils/configs";

const Deals = () => {
  const [data, setData] = useState({});
  const [loaderShow, setLoaderShow] = useState(true);
  const [errorShow, setErrorShow] = useState(false);
  const [search, setSearch] = useState("");
  const [options, setOptions] = useState({
    BULK_DEALS: {
      data: [],
      number: 0,
      name: "BULK DEALS",
    },
    // SHORT_DEALS: {
    //   data: [],
    //   number: 0,
    //   name: "SHORT DEALS",
    // },
    BLOCK_DEALS: {
      data: [],
      number: 0,
      name: "BLOCK DEALS",
    },
  });
  const [selectedOption, setSelectedOption] = useState("BULK_DEALS");
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const ddtoggle = () => setDropdownOpen((prevState) => !prevState);
  useEffect(() => {
    (async () => {
      await commonApiGet("/analysis/deals")
        .then((res) => {
          setData(res.data);
          setOptions({
            BULK_DEALS: {
              data: res.data.BULK_DEALS_DATA,
              number: res.data.BULK_DEALS,
              name: "BULK DEALS",
            },
            // SHORT_DEALS: {
            //   data: res.data.SHORT_DEALS_DATA,
            //   number: res.data.SHORT_DEALS,
            //   name: "SHORT DEALS",
            // },
            BLOCK_DEALS: {
              data: res.data.BLOCK_DEALS_DATA,
              number: res.data.BLOCK_DEALS,
              name: "BLOCK DEALS",
            },
          });
          setLoaderShow(false);
          setErrorShow(false);
        })
        .catch((err) => {
          notificationTopup(err.message);
          setErrorShow(true);
          setLoaderShow(false);
        });
    })();
  }, []);

  console.log(options);

  return (
    <CommonCardView
      id="commonCard_deals"
      header="Bulk deals"
      headerAlign="left"
      style={{
        position: "relative",
      }}
      headerInfo={{
        show: true,
        desc: "BULK deals on stocks.",
        id: "deals-in-stocks",
      }}
    >
      <ButtonDropdown
        isOpen={dropdownOpen}
        toggle={ddtoggle}
        className="tab-weight absolute-tabGrp"
      >
        <DropdownToggle
          caret
          size="sm"
          color="transparent"
          className="general-nmphc-des text-color-themed mt-1 mx-2"
          style={{
            fontSize: "12px",
          }}
        >
          {options[selectedOption].name}
        </DropdownToggle>
        <DropdownMenu
          className="general-nmphc-des non-trans-bg"
          style={{
            maxHeight: "220px",
            overflowY: "scroll",
          }}
        >
          {Object.keys(options).map((val, key) => (
            <DropdownItem
              className="bg-set-on-active text-color-themed"
              key={`deals_${key}`}
              onClick={() => {
                setSelectedOption(val);
              }}
              style={{
                fontSize: "12px",
              }}
              disabled={val === selectedOption ? true : false}
            >
              {options[val].name}
            </DropdownItem>
          ))}
        </DropdownMenu>
      </ButtonDropdown>

      {errorShow ? (
        <VisOops />
      ) : loaderShow ? (
        <QFLoader />
      ) : (
        <div className=" akp-zoom">
          <div className=" make-me-sticky non-trans-bg py-2">
            <input
              type="text"
              className="qfInputType py-1 px-2"
              placeholder="Search"
              onChange={(e) => {
                e.preventDefault();
                setSearch(e.target.value);
              }}
              value={search}
            />
          </div>

          <div className="py-1">
            <table className="w-100 table-tr-bb">
              <thead
                className="make-me-sticky non-trans-bg"
                style={{
                  top: 40,
                }}
              >
                <tr>
                  <th className="px-1 px-md-0">Date</th>
                  <th style={{ width: "50%" }} className="px-1 px-md-0 ">
                    Client
                  </th>
                  <th className="px-1 px-md-0">Action</th>
                  <th className="px-1 px-md-0">Symbol</th>
                </tr>
              </thead>
              <tbody>
                {options[selectedOption].data
                  .filter((val) =>
                    val.symbol.toLowerCase().includes(search.toLowerCase())
                  )
                  .map((val, key) => {
                    return (
                      <tr key={key}>
                        <td className="px-1 px-md-0">{dbyTodb(val.date)}</td>
                        <td className="px-1 px-md-0">{val.clientName}</td>
                        <td className="px-1 px-md-0">
                          {val.buySell?.toLowerCase() === "buy"
                            ? "Bought "
                            : val.buySell?.toLowerCase() === "sell"
                            ? "Sold "
                            : ""}
                          {groupINRCurrencyNSE(val.qty)}
                        </td>
                        <td className="px-1 px-md-0">{val.symbol}</td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          </div>
          {options[selectedOption].data.filter((val) =>
            val.symbol.toLowerCase().includes(search.toLowerCase())
          ).length === 0
            ? getRandomStockMessage()
            : ""}
        </div>
      )}
    </CommonCardView>
  );
};

export default Deals;
