import App from "./views/App";
import React from "react";
import ReactDOM from "react-dom/client";
import reportWebVitals from "./reportWebVitals";
import HawkBoundary from "./utils/hawkeye/HawkBoundary";

import { Provider } from "react-redux";
import hawkQuiver from "./utils/hawkeye/hawkQuiver";
import { Helmet } from "react-helmet";

const root = ReactDOM.createRoot(document.getElementById("app"));
root.render(
  <HawkBoundary>
    <Helmet>
      <meta
        name="keywords"
        content="Quibblefrost, Indian stock market, Share bazaar, IPO, Market updates, Market khabare, Stock dashboard, Dalal street, Investment, Market ki har chal pe nazar"
      />

      <meta
        name="description"
        content="NSE Stock Dashboard | Stay ahead with NSE stock trends"
      />
    </Helmet>
    <Provider store={hawkQuiver}>
      <App />
      {/* <SpeedInsights />
      <Analytics /> */}
    </Provider>
  </HawkBoundary>
);

// If you want to start measuring performance in your app, pass a function
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
