import React, { useEffect, useState } from "react";
import { commonApiGet } from "../../../utils/api";
import { notificationTopup } from "../../../utils/NotificationTopup";
import QFLoader from "../QFLoader";
import VisOops from "../VisOops";

const VisProfile = () => {
  const [profileData, setProfileData] = useState({});
  const [loader, setLoader] = useState(true);
  const [error, setError] = useState(false);

  useEffect(() => {
    (async () => {
      await commonApiGet("/user/getuser", "EQUITY_SEARCH", false)
        .then((res) => {
          setProfileData(res.data);
          setLoader(false);
          setError(false);
        })
        .catch((err) => {
          notificationTopup(err.message);
          setError(true);
          setLoader(false);
        });
    })();
  }, []);

  return (
    <div className="profile-container">
      {loader ? (
        <QFLoader />
      ) : error ? (
        <VisOops />
      ) : (
        <div className="profile-card">
          <h2 className="profile-title">User  Profile</h2>
          <div className="profile-info left-border-div-sec ps-2">
            <p><strong>Name:</strong> <span>{profileData.user?.name}</span></p>
            <p><strong>Email:</strong> <span>{profileData.user?.email}</span></p>
            <p><strong>Username:</strong> <span>{profileData.user?.username}</span></p>
            <p><strong>Email Verified:</strong> <span>{profileData.user?.user_attributes?.email_verified ? "Yes" : "No"}</span></p>
          </div>
        </div>
      )}
    </div>
  );
};

export default VisProfile;