import React, { useEffect, useState } from "react";
import { themedColor } from "../../utils/configs";
import { commonApiGet } from "../../utils/api";
import { notificationTopup } from "../../utils/NotificationTopup";
import { useSelector } from "react-redux";
import QFLoader from "../vision/QFLoader";
import CommonCardView from "../vision/CommonCardView";
import {
  ButtonDropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap";
import VisOops from "../vision/VisOops";
import VIsAdvancerDeclinerUnchanged from "../vision/VIsAdvancerDeclinerUnchanged";

const AdvancerDecliner = () => {
  const options = ["LOSER", "GAINER"];
  const [whichAdvancer, setWhichAdvancer] = useState("LOSER");

  const [gainerLoser, setGainerLoser] = useState({ data: [] });

  const theme = useSelector((state) => state.theme);
  const [loaderShow, setLoaderShow] = useState(true);
  const [errorShow, setErrorShow] = useState(false);

  const [ddOpen, setDdOpen] = useState(false);
  const ddToogle = () => setDdOpen((prevState) => !prevState);

  useEffect(() => {
    (async () => {
      await commonApiGet("/market/advances_declines")
        .then((res) => {
          setGainerLoser(res.data);
          setLoaderShow(false);
          setErrorShow(false);
        })
        .catch((err) => {
          notificationTopup(err.message);
          setErrorShow(true);
          setLoaderShow(false);
        });
    })();
  }, []);

  return (
    <CommonCardView
      id="commonCard_advancerDecliner"
      header="Gainer/Loser"
      headerInfo={{
        show: true,
        desc: "Advancers Decliners stocks from NIFTY 200",
        id: "gainer-loser",
      }}
      style={{
        position: "relative",
      }}
    >
      <ButtonDropdown
        isOpen={ddOpen}
        toggle={ddToogle}
        className="tab-weight absolute-tabGrp"
      >
        <DropdownToggle
          caret
          size="sm"
          color="transparent"
          className="general-nmphc-des text-color-themed mt-1"
          style={{
            fontSize: "12px",
          }}
        >
          {whichAdvancer.toUpperCase()}
        </DropdownToggle>
        <DropdownMenu
          className="general-nmphc-des non-trans-bg"
          style={{
            maxHeight: "220px",
            overflowY: "scroll",
          }}
        >
          {options.map((val, key) => (
            <DropdownItem
              className="bg-set-on-active text-color-themed"
              key={`AD_GL_${key}`}
              onClick={() => {
                setWhichAdvancer(val);
              }}
              style={{
                fontSize: "12px",
              }}
              disabled={val === whichAdvancer ? true : false}
            >
              {val.toUpperCase()}
            </DropdownItem>
          ))}
        </DropdownMenu>
      </ButtonDropdown>
      {errorShow ? (
        <VisOops />
      ) : loaderShow ? (
        <QFLoader />
      ) : (
        <table
          style={{
            width: "100%",
          }}
        >
          <thead className="make-me-sticky non-trans-bg">
            <tr>
              <td colSpan={2}>
               <VIsAdvancerDeclinerUnchanged gainerLoser={gainerLoser} />
              </td>
            </tr>
          </thead>
          <tbody>
            {(whichAdvancer == "GAINER"
              ? gainerLoser.data.filter((val) => val.pChange > 0)
              : gainerLoser.data.filter((val) => val.pChange < 0) || []
            )
              .sort((a, b) =>
                whichAdvancer == "GAINER"
                  ? b.pChange - a.pChange
                  : a.pChange - b.pChange
              )
              // .slice(0, 15)
              .map((data, index) => {
                return (
                  <tr
                    key={index}
                    style={{
                      borderTop: `1px solid ${
                        theme === "dark" ? themedColor.dark : themedColor.light
                      }`,
                    }}
                  >
                    <td className="py-1">{data["symbol"]}</td>
                    <td className="py-1" style={{ textAlign: "right" }}>
                      {data["pChange"]}%
                    </td>
                    {/* <td>{data["totalTradedVolume"]}</td> */}
                  </tr>
                );
              })}
          </tbody>
        </table>
      )}
    </CommonCardView>
  );
};

export default AdvancerDecliner;
