import React, { useEffect, useState } from "react";
import { commonApiGet } from "../../utils/api";
import { convertToTimestamp, dbyTodb } from "../../utils/configs";
import { notificationTopup } from "../../utils/NotificationTopup";
import QFLoader from "../vision/QFLoader";
import CommonCardView from "../vision/CommonCardView";
import VisOops from "../vision/VisOops";
import VisTable from "../vision/VisTable";

const EodBandChanges = () => {
  const [eodBandChangersData, setEodBandChangersData] = useState([]);
  const [dataTable, setdataTable] = useState({ columns: [], rows: [] });
  const [loaderShow, setLoaderShow] = useState(true);
  const [errorShow, setErrorShow] = useState(false);

  useEffect(() => {
    (async () => {
      await commonApiGet(`/market/eod_band_changes?n=${50}`)
        .then((res) => {
          setEodBandChangersData(res.data);
          setLoaderShow(false);
          setErrorShow(false);
        })
        .catch((err) => {
          notificationTopup(err.message);
          setErrorShow(true);
          setLoaderShow(false);
        });
    })();
  }, []);

  useEffect(() => {
    if (eodBandChangersData.length > 0) {
      setdataTable({
        columns: [
          { field: "Date", label: "Date" },
          { field: "Symbol", label: "Symbol" },
          { field: "From", label: "From" },
          { field: "To", label: "To" },
        ],
        rows: eodBandChangersData
          .sort(
            (a, b) => convertToTimestamp(b.date) - convertToTimestamp(a.date)
          )
          .map((val) => ({
            Date: dbyTodb(val.date),
            Symbol: `${val.Symbol}`,
            From: (parseFloat(val.From) || "0") + "%",
            To: (parseFloat(val.To) || "0") + "%",
          })),
      });
    }
  }, [eodBandChangersData]);

  return (
    <CommonCardView
      id="commonCard_bandChanges"
      header="Band Changes"
      headerInfo={{
        show: true,
        desc: "Upper/Lower Circuit Changes",
        id: "band-changes",
      }}
      toWatermark={false}
    >
      {errorShow ? (
        <VisOops />
      ) : loaderShow ? (
        <QFLoader />
      ) : (
        <VisTable data={dataTable} recsPerPage={20} />
      )}
    </CommonCardView>
  );
};

export default EodBandChanges;
