export function sortme(data) {
  return data.sort((a, b) => {
    const monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    const monthA =
      monthNames.indexOf(a.recMonth.split(" ")[0]) +
      parseInt(a.recMonth.split(" ")[1]) * 12;
    const monthB =
      monthNames.indexOf(b.recMonth.split(" ")[0]) +
      parseInt(b.recMonth.split(" ")[1]) * 12;
    return monthB - monthA;
  });
}

export function monthBtob(stringToBe){
  return stringToBe.split(' ')[0].substring(0, 3) + ' ' + stringToBe.split(' ')[1];
}