import React, { useEffect, useRef } from "react";
import { monthBtob, sortme } from "./helper";
import Chart from "chart.js/auto";
import { themedColor } from "../../../../utils/configs";
import { useSelector } from "react-redux";
// Index Opt
// Index Fut
const FnoMonthlyChart = ({ data = [], cat }) => {
  const chartRef = useRef(null);
  const theme = useSelector((state) => state.theme);
  const dataToSee = {
    option: sortme(
      data.filter((val) => val.category.trim() === `fii_${cat} Opt`)
    ),
    future: sortme(
      data.filter((val) => val.category.trim() === `fii_${cat} Fut`)
    ),
  };

  const calculateHeight = () => {
    const dataSize = Math.max(dataToSee.option.length, dataToSee.future.length);
    return dataSize * 21 + 100; // 30px per data point + 100px for padding
  };
  useEffect(() => {
    if (!chartRef.current) return;
    const ctx = chartRef.current.getContext("2d");
    const chart = new Chart(ctx, {
      type: "bar",
      data: {
        labels: [
          ...new Set([
            ...dataToSee.option.map((item) => monthBtob(item.recMonth)),
            ...dataToSee.future.map((item) => monthBtob(item.recMonth)),
          ]),
        ],
        datasets: [
          {
            label: "Options",
            data: dataToSee.option.map((item) => item["Net Purchase / Sales"]),
            borderColor: "rgb(255, 192, 0)",
            backgroundColor: "rgba(255, 192, 0, 0.2)",
          },
          {
            label: "Futures",
            data: dataToSee.future.map((item) => item["Net Purchase / Sales"]),
            borderColor: "rgb(54, 162, 235)",
            backgroundColor: "rgba(54, 162, 235, 0.2)",
          },
        ],
      },
      options: {
        indexAxis: "y",
        elements: {
          bar: {
            borderWidth: 2,
          },
        },
        responsive: true,
        plugins: {
          legend: {
            position: "top",
            display: true,
          },
          title: {
            display: true,
            text: "Fii Monthly Net Purchase/Sales",
          },
        },
        scales: {
          y: {
            position: "left",
            grid: {
              display: false,
            },
            ticks: {
              color: themedColor[theme],
            },
          },
          y1: {
            position: "right",
            grid: {
              display: false,
            },
            ticks: {
              color: themedColor[theme],
            },
          },
          x: {
            position: "top",
            grid: {
              display: false,
            },
            ticks: {
              color: themedColor[theme],
            },
          },
        },
      },
    });

    return () => {
      chart.destroy();
    };
  }, [data, theme, cat]);

  return (
    <div>
      <canvas ref={chartRef} height={calculateHeight()} />
    </div>
  );
};

export default FnoMonthlyChart;
