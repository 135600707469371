import { prepareCyptoData } from "./functions";
import { apiFieldForMultiExchangeDefault, tickerSymsExchange } from "./configs";

export async function defaultValuesForCryptoPrice(symbol, exchange = "INDIA") {
  const headers = {
    Accept: "application/json",
  };
  let apiUrl = `${apiFieldForMultiExchangeDefault[exchange]}${tickerSymsExchange[symbol][exchange]}`;
  return await fetch(apiUrl, {
    method: "GET",
    headers: headers,
  })
    .then((response) => {
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      return response.json();
    })
    .then((data) => {
      const result = data.result;
      result.uriCalled = apiUrl;
      const returnData = prepareCyptoData(result, {});
      return returnData;
    })
    .catch((error) => {
      console.error(`Error fetching data for symbol ${symbol}:`, error);
      return null;
    });
}
