import React from "react";
import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts";
import { chartColor } from "../utils/configs";
import { useSelector } from "react-redux";

const AskBidChart = ({ quotes }) => {
  const bestAsk = parseFloat(quotes.best_ask || 0);
  const bestBid = parseFloat(quotes.best_bid || 0);
  const theme = useSelector((state) => state.theme);

  const config = {
    chart: {
      height: 40,
      type: "bar",
      backgroundColor: null,
      margin: [0, 0, 0, 0],
    },
    plotOptions: {
      bar: {
        borderWidth: 0
      },
      series: {
        animation: false, // Disable animations for the series
      },
    },
    title: {
      text: null, // Hide title
    },
    xAxis: {
      categories: ["Best Ask: $" + bestAsk, "Best Bid: $" + bestBid],
      title: {
        text: null, // Hide title
      },
      gridLineColor: "transparent", // Hide y-axis grid lines
      lineColor: theme == "dark" ? "white" : "black",
    },
    yAxis: {
      title: {
        text: null, // Hide title
      },
      gridLineColor: "transparent", // Hide y-axis grid lines
    },
    legend: {
      enabled: false, // Hide legend
    },
    series: [
      {
        name: "Size",
        data: [
          {
            y: parseFloat(quotes.ask_size || 0),
            color: chartColor.red,
          },
          {
            y: parseFloat(quotes.bid_size || 0),
            color: chartColor.green,
          },
        ],
      },
    ],
  };

  return <HighchartsReact options={config} highcharts={Highcharts} />;
};

export default AskBidChart;
