import React, { useEffect, useState } from "react";
import { commonApiGet } from "../../utils/api";
import { getRandomStockMessage, themedColor } from "../../utils/configs";
import { useSelector } from "react-redux";
import QFLoader from "../vision/QFLoader";
import { notificationTopup } from "../../utils/NotificationTopup";
import CommonCardView from "../vision/CommonCardView";
import VisOops from "../vision/VisOops";
import { Link } from "react-router-dom";

const IntradayStocks = () => {
  const [intradayStocksData, setIntradayStocksData] = useState([]);
  const theme = useSelector((state) => state.theme);
  const [search, setSearch] = useState("");
  const [loaderShow, setLoaderShow] = useState(true);
  const [errorShow, setErrorShow] = useState(false);

  useEffect(() => {
    (async () => {
      await commonApiGet("/market/intradayStocks?exchange=NSE")
        .then((res) => {
          setIntradayStocksData(res.data.data);
          setLoaderShow(false);
          setErrorShow(false);
        })
        .catch((err) => {
          notificationTopup(err.message);
          setErrorShow(true);
          setLoaderShow(false);
        });
    })();
  }, []);

  return (
    <CommonCardView
      id="commonCard_intradayStocks"
      header="Intraday Stocks"
      headerInfo={{
        id: "intraday-stocks",
        show: true,
        desc: "Intraday stocks on NSE exchange",
      }}
      toWatermark={false}
    >
      {errorShow ? (
        <VisOops />
      ) : loaderShow ? (
        <QFLoader />
      ) : (
        <>
          <div className="make-me-sticky non-trans-bg py-2">
            <input
              type="text"
              className="qfInputType py-1 px-2"
              placeholder="Search"
              onChange={(e) => {
                e.preventDefault();
                setSearch(e.target.value);
              }}
              value={search}
            />
          </div>

          <table className="w-100">
            <thead className="make-me-sticky non-trans-bg" style={{ top: 40 }}>
              <tr>
                <th>Symbol</th>
                <th>Multiplier</th>
              </tr>
            </thead>
            <tbody>
              {intradayStocksData
                .filter((val) =>
                  val["SymbolName"].toLowerCase().includes(search.toLowerCase())
                )
                .map((data, index) => {
                  return (
                    <tr
                      key={index}
                      style={{
                        borderTop: `1px solid ${
                          theme === "dark"
                            ? themedColor.dark
                            : themedColor.light
                        }`,
                      }}
                    >
                      <td className="py-1">
                        <Link
                          to={`/info/NSE/${data.SymbolName}-EQ`}
                          style={{
                            color: "var(--text-color)",
                            textDecoration: "none",
                            fontWeight: 900,
                          }}
                        >
                          {data["SymbolName"]}
                        </Link>
                      </td>
                      <td className="py-1">{data["Multiplier"]}</td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
          {intradayStocksData.filter((val) =>
            val["SymbolName"].toLowerCase().includes(search.toLowerCase())
          ).length === 0 && getRandomStockMessage()}
        </>
      )}
    </CommonCardView>
  );
};

export default IntradayStocks;
