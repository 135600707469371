import React, { useEffect } from "react";
import Navbar from "../components/Navbar";
import EodBandChanges from "../components/NSEComponents/EodBandChanges";
import PriceBandHitters from "../components/NSEComponents/PriceBandHitters";
import Scanners from "../components/NSEComponents/Scanners";
import FiiDiiMonthly from "../components/NSEComponents/FiiDiiMonthly";
import SharkFolio from "../components/NSEComponents/SharkFolio";
import Trending from "../components/NSEComponents/Trending";
import Breakout from "../components/NSEComponents/Breakout";
import Buildup from "../components/NSEComponents/Buildup";
import ResearchStocks from "../components/NSEComponents/ResearchStocks";
import ShortSelling from "../components/NSEComponents/ShortSelling";
import AdvancerDecliner from "../components/NSEComponents/AdvancerDecliner";
import IpoData from "../components/NSEComponents/IpoData";
import NewsCorp from "../components/NSEComponents/NewsCorp";
import OptionChain from "../components/NSEComponents/OptionChain";
import { getCookie } from "../utils/api";
import { Navigate } from "react-router-dom";
import LivePrice from "./preLogin/components/LivePrice";
import OIChange from "../components/NSEComponents/OIChange";
import Deals from "../components/NSEComponents/Deals";
// import { loginUrl } from "./preLogin/components/Ctas";

export const NSE = () => {
  if (getCookie("access_token") === "") {
    // window.location.href = '/'
    return <Navigate to="/" />;
  }

  return (
    <div className="container-fluid">
      <Navbar />
      <LivePrice />
      <div className="main-content">
        <div className="row">
          <div
            className="futures-card m-0 col-12"
            style={{
              overflowY: "scroll",
              maxHeight: "85vh",
              overflowX: "hidden",
            }}
          >
            <div className="row g-3">
              {/* <div className="col-12">
              </div> */}
              {/* <div className="col-12 p-1"></div> */}
              <div className="col-xxxl-4 col-md-6">
                <Scanners />
              </div>
              <div className="col-xxxl-5 col-md-6">
                <OptionChain />
              </div>
              <div className="col-xxxl-3 col-md-3">
                <SharkFolio />
              </div>

              <div className="col-xxxl-4 col-md-3">
                <Deals />
              </div>
              <div className="col-xxxl-5 col-md-6 p-0 mt-md-3 mt-0">
                <div className="row p-0 m-0 h-min-full gy-md-0 gy-3">
                  <div className="col-md-6 py-0 px-2">
                    <EodBandChanges />
                  </div>
                  <div className="col-md-6 py-0 px-2">
                    <PriceBandHitters />
                  </div>
                </div>
              </div>
              <div className="col-xxxl-3 col-md-4">
                <Trending />
              </div>
              <div className="col-xxxl-3 col-md-4">
                {/* <Breakout /> */}
                <OIChange />
              </div>
              <div className="col-xxxl-3 col-md-4">
                <Buildup />
              </div>
              <div className="col-xxxl-3 col-md-4">
                <ResearchStocks />
              </div>
              <div className="col-xxxl-3 col-md-4">
                <ShortSelling />
              </div>
              <div className="col-xxxl-3 col-md-4">
                <FiiDiiMonthly />
              </div>
              <div className="col-xxxl-3 col-md-4">
                <AdvancerDecliner />
                {/* <IpoData /> */}
              </div>
              <div className="col-xxxl-6 col-md-8">
                <NewsCorp />
              </div>
              <div className="col-12 p-3"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
