import React, { useEffect, useState } from "react";
import { commonApiGet } from "../../utils/api";

import {
  ButtonDropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap";
import { notificationTopup } from "../../utils/NotificationTopup";
import { getRandomStockMessage, themedColor } from "../../utils/configs";
import QFLoader from "../vision/QFLoader";
import { useSelector } from "react-redux";
import CommonCardView from "../vision/CommonCardView";
import VisOops from "../vision/VisOops";

const OIChange = () => {
  const [search, setSearch] = useState("");
  const theme = useSelector((state) => state.theme);

  const [bus, setbus] = useState([]);
  const [dropdownbusOpen, setDropdownbusOpen] = useState(false);
  const [dropdownExpOpen, setDropdownExpOpen] = useState(false);
  const builupOptions = {
    PercPriceGainers: "% Price Gainers",
    PercPriceLosers: "% Price Losers",
    PercOILosers: "% OI Losers",
    PercOIGainers: "% OI Gainers",
  };
  const expOptions = ["NEAR", "NEXT", "FAR"];
  const [selectedbusOption, setSelectedbusOption] =
    useState("PercPriceGainers");
  const [selectedExpOption, setSelectedExpOption] = useState(expOptions[0]);
  const bustoggle = () => setDropdownbusOpen((prevState) => !prevState);
  const exptoggle = () => setDropdownExpOpen((prevState) => !prevState);
  const [loaderShow, setLoaderShow] = useState(true);
  const [errorShow, setErrorShow] = useState(false);

  useEffect(() => {
    (async () => {
      await commonApiGet(
        `/fno/OIChange?datatype=${selectedbusOption}&expirytype=${selectedExpOption}`
      )
        .then((res) => {
          // setbus(Array.isArray(res.data) ? res.data : []);
          setbus(res.data.data);
          setLoaderShow(false);
          setErrorShow(false);
        })
        .catch((err) => {
          notificationTopup(err.message);
          setErrorShow(true);
          setLoaderShow(false);
        });
    })();
  }, [selectedbusOption, selectedExpOption]);

  return (
    <CommonCardView
      id="commonCard_OIChange"
      header="OI Change"
      headerAlign="left"
      style={{
        position: "relative",
      }}
      headerInfo={{
        show: true,
        desc: "Change in OI(EQuity).",
        id: "oichange-in-stocks",
      }}
    >
      <div className="absolute-tabGrp">
        <ButtonDropdown
          isOpen={dropdownbusOpen}
          toggle={bustoggle}
          className="tab-weight"
        >
          <DropdownToggle
            caret
            size="sm"
            color="transparent"
            className="general-nmphc-des text-color-themed mt-1 mx-2"
            style={{
              fontSize: "12px",
            }}
          >
            {builupOptions[selectedbusOption].toUpperCase()}
          </DropdownToggle>
          <DropdownMenu
            className="general-nmphc-des non-trans-bg"
            style={{
              maxHeight: "220px",
              overflowY: "scroll",
            }}
          >
            {Object.keys(builupOptions).map((val, key) => (
              <DropdownItem
                className="bg-set-on-active text-color-themed"
                key={`BUS_${key}`}
                onClick={() => {
                  setLoaderShow(true);
                  setbus([]);
                  setSelectedbusOption(val);
                }}
                style={{
                  fontSize: "12px",
                }}
                disabled={val === selectedbusOption ? true : false}
              >
                {builupOptions[val].toUpperCase()}
              </DropdownItem>
            ))}
          </DropdownMenu>
        </ButtonDropdown>
        <ButtonDropdown
          isOpen={dropdownExpOpen}
          toggle={exptoggle}
          className="tab-weight"
        >
          <DropdownToggle
            caret
            size="sm"
            color="transparent"
            className="general-nmphc-des text-color-themed mt-1"
            style={{
              fontSize: "12px",
            }}
          >
            {selectedExpOption.toUpperCase()}
          </DropdownToggle>
          <DropdownMenu
            className="general-nmphc-des non-trans-bg"
            style={{
              maxHeight: "220px",
              overflowY: "scroll",
            }}
          >
            {expOptions.map((val, key) => (
              <DropdownItem
                className="bg-set-on-active text-color-themed"
                key={`EXP_${key}`}
                onClick={() => {
                  setLoaderShow(true);
                  setbus([]);
                  setSelectedExpOption(val);
                }}
                style={{
                  fontSize: "12px",
                }}
                disabled={val === selectedExpOption ? true : false}
              >
                {val.toUpperCase()}
              </DropdownItem>
            ))}
          </DropdownMenu>
        </ButtonDropdown>
      </div>

      {errorShow ? (
        <VisOops />
      ) : loaderShow ? (
        <QFLoader />
      ) : (
        <>
          <div className=" make-me-sticky non-trans-bg py-2">
            <input
              type="text"
              className="qfInputType py-1 px-2"
              placeholder="Search"
              onChange={(e) => {
                e.preventDefault();
                setSearch(e.target.value);
              }}
              value={search}
            />
          </div>

          <div
            // style={{
            //   ...(key !== 0
            //     ? {
            //         borderTop: `1px solid ${
            //           theme === "dark" ? themedColor.dark : themedColor.light
            //         }`,
            //       }
            //     : {}),
            // }}
            className="py-1"
          >
            <table className="w-100 table-tr-bb">
              <thead
                className="make-me-sticky non-trans-bg"
                style={{
                  top: 40,
                }}
              >
                <tr>
                  {/* "percentChange": 5.51,
                      "symbolToken": 40117,
                      "ltp": 198.2,
                      "netChange": 10.35 */}
                  <th>Symbol</th>
                  <th>{selectedbusOption.includes("Price") ? "LTP" : "OI"}</th>
                  <th>Change</th>
                </tr>
              </thead>
              <tbody>
                {bus
                  .filter((val) =>
                    val.tradingSymbol
                      .toLowerCase()
                      .includes(search.toLowerCase())
                  )
                  .map((val, key) => {
                    return (
                      <tr
                        key={`selectedbusOption_${selectedbusOption}_${selectedExpOption}_${key}`}
                        className=""
                      >
                        {/* <td>{val.tradingSymbol.match(/\d{2}[A-Z]{3}\d{2}/)}</td> */}
                        <td>
                          <b>{val.tradingSymbol.match(/^[A-Z-&]+(?=\d)/)}</b>
                        </td>
                        <td>
                          {selectedbusOption.includes("Price")
                            ? "₹" + val.ltp
                            : val.opnInterest}
                        </td>
                        <td>
                          {selectedbusOption.includes("Price")
                            ? "₹" + val.netChange
                            : val.netChangeOpnInterest}
                          {`(${val.percentChange}%)`}
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          </div>
          {bus.filter((val) =>
            val.tradingSymbol.toLowerCase().includes(search.toLowerCase())
          ).length === 0
            ? getRandomStockMessage()
            : ""}
        </>
      )}
    </CommonCardView>
  );
};

export default OIChange;
