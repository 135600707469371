import React, { useEffect, useState } from "react";
import { commonApiGet } from "../../utils/api";
import {
  ButtonDropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap";
import {
  camelCaseToSentence,
  maxViewContent,
  YmdToViewDate,
} from "../../utils/configs";
import { ipo } from "../../utils/req-res-controllers/sampleResponses";
import { notificationTopup } from "../../utils/NotificationTopup";
import CommonCardView from "../vision/CommonCardView";
import QFLoader from "../vision/QFLoader";
import VisOops from "../vision/VisOops";

const IpoData = () => {
  const [ipoData, setIpoData] = useState({});
  const [options, setOptions] = useState([]);
  const [selectedOption, setSelectedOption] = useState("openIpoList");
  const [dropdownOptionOpen, setDropdownOptionOpen] = useState(false);
  const [selectedDetail, setSelectedDetail] = useState(0);
  const ddootoggle = () => setDropdownOptionOpen((prevState) => !prevState);
  const [loaderShow, setLoaderShow] = useState(true);
  const [errorShow, setErrorShow] = useState(false);

  useEffect(() => {
    (async () => {
      await commonApiGet("/market/ipo?slug=data")
        .then((res) => {
          setIpoData(res.data);
          const keysList = Object.keys(res.data).filter(
            (val) =>
              !["fundingCalculator", "type", "loader", "draftIssue"].includes(
                val
              )
          );
          setOptions(keysList);
          setLoaderShow(false);
          setErrorShow(false);
        })
        .catch((err) => {
          notificationTopup(err.message);
          setErrorShow(true);
          setLoaderShow(false);
        });
    })();
  }, []);

  useEffect(() => {
    setSelectedDetail(0);
  }, [selectedOption, ipoData]);
  return (
    <CommonCardView
      id="commonCard_ipoDetails"
      header="IPO Details"
      headerAlign="left"
      style={{
        position: "relative",
      }}
      headerInfo={{
        show: true,
        desc: "IPO-Data",
        id: "ipo-data",
      }}
    >
      <ButtonDropdown
        isOpen={dropdownOptionOpen}
        toggle={ddootoggle}
        className="tab-weight absolute-tabGrp"
      >
        <DropdownToggle
          caret
          size="sm"
          color="transparent"
          className="general-nmphc-des text-color-themed mt-1"
          style={{
            fontSize: "12px",
          }}
        >
          {camelCaseToSentence(selectedOption)
            .toUpperCase()
            .replaceAll("_", " ")}
        </DropdownToggle>
        <DropdownMenu
          className="general-nmphc-des non-trans-bg"
          style={{
            maxHeight: "220px",
            overflowY: "scroll",
          }}
        >
          {options.map((val, key) => (
            <DropdownItem
              className="bg-set-on-active text-color-themed"
              key={`ipo_data_keys_${key}`}
              onClick={() => {
                setSelectedOption(val);
              }}
              style={{
                fontSize: "12px",
              }}
              disabled={val === selectedOption ? true : false}
            >
              {camelCaseToSentence(val).toUpperCase().replaceAll("_", " ")}
            </DropdownItem>
          ))}
        </DropdownMenu>
      </ButtonDropdown>

      {errorShow || ipoData[selectedOption] == undefined ? (
        <VisOops />
      ) : loaderShow ? (
        <QFLoader />
      ) : (
        <table className="w-100 td-all-right  table-th-py table-tr-bb">
          <tbody>
            <tr>
              <td colSpan={2} className="py-3">
                <div className="row m-0">
                  <div className="col-2">
                    <i
                      className="div-pill general-nmphc-des our-border fas fa-caret-left"
                      style={{
                        lineHeight: "20px",
                      }}
                      onClick={() => {
                        setSelectedDetail((prev) => {
                          if (0 === prev) {
                            return ipoData[selectedOption].length - 1;
                          }
                          return prev - 1;
                        });
                      }}
                    ></i>
                  </div>
                  <div className="text-center col-8">
                    {`${maxViewContent(
                      ipoData[selectedOption][selectedDetail]?.company_name
                    )}`}
                    <br />({ipoData[selectedOption][selectedDetail]?.ipo_type})
                  </div>
                  <div className="col-2">
                    <i
                      className="div-pill general-nmphc-des our-border fas fa-caret-right"
                      style={{
                        lineHeight: "20px",
                      }}
                      onClick={() => {
                        setSelectedDetail((prev) => {
                          if (ipoData[selectedOption].length - 1 === prev) {
                            return 0;
                          }
                          return prev + 1;
                        });
                      }}
                    ></i>
                  </div>
                </div>
              </td>
            </tr>
            {Object.keys(ipo[selectedOption]).map((val, index) => {
              let title = ipo[selectedOption][val];
              return (
                <tr key={`inside_IPO_${index}`}>
                  <th>{title}:</th>
                  <td
                    style={{
                      maxWidth: "150px",
                    }}
                  >
                    {title.toLowerCase().includes("date") ? (
                      YmdToViewDate(
                        ipoData[selectedOption][selectedDetail][val]
                      )
                    ) : title.toLowerCase().includes("price") ||
                      title.toLowerCase().includes("gain") ? (
                      `₹${parseFloat(
                        ipoData[selectedOption][selectedDetail][val] || 0
                      ).toFixed(2)}`
                    ) : title.toLowerCase().includes("subs") ? (
                      `${ipoData[selectedOption][selectedDetail][val]}x`
                    ) : title.toLowerCase().includes("url") ? (
                      <a
                        href={ipoData[selectedOption][selectedDetail][val]}
                        target="_blank"
                      >
                        Click here
                      </a>
                    ) : title.toLowerCase().includes("desc") ? (
                      <marquee>
                        {ipoData[selectedOption][selectedDetail][val]}
                      </marquee>
                    ) : (
                      ipoData[selectedOption][selectedDetail][val]
                    )}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      )}
    </CommonCardView>
  );
};

export default IpoData;
