import { Link } from "react-router-dom";
import { commonApiGet, getCookie, setCookie } from "./api";

const socketFeedIndia = "wss://socket.india.delta.exchange";
const socketFeedGlobal = "wss://socket.delta.exchange";

export const nseBase = "https://api.quibblefrost.in";
export const nseBaseDocs = nseBase + "/docs";
export const webSockQF = "wss://socket.quibblefrost.in";

const apiForFeedIndia = "https://api.india.delta.exchange/v2/tickers/";
const apiForFeedGlobal = "https://api.delta.exchange/v2/tickers/";

export const sparklineApi =
  "https://api.india.delta.exchange/v2/history/sparklines";

export const historicOHLCCandlesApi =
  "https://api.delta.exchange/v2/history/candles";

export const indiaAssetsApi = "https://api.india.delta.exchange/v2/assets";
export const indiaIndicesApi = "https://api.india.delta.exchange/v2/indices";

export const indiaProductApi = "https://api.india.delta.exchange/v2/products";

export const socketFieldForMultiExchange = {
  INDIA: socketFeedIndia,
  GLOBAL: socketFeedGlobal,
  COMBINED: {
    INDIA: socketFeedIndia,
    GLOBAL: socketFeedGlobal,
  },
};

export const apiFieldForMultiExchangeDefault = {
  INDIA: apiForFeedIndia,
  GLOBAL: apiForFeedGlobal,
  COMBINED: {
    INDIA: apiForFeedIndia,
    GLOBAL: apiForFeedGlobal,
  },
};

export const tickerSymsExchange = {
  BTCUSD: {
    INDIA: "BTCUSD",
    GLOBAL: "BTCUSDT",
  },
  ETHUSD: {
    INDIA: "ETHUSD",
    GLOBAL: "ETHUSDT",
  },
};

export const chartColor = {
  green: "#198754",
  red: "#dc3545",
  yellow: "#fca103",
  opacGreen: "#19875469",
  opacGreenMore: "#19875422",
  opacRed: "#dc354569",
  opacRedMore: "#dc354522",
  opacYellow: "#fca10369",
  opacYellowMore: "#fca10322",
};

export const toFetchCandlesSmall = 24;
export const toFetchCandlesBig = 500;

export const candleCountAccordingTimeframes = {
  resolutionsAvailable: {
    miniChart: ["15m", "1h", "1d", "7d", "30d"],
    fullChart: ["15m", "1h", "1d", "7d", "30d"],
  },
  "15m": {
    miniChart: {
      startTime: Math.floor(Date.now() / 1000) - toFetchCandlesSmall * 15 * 60,
      endTime: Math.floor(Date.now() / 1000),
    },

    fullChart: {
      startTime: Math.floor(Date.now() / 1000) - toFetchCandlesBig * 15 * 60,
      endTime: Math.floor(Date.now() / 1000),
    },
  },
  "1h": {
    miniChart: {
      startTime: Math.floor(Date.now() / 1000) - toFetchCandlesSmall * 60 * 60,
      endTime: Math.floor(Date.now() / 1000),
    },

    fullChart: {
      startTime: Math.floor(Date.now() / 1000) - toFetchCandlesBig * 60 * 60,
      endTime: Math.floor(Date.now() / 1000),
    },
  },
  "1d": {
    miniChart: {
      startTime:
        Math.floor(Date.now() / 1000) - toFetchCandlesSmall * 60 * 24 * 60,
      endTime: Math.floor(Date.now() / 1000),
    },

    fullChart: {
      startTime:
        Math.floor(Date.now() / 1000) - toFetchCandlesBig * 60 * 24 * 60,
      endTime: Math.floor(Date.now() / 1000),
    },
  },
  "7d": {
    miniChart: {
      startTime:
        Math.floor(Date.now() / 1000) - toFetchCandlesSmall * 60 * 24 * 7 * 60,
      endTime: Math.floor(Date.now() / 1000),
    },

    fullChart: {
      startTime:
        Math.floor(Date.now() / 1000) - toFetchCandlesBig * 60 * 24 * 7 * 60,
      endTime: Math.floor(Date.now() / 1000),
    },
  },
  "30d": {
    miniChart: {
      startTime:
        Math.floor(Date.now() / 1000) - toFetchCandlesSmall * 60 * 24 * 30 * 60,
      endTime: Math.floor(Date.now() / 1000),
    },

    fullChart: {
      startTime:
        Math.floor(Date.now() / 1000) - toFetchCandlesBig * 60 * 24 * 30 * 60,
      endTime: Math.floor(Date.now() / 1000),
    },
  },
};

export function formatDate(date, format) {
  const year = date.getFullYear();
  const month = getMonthName(date.getMonth());
  const day = date.getDate().toString().padStart(2, "0");
  return format.replace("DD", day).replace("MMM", month).replace("YY", year);
}

export const convertToTimestamp = (dateStr) => {
  if (!dateStr) return;

  // Define months mapping for converting "Aug" to "08" etc.
  const months = {
    Jan: "01",
    Feb: "02",
    Mar: "03",
    Apr: "04",
    May: "05",
    Jun: "06",
    Jul: "07",
    Aug: "08",
    Sep: "09",
    Oct: "10",
    Nov: "11",
    Dec: "12",
  };

  // Split dateStr (example: "24-Aug-2024")
  const [day, month, year] = dateStr.split("-");

  // Create a new date string in the format "YYYY-MM-DD"
  const formattedDate = `${year}-${months[month]}-${day}`;

  // Convert to timestamp (Epoch time in milliseconds)
  const timestamp = new Date(formattedDate).getTime();

  return timestamp;
};

function getMonthName(month) {
  const monthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  return monthNames[month];
}
export const date4IndexHis = {
  current: formatDate(new Date(), "DD-MMM-YY"),
  "1M": formatDate(
    new Date(new Date().getTime() - 30 * 24 * 60 * 60 * 1000),
    "DD-MMM-YY"
  ),
  "2M": formatDate(
    new Date(new Date().getTime() - 60 * 24 * 60 * 60 * 1000),
    "DD-MMM-YY"
  ),
  "3M": formatDate(
    new Date(new Date().getTime() - 90 * 24 * 60 * 60 * 1000),
    "DD-MMM-YY"
  ),
  "6M": formatDate(
    new Date(new Date().getTime() - 180 * 24 * 60 * 60 * 1000),
    "DD-MMM-YY"
  ),
  "1Y": formatDate(
    new Date(new Date().getTime() - 360 * 24 * 60 * 60 * 1000),
    "DD-MMM-YY"
  ),
  options: ["1M", "2M", "3M", "6M", "1Y"],
};

export function convertToDateNSE(unformattedDate) {
  if (unformattedDate === undefined) return;
  const [day, month, year] = unformattedDate.split("-");
  const months = {
    JAN: "01",
    FEB: "02",
    MAR: "03",
    APR: "04",
    MAY: "05",
    JUN: "06",
    JUL: "07",
    AUG: "08",
    SEP: "09",
    OCT: "10",
    NOV: "11",
    DEC: "12",
  };
  const formattedDate = `${year}-${months[month]}-${day}T00:00:00.000Z`;
  const date = new Date(formattedDate);
  const unixTimestampMs = date.getTime();
  return unixTimestampMs;
}

export const indicesSnapshotPrio = {
  "NIFTY 50": 1,
  "NIFTY BANK": 2,
  "NIFTY FINANCIAL SERVICES": 3,
  "INDIA VIX": 4,
  "NIFTY PSU BANK": 5,
  "NIFTY IT": 6,
  "NIFTY MIDCAP 50": 7,
};

export const indicesSnapshotMarquee = [
  "NIFTY 50",
  "INDIA VIX",
  "NIFTY BANK",
  "NIFTY NEXT 50",
  "NIFTY COMMODITIES",
];

export const prioritiseIndicesSnapshot = (list) => {
  let prioAddedList = list.map((val, index) => {
    val.priority =
      indicesSnapshotPrio[val["Index Name"].toUpperCase().trim()] ||
      index + 1000;
    return val;
  });
  prioAddedList.sort((a, b) => a.priority - b.priority);
  return prioAddedList;
};

export const marqueeSnapshots = (list) => {
  function filterit(val) {
    return indicesSnapshotMarquee.includes(
      val["Index Name"].toUpperCase().trim()
    );
  }
  let returnData = list.filter(filterit);
  return returnData;
};

export const formatDateAO = (date) => {
  if (typeof date === "string") return date;

  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-based
  const day = String(date.getDate()).padStart(2, "0");
  const hours = String(date.getHours()).padStart(2, "0");
  const minutes = String(date.getMinutes()).padStart(2, "0");

  return `${year}-${month}-${day} ${hours}:${minutes}`;
};

export const getDateRangeAO = (days) => {
  const today = new Date();
  const fromDate = new Date();
  fromDate.setDate(today.getDate() - days);

  const formatDate = (date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-based
    const day = String(date.getDate()).padStart(2, "0");
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");

    return `${year}-${month}-${day} ${hours}:${minutes}`;
  };
  return {
    today: formatDate(today),
    from: formatDate(fromDate),
  };
};

export function isMobile() {
  // Define a breakpoint for mobile devices
  const mobileBreakpoint = 768; // You can adjust this value based on your needs

  // Check if the window's width is less than the breakpoint
  return window.innerWidth < mobileBreakpoint;
}

export function isLoggedin() {
  return getCookie("access_token") !== ""
}

export const lastDays = async (days = 10) => {
  const today = new Date();
  const lastDays = [];
  let holidays = getCookie("tradingHoliday");
  if (holidays) {
    holidays = JSON.parse(holidays);
  } else {
    holidays = (await commonApiGet("/utils/holidays?type=trading")).data.CM.map(
      (val) => val.tradingDate
    );
    setCookie("tradingHoliday", JSON.stringify(holidays), 1);
  }
  for (let i = 0; i < days; i++) {
    let dateUF = new Date(today.getTime() - i * 24 * 60 * 60 * 1000);
    let date = formatDate(dateUF, "DD-MMM-YY");
    if (holidays?.includes(date) || [6, 0].includes(dateUF.getDay())) continue;
    lastDays.push(date);
  }
  return lastDays;
};

export const themedColor = {
  light: "#363537",
  dark: "#e6e7ee",
};

export const YmdToViewDate = (normalDate) => {
  if (normalDate === undefined || normalDate === null || normalDate == "null")
    return;
  let tv = String(normalDate).split("-");
  if (tv.length != 3) return;
  const [year, month, day] = tv;
  const viewDate = `${day} ${getMonthName(
    parseInt(month) - 1
  )} ${year.substring(year.length - 2, year.length)}`;
  return viewDate;
};

export const dmYToViewDate = (normalDate) => {
  if (normalDate === undefined || normalDate === null || normalDate == "null")
    return;
  let tv = String(normalDate).split("-");
  if (tv.length != 3) return;
  const [day, month, year] = tv;
  const viewDate = `${day} ${getMonthName(
    parseInt(month) - 1
  )} ${year.substring(year.length - 2, year.length)}`;
  return viewDate;
};

export const camelCaseToSentence = (camelCaseStr) => {
  // Use a regular expression to find places to insert spaces
  let spacedSentence = camelCaseStr.replace(/([a-z])([A-Z])/g, "$1 $2");
  // Capitalize the first letter of the resulting sentence
  spacedSentence =
    spacedSentence.charAt(0).toUpperCase() + spacedSentence.slice(1);
  return spacedSentence;
};

export const maxViewContent = (sentence, lengthOf = 25) => {
  if (!sentence) return "...";
  if (sentence.length > lengthOf) {
    return sentence.substring(0, lengthOf) + "...";
  }
  return sentence;
};

export const dbyTodb = (normalDate) => {
  if (normalDate === undefined || normalDate === null || normalDate == "null")
    return;
  return normalDate.substring(0, 6).replaceAll("-", " ");
};

export function getRandomStockMessage() {
  const messages = [
    "Oops, looks like the market is closed on this one!",
    "No stock data found. Maybe it's a holiday on Dalal Street?",
    "Our search didn’t find any stocks—time for chai?",
    "Looks like we couldn't find that stock. Maybe it’s listed on the moon?",
    "No shares in sight—perhaps the bulls are taking a break?",
    "The market ticker has gone silent on this one.",
    "No luck on the search. Maybe check NSE or BSE again?",
    "Our search for this stock came up empty—must be a bearish day.",
    "It seems this stock is as elusive as a good monsoon forecast!",
    "Nothing found! Did we just hit a market circuit breaker?",
    "No results? Must be a quiet day on the Sensex.",
    "Stock not found. Maybe it's a hidden gem waiting to be discovered?",
    "Looks like this stock isn’t trading today. Taking a breather, perhaps?",
    "Our search was fruitless—must be stuck in a liquidity trap!",
    "Looks like this stock didn’t make the cut today.",
    "Search complete, but no stock movements were detected.",
    "Nothing to report—must be a silent stock in the Indian market.",
    "Stock not found. Are you sure it’s not trading on the Bombay Stock Exchange?",
    "Well, this is unexpected! The stock data is nowhere to be found.",
    "No stock here! Did we miss the opening bell?",
    "Our search found nothing—maybe this stock is on a tea break?",
    "No signs of this stock on our radar—must be lost in the trading pits.",
    "No data found. Is this stock flying under the radar on the Nifty?",
    "Looks like we missed the bull run—no stock found.",
    "Search yielded no results—maybe try checking the commodity market?",
    "Our stock search went AWOL. Time to rethink that portfolio?",
    "Stock not found—maybe it's in the small-cap wilderness?",
    "No data for this stock. Must be a rare unicorn on the exchange!",
    "Oops, no sign of this stock—could it be a ghost of the market?",
    "This stock seems to be off the grid. Are you sure it's listed?",
  ];

  const randomIndex = Math.floor(Math.random() * messages.length);
  return messages[randomIndex];
}

/**
 * Returns the icon for a given name and type.
 *
 * @param {string} name - The name of the icon.
 * @param {string} type - The type of icon (e.g., 'scan').
 * @returns {string|null} - The icon class (e.g., 'fas fa-magnifying-glass-chart') or null if not found.
 */
export function getIconForName(name, type) {
  const fallback = "qf-|_|-fallback";
  const configIcons = {
    scan: {
      result: "fas fa-check-circle",
      curated: "fas fa-star",
      ratio: "fas fa-percent",
      "profit & loss": "fas fa-file-invoice",
      "balance sheet": "fas fa-book",
      "cash flow": "fas fa-money-bill-trend-up",
      "red flag": "fas fa-skull",
      shareholding: "fas fa-users",
      [fallback]: "fas fa-magnifying-glass-chart",
    },
    // Add more types as needed
  };

  // Convert name to lowercase for case-insensitive search
  name = name.toLowerCase();

  // Check if type exists in config
  if (configIcons[type]) {
    // Find icon by checking if key includes name
    const iconKey = Object.keys(configIcons[type]).find((key) =>
      name.includes(key)
    );

    // Return icon if found, or fallback icon
    return configIcons[type][iconKey] || configIcons[type][fallback];
  }

  // Return null if type or name not found
  return null;
}

export function arraysAreSame(arr1, arr2) {
  console.log(arr1, arr2);
  if (arr1.length != arr2.length) return false;

  for (let i = 0; i < arr1.length; i++) {
    if (arr1[i] != arr2[i]) return false;
  }

  return true;
}

export function decideColor(strkPrice, top3s, defaulter) {
  if (strkPrice === top3s[0]) {
    return chartColor.yellow;
  }
  if (strkPrice === top3s[1]) {
    return chartColor.opacYellow;
  }
  if (strkPrice === top3s[2]) {
    return chartColor.opacYellowMore;
  }

  if (isBetween(top3s[2], top3s[0], top3s[1])) {
    if (isBetween(strkPrice, top3s[0], top3s[2])) {
      return chartColor.opacYellow;
    }
    if (isBetween(strkPrice, top3s[1], top3s[2])) {
      return chartColor.opacYellowMore;
    }
  }

  if (isBetween(top3s[1], top3s[0], top3s[2])) {
    if (isBetween(strkPrice, top3s[0], top3s[1])) {
      return chartColor.opacYellow;
    }
    if (isBetween(strkPrice, top3s[1], top3s[2])) {
      return chartColor.opacYellowMore;
    }
  }

  if (isBetween(top3s[0], top3s[1], top3s[2])) {
    if (isBetween(strkPrice, top3s[0], top3s[1])) {
      return chartColor.opacYellow;
    }
    if (isBetween(strkPrice, top3s[0], top3s[2])) {
      return chartColor.opacYellowMore;
    }
  }

  return defaulter; // Default color for prices out of range
}

export function isBetween(middle, first, second) {
  return Math.min(first, second) <= middle && middle <= Math.max(first, second);
}

export function sortDatesWebSockA1(dates) {
  // Map month abbreviations to numbers (0 for January, 11 for December)
  const monthMap = {
    JAN: 0,
    FEB: 1,
    MAR: 2,
    APR: 3,
    MAY: 4,
    JUN: 5,
    JUL: 6,
    AUG: 7,
    SEP: 8,
    OCT: 9,
    NOV: 10,
    DEC: 11,
  };

  // Helper function to parse the date
  function parseDate(dateStr) {
    const day = parseInt(dateStr.slice(0, 2));
    const month = dateStr.slice(2, 5);
    const year = parseInt(dateStr.slice(5));

    return new Date(year, monthMap[month], day);
  }

  // Sort the dates after parsing them into JavaScript Date objects
  return dates.sort((a, b) => parseDate(a) - parseDate(b));
}

export function isObject(value) {
  return typeof value === 'object' && value !== null;
}