import React, { useEffect, useState } from "react";
import { commonApiGet } from "../../utils/api";
import QFLoader from "../vision/QFLoader";
import { useSelector } from "react-redux";
import {
  camelCaseToSentence,
  getRandomStockMessage,
  themedColor,
} from "../../utils/configs";
import { notificationTopup } from "../../utils/NotificationTopup";
import CommonCardView from "../vision/CommonCardView";
import {
  sharkFolioSecondPage,
  sharkholdings,
  shortSharkholdings,
} from "../../utils/req-res-controllers/sampleResponses";

import {
  ButtonDropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap";
import VisOops from "../vision/VisOops";
import ModalQF from "../vision/ModalQF";
const SharkFolio = () => {
  const theme = useSelector((state) => state.theme);
  const options = {
    IND: "IND",
    INS: "INS",
    FII: "FII",
  };

  const option2DataTypes = ["holdings", "financial", "shareholding"];

  const [isPreviousSelected, setIsPreviousSelected] = useState(0);
  const [selectedOptionFor2ndOption, setSelectedOptionFor2ndOption] = useState(
    option2DataTypes[0]
  );
  const initialSecondState = {
    subset3Data: {
      data: {},
    },
    subset2Type: null,
    subset2Data: {
      data: [],
    },
    subset4Type: {
      nseId: "NA",
      portfolioId: -1,
      stockName: "Not Available",
    },
    subset4Data: {
      data: [],
    },
  };

  const dataMappings = {
    cr: ["val", "cap", "revenue", "profit"],
    rupee: ["val", "cap", "revenue", "profit", "dividend"],
    per: ["per", "promoter", "fii", "dii", "public"],
  };
  const [selectedShark, setSelectedShark] = useState({});
  const [selectedSharkData, setSelectedSharkData] =
    useState(initialSecondState);
  const [dropdownOptionOpen, setDropdownOptionOpen] = useState(false);
  const ddootoggle = () => setDropdownOptionOpen((prevState) => !prevState);
  const [isExpand, setIsExpand] = useState(false);
  const [dataLoadNext, setDataLoadNext] = useState(-1);
  const [data, setData] = useState([]);
  const [selectedOption, setSelectedOption] = useState("IND");
  const [header, setHeader] = useState("");

  const [loaderShow, setLoaderShow] = useState(true);
  const [errorShow, setErrorShow] = useState(false);
  const [search, setSearch] = useState("");

  const [showSecondPage, setShowSecondPage] = useState(false);
  const [apiCaller, setApiCaller] = useState(0);

  const modalCloseFunc = (
    closeVals = {
      selectedSharkDataCF: initialSecondState,
      showSecondPageCF: false,
      dataLoadNextCF: -1,
    }
  ) => {
    setSelectedSharkData(closeVals.selectedSharkDataCF);
    setShowSecondPage(closeVals.showSecondPageCF);
    setDataLoadNext(closeVals.dataLoadNextCF);
  };

  useEffect(() => {
    (async () => {
      await commonApiGet(
        `/market/sharkfolio?subcat=1&reqtype=${selectedOption}`
      )
        .then((res) => {
          setData(
            res.data.sort(
              (a, b) => parseFloat(b.netWorth) - parseFloat(a.netWorth)
            )
          );
          setSearch("");
          setLoaderShow(false);
          setErrorShow(false);
        })
        .catch((err) => {
          notificationTopup(err.message);
          setErrorShow(true);
          setLoaderShow(false);
        });
    })();
  }, [selectedOption, apiCaller]);

  useEffect(() => {
    (async () => {
      if (dataLoadNext >= 0) {
        await commonApiGet(
          `/market/sharkfolio?subcat=3&reqtype=${selectedShark.slug}`
        )
          .then((res) => {
            setSelectedSharkData((prev) => {
              return {
                ...prev,
                subset3Data: {
                  data: res.data,
                },
              };
            });
          })
          .catch((err) => {
            notificationTopup(err.message);
          });
      }
    })();
  }, [selectedShark]);

  useEffect(() => {
    (async () => {
      if (dataLoadNext >= 0) {
        await commonApiGet(
          `/market/sharkfolio?subcat=2&reqtype=${selectedShark.portfolioId}&dataType=${selectedOptionFor2ndOption}`
        )
          .then((res) => {
            setSelectedSharkData((prev) => {
              return {
                ...prev,
                subset2Type: selectedOptionFor2ndOption,
                subset2Data: {
                  data: res.data,
                },
              };
            });
          })
          .catch((err) => {
            notificationTopup(err.message);
          });
      }
    })();
  }, [selectedShark, selectedOptionFor2ndOption]);

  useEffect(() => {
    (async () => {
      if (dataLoadNext >= 0 && showSecondPage) {
        await commonApiGet(
          `/market/sharkfolio?subcat=4&reqtype=${selectedSharkData.subset4Type.portfolioId}&dataType=${selectedSharkData.subset4Type.nseId}`
        )
          .then((res) => {
            setSelectedSharkData((prev) => {
              return {
                ...prev,
                subset4Data: {
                  data: res.data,
                },
              };
            });
          })
          .catch((err) => {
            notificationTopup(err.message);
          });
      }
    })();
  }, [selectedShark, showSecondPage]);

  // async function pal(val){
  //   await commonApiGet(
  //     `/market/sharkfolio?subcat=3&reqtype=${val.slug}`
  //   )
  //     .then((res) => {
  //     })
  //     .catch((err) => {
  //     });
  // }

  // useEffect(() => {
  //   if (data.length > 0) {
  //     data.map((val) => {
  //       pal(val)
  //     });
  //   }
  // }, [data]);

  return (
    <CommonCardView
      id="commonCard_sharkFolio"
      header="SharkFolio"
      headerInfo={{
        show: true,
        desc: "Big Players Investments details",
        id: "shark-folio",
      }}
      headerAlign="left"
      style={{
        ...(!isExpand
          ? {
              position: "relative",
            }
          : {}),
      }}
    >
      {!isExpand ? (
        <ButtonDropdown
          isOpen={dropdownOptionOpen}
          toggle={ddootoggle}
          className="tab-weight absolute-tabGrp"
        >
          <DropdownToggle
            caret
            size="sm"
            color="transparent"
            className="general-nmphc-des text-color-themed mt-1"
            style={{
              fontSize: "12px",
            }}
          >
            {options[selectedOption]}
          </DropdownToggle>
          <DropdownMenu
            className="general-nmphc-des non-trans-bg"
            style={{
              maxHeight: "220px",
              overflowY: "scroll",
            }}
          >
            {Object.keys(options).map((val, key) => (
              <DropdownItem
                className="bg-set-on-active text-color-themed"
                key={`QF_SHARKPF_KEYS_${key}`}
                onClick={() => {
                  setIsPreviousSelected(0);
                  setLoaderShow(true);
                  setData([]);
                  setSelectedOption(val);
                }}
                style={{
                  fontSize: "12px",
                }}
                disabled={val === selectedOption ? true : false}
              >
                {options[val]}
              </DropdownItem>
            ))}
          </DropdownMenu>
        </ButtonDropdown>
      ) : (
        <></>
      )}
      {errorShow ? (
        <VisOops />
      ) : loaderShow ? (
        <QFLoader />
      ) : (
        <>
          <div className=" make-me-sticky non-trans-bg py-2">
            <input
              type="text"
              className="qfInputType py-1 px-2"
              placeholder="Search"
              onChange={(e) => {
                e.preventDefault();
                setSearch(e.target.value);
              }}
              value={search}
            />
          </div>
          <>
            <table
              style={{
                width: "100%",
              }}
            >
              <thead
                className=" make-me-sticky non-trans-bg"
                style={{
                  top: 40,
                }}
              >
                <tr>
                  <th>Name</th>
                  <th>Networth</th>
                  <th>Holdings</th>
                </tr>
              </thead>
              <tbody>
                {data
                  .filter((val) =>
                    val.name.toLowerCase().includes(search.toLowerCase())
                  )
                  .map((val, index) => {
                    return (
                      <tr
                        onClick={() => {
                          setIsPreviousSelected(index);
                          setDataLoadNext(1);
                          setHeader(val.name);
                          setSelectedShark(val);
                          setApiCaller(apiCaller + 1);
                        }}
                        key={`SharkFolio_dataRecords_key_${index}`}
                        style={{
                          borderTop: `1px solid ${
                            theme === "dark"
                              ? themedColor.dark
                              : themedColor.light
                          }`,
                        }}
                        className={`c-pointer tr-hover-focus-selection ${
                          isPreviousSelected === index
                            ? " tr-hover-focus-selectionDefault"
                            : ""
                        }`}
                      >
                        <td className="py-1">
                          <span>{val.name}</span>
                        </td>
                        <td
                          className="py-1"
                          style={
                            {
                              // textAlign: "right",
                            }
                          }
                        >
                          ₹{val.netWorth}Cr.
                        </td>
                        <td
                          className="py-1"
                          style={{
                            textAlign: "right",
                          }}
                        >
                          #{val.noOfCompanies}
                        </td>
                      </tr>
                    );
                  })}
                {data.filter((val) =>
                  val.name.toLowerCase().includes(search.toLowerCase())
                ).length === 0 && getRandomStockMessage()}
              </tbody>
            </table>
          </>
          {dataLoadNext === -1 ? (
            <></>
          ) : (
            <ModalQF
              isExpandBtn={true}
              expandVal={isExpand}
              dataClearFunc={setDataLoadNext}
              dataClearTo={-1}
              expandFunc={setIsExpand}
              header={header}
              // desc={innerDesc}
              data={
                <>
                  {Object.keys(selectedSharkData.subset3Data.data).length ===
                    0 ||
                  Object.keys(selectedSharkData.subset3Data.data.increase)
                    .length +
                    Object.keys(selectedSharkData.subset3Data.data.decrease)
                      .length ===
                    0 ? (
                    <center>
                      NO CHANGES IN THIS QUARTER
                      <hr />
                    </center>
                  ) : (
                    <center>Some Data, plz develop further</center>
                  )}
                  {selectedSharkData.subset2Data.data.length === 0 ? (
                    <div
                      style={{
                        height: "200px",
                      }}
                    >
                      <QFLoader />
                    </div>
                  ) : (
                    <>
                      <div className="row px-2 m-0">
                        {option2DataTypes.map((val, key) => {
                          return (
                            <div className="py-1 px-1 col-4" key={key}>
                              <div
                                className={
                                  (selectedOptionFor2ndOption == val
                                    ? "general-nmphc-in-chart-i-desV2"
                                    : "general-nmphc-desV2") +
                                  " text-center mt-0 c-pointer"
                                }
                                onClick={() => {
                                  setSelectedOptionFor2ndOption(val);
                                  setSelectedSharkData((prev) => ({
                                    ...prev,
                                    subset2Type: val,
                                    subset2Data: {
                                      data: [],
                                    },
                                  }));
                                }}
                              >
                                {camelCaseToSentence(
                                  val.replace("shareholding", "stake")
                                )}
                              </div>
                            </div>
                          );
                        })}
                      </div>
                      <div
                        style={{
                          overflow: "scroll",
                          // overflowY: "scroll",
                          ...(isExpand
                            ? { maxHeight: "65vh" }
                            : { maxHeight: "250px" }),
                        }}
                      >
                        <table className="w-100 table-tr-bb">
                          <thead
                            className="make-me-sticky non-trans-bg"
                            style={{
                              textWrap: "nowrap",
                            }}
                          >
                            <tr>
                              {Object.keys(
                                (!isExpand
                                  ? shortSharkholdings
                                  : sharkholdings)[selectedOptionFor2ndOption]
                              ).map((valForHead, keyForHead) => {
                                return (
                                  <th key={`${keyForHead}`} className="px-2">
                                    {camelCaseToSentence(valForHead)}
                                  </th>
                                );
                              })}
                            </tr>
                          </thead>
                          <tbody>
                            {selectedSharkData.subset2Data.data[0].data.map(
                              (valForBody, keyForBody) => {
                                return (
                                  <tr key={`${keyForBody}`}>
                                    {Object.keys(
                                      (!isExpand
                                        ? shortSharkholdings
                                        : sharkholdings)[
                                        selectedOptionFor2ndOption
                                      ]
                                    ).map((valForBody2, keyForBody2) => {
                                      return (
                                        <td
                                          key={`${keyForBody2}`}
                                          className="px-2"
                                        >
                                          {dataMappings.rupee
                                            .map((val) =>
                                              valForBody2
                                                .toLowerCase()
                                                .includes(val)
                                            )
                                            .filter((a) => a === true).length >
                                          0
                                            ? "₹"
                                            : ""}
                                          {valForBody2 === "stockName" &&
                                          selectedOptionFor2ndOption ===
                                            "holdings" &&
                                          valForBody.nseCode.trim() !== "" ? (
                                            <span
                                              onClick={() => {
                                                setSelectedSharkData({
                                                  ...selectedSharkData,
                                                  subset4Type: {
                                                    nseId: valForBody.nseCode,
                                                    portfolioId:
                                                      valForBody.portfolioId,
                                                    stockName:
                                                      valForBody.stockName,
                                                  },
                                                });
                                                setShowSecondPage(true);
                                              }}
                                              className="c-pointer"
                                              style={{
                                                fontWeight: "bold",
                                              }}
                                            >
                                              {valForBody[valForBody2]}
                                            </span>
                                          ) : (
                                            valForBody[valForBody2]
                                          )}
                                          {dataMappings.per
                                            .map((val) =>
                                              valForBody2
                                                .toLowerCase()
                                                .includes(val)
                                            )
                                            .filter((a) => a === true).length >
                                          0
                                            ? "%"
                                            : dataMappings.cr
                                                .map((val) =>
                                                  valForBody2
                                                    .toLowerCase()
                                                    .includes(val)
                                                )
                                                .filter((a) => a === true)
                                                .length > 0
                                            ? "Cr."
                                            : ""}
                                        </td>
                                      );
                                    })}
                                  </tr>
                                );
                              }
                            )}
                          </tbody>
                        </table>
                      </div>
                    </>
                  )}
                </>
              }
              secondPage={{
                able: true,
                showSecond: showSecondPage,
                data: (
                  <>
                    {selectedSharkData.subset4Data.data.length > 0 ? (
                      <div
                        style={{
                          overflow: "scroll",
                          ...(isExpand
                            ? { maxHeight: "70vh" }
                            : { maxHeight: "300px" }),
                        }}
                      >
                        <table className="w-100 table-tr-bb">
                          <thead
                            className="make-me-sticky non-trans-bg"
                            style={{
                              textWrap: "nowrap",
                            }}
                          >
                            <tr>
                              {Object.keys(
                                !isExpand
                                  ? sharkFolioSecondPage.minified
                                  : sharkFolioSecondPage.full
                              ).map((valForHead, keyForHead) => {
                                return (
                                  <th key={`${keyForHead}`} className="px-2">
                                    {camelCaseToSentence(valForHead)}
                                  </th>
                                );
                              })}
                            </tr>
                          </thead>
                          <tbody>
                            {selectedSharkData.subset4Data.data.map(
                              (valForBody, keyForBody) => {
                                return (
                                  <tr key={`${keyForBody}`}>
                                    {Object.keys(
                                      !isExpand
                                        ? sharkFolioSecondPage.minified
                                        : sharkFolioSecondPage.full
                                    ).map((valForBody2, keyForBody2) => {
                                      return (
                                        <td
                                          key={`${keyForBody2}`}
                                          className="px-2"
                                        >
                                          {dataMappings.rupee
                                            .map((val) =>
                                              valForBody2
                                                .toLowerCase()
                                                .includes(val)
                                            )
                                            .filter((a) => a === true).length >
                                          0
                                            ? "₹"
                                            : ""}
                                          {valForBody[valForBody2]}
                                          {dataMappings.per
                                            .map((val) =>
                                              valForBody2
                                                .toLowerCase()
                                                .includes(val)
                                            )
                                            .filter((a) => a === true).length >
                                          0
                                            ? "%"
                                            : dataMappings.cr
                                                .map((val) =>
                                                  valForBody2
                                                    .toLowerCase()
                                                    .includes(val)
                                                )
                                                .filter((a) => a === true)
                                                .length > 0
                                            ? "Cr."
                                            : ""}
                                        </td>
                                      );
                                    })}
                                  </tr>
                                );
                              }
                            )}
                          </tbody>
                        </table>
                      </div>
                    ) : (
                      <div
                        style={{
                          height: "200px",
                        }}
                      >
                        <QFLoader />
                      </div>
                    )}
                  </>
                ),
                backFunction: () => {
                  setSelectedSharkData((prev) => {
                    return {
                      ...prev,
                      subset4Type: initialSecondState.subset4Type,
                      subset4Data: initialSecondState.subset4Data,
                    };
                  });
                  setShowSecondPage(false);
                },
                header: selectedSharkData.subset4Type.stockName,
              }}
              closeVal={{
                selectedSharkDataCF: initialSecondState,
                showSecondPageCF: false,
                dataLoadNextCF: -1,
              }}
              closeVar={modalCloseFunc}
            />
          )}
        </>
      )}
    </CommonCardView>
  );
};

export default SharkFolio;
