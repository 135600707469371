import React, { useEffect, useRef, useState } from "react";
import { Nav, NavItem, NavLink } from "reactstrap";
import { chartColor, themedColor } from "../../utils/configs";
import { commonApiGet } from "../../utils/api";
import { notificationTopup } from "../../utils/NotificationTopup";
import QFLoader from "../../components/vision/QFLoader";
import VisOops from "../../components/vision/VisOops";
import { Link, useParams } from "react-router-dom";
import VIsAdvancerDeclinerUnchanged from "../../components/vision/VIsAdvancerDeclinerUnchanged";
import VisSectorHeatMap from "../../components/vision/charts/VIsSectorHeatMap";

const IndicesFunda = ({ fullView }) => {
  const { exchange, symbol } = useParams();
  const [indexData, setIndexData] = useState({});
  const [selectedTab, setSelectedTab] = useState("details");

  const [loaderShow, setLoaderShow] = useState(true);
  const [errorShow, setErrorShow] = useState(false);

  const [expandedIndex, setExpandedIndex] = useState(false);

  const [search, setSearch] = useState("");

  const searchFilter = (val) => {
    const searchOn = val.symbol + "-" + val.series;
    // console.log(searchOn)
    return searchOn.toUpperCase().includes(search.toUpperCase());
  };

  useEffect(() => {
    (async () => {
      await commonApiGet(
        `/stocks/stocks_in_index?index=${symbol}&exch=${exchange}`,
        "FUNDAMENTAL_INDICES",
        false
      )
        .then((res) => {
          setIndexData(res.data);
          setLoaderShow(false);
          setErrorShow(false);
        })
        .catch((err) => {
          notificationTopup(err.message);
          setLoaderShow(false);
          setErrorShow(true);
        });
    })();
  }, []);

  const tabsConfig = {
    details: {
      name: "Sector Heatmap",
      component: (
        <>
          <VisSectorHeatMap symbol={symbol} name={fullView.promisedData.name} />
        </>
      ),
    },
    stocksList: {
      name: `Stocks in ${fullView.symbol}`,
      component: (
        <>
          {
            <div className="col-md-12 pt-2">
              <div
                className="row make-me-sticky non-trans-bg pt-2"
                style={{
                  top: 35,
                }}
              >
                <b>Index Stocks:</b>
                <br />
                <div className="py-2 col-md-4">
                  <input
                    type="text"
                    className="qfInputType py-1 px-2"
                    style={
                      {
                        // maxWidth: '600px'
                      }
                    }
                    placeholder="Search"
                    onChange={(e) => {
                      e.preventDefault();
                      setSearch(e.target.value);
                    }}
                    value={search}
                  />
                </div>
              </div>
              <div
                className="make-me-sticky non-trans-bg"
                style={{
                  top: 95,
                }}
              >
                <VIsAdvancerDeclinerUnchanged gainerLoser={indexData} />
              </div>
              <div className="">
                {indexData.data &&
                  indexData.data
                    .filter(searchFilter)
                    .filter((val) => val.priority === 0)
                    .filter((val, ind) => {
                      return ind < 50 || expandedIndex;
                    })
                    .map((val, key) => {
                      return (
                        <Link
                          to={`/info/${exchange}/${val.symbol}-${val.series}`}
                        >
                          <div
                            className="our-border div-pill me-1 mt-1 mb-1 div-pill-hoverable"
                            style={{
                              textWrap: "nowrap",
                              display: "inline-block",
                              // fontWeight: "normal",
                              fontSize: "x-small",
                              color:
                                val.pChange < 0
                                  ? chartColor.red
                                  : val.pChange == 0
                                  ? 'var(--text-color)'
                                  : chartColor.green,
                            }}
                            key={key}
                          >
                            {val.symbol}-{val.series} {val.pChange}%
                          </div>
                        </Link>
                      );
                    })}
                {indexData.data &&
                indexData.data.filter(searchFilter).length > 51 ? (
                  <>
                    {expandedIndex ? (
                      <div
                        className="our-border div-pill me-1 mt-1 mb-1 div-pill-hoverable"
                        style={{
                          textWrap: "nowrap",
                          display: "inline-block",
                          // fontWeight: "normal",
                          fontSize: "x-small",
                        }}
                        onClick={() => setExpandedIndex(false)}
                      >
                        ...less
                      </div>
                    ) : (
                      <div
                        className="our-border div-pill me-1 mt-1 mb-1 div-pill-hoverable"
                        style={{
                          textWrap: "nowrap",
                          display: "inline-block",
                          // fontWeight: "normal",
                          fontSize: "x-small",
                        }}
                        onClick={() => setExpandedIndex(true)}
                      >
                        more...
                      </div>
                    )}
                  </>
                ) : (
                  <></>
                )}
              </div>
            </div>
          }
        </>
      ),
    },
  };

  return (
    <div
      className="row"
      style={{
        position: "relative",
        maxHeight: "400px",
        overflowY: "scroll",
      }}
    >
      <Nav
        tabs
        style={{
          color: "var(--text-color) !important",
        }}
        className="mb-3 make-me-sticky non-trans-bg"
      >
        {Object.keys(tabsConfig).map((key, index) => (
          <NavItem
            key={index}
            onClick={() => {
              setSelectedTab(key);
            }}
          >
            <NavLink
              active={selectedTab === key ? true : false}
              style={{
                color:
                  selectedTab === key ? themedColor.light : "var(--text-color)",
              }}
            >
              {tabsConfig[key].name}
            </NavLink>
          </NavItem>
        ))}
      </Nav>
      {loaderShow ? (
        <QFLoader />
      ) : errorShow ? (
        <VisOops />
      ) : (
        tabsConfig[selectedTab].component
      )}
    </div>
  );
};

export default IndicesFunda;
