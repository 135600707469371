import React, {
  useEffect,
  useRef,
  useState,
  useMemo,
  useCallback,
} from "react";
import CommonCardView from "../vision/CommonCardView";
import {
  ButtonDropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap";
import VisOops from "../vision/VisOops";
import QFLoader from "../vision/QFLoader";
import {
  chartColor,
  decideColor,
  isBetween,
  sortDatesWebSockA1,
  webSockQF,
} from "../../utils/configs";
import { groupINRCurrencyNSE } from "../../utils/functions";
import hawkQuiver, { appendJourney } from "../../utils/hawkeye/hawkQuiver";

const OptionChain = () => {
  const tableContainerRef = useRef(null);

  const [underlying, setUnderlying] = useState({});

  const [optionChain, setOptionChain] = useState({});
  const [optionExDates, setOptionExDates] = useState([]);
  const [option, setOption] = useState("NIFTY");
  const [loaderShow, setLoaderShow] = useState(true);
  const [errorShow, setErrorShow] = useState(false);
  const [dropdownOptionOpen, setDropdownOptionOpen] = useState(false);
  const [dropdownOptionOpenDates, setDropdownOptionOpenDates] = useState(false);
  const [optionsDate, setOptionsDate] = useState(undefined);
  const [callCheck, setCallCheck] = useState(0);
  const [toScrollSetMiddleHR, setToScrollSetMiddleHR] = useState(-1);
  const [topThrees, setTopThrees] = useState({
    call: {
      byVolume: [],
      byOI: [],
    },
    put: {
      byVolume: [],
      byOI: [],
    },
  });

  const ddootoggle = useCallback(
    () => setDropdownOptionOpen((prevState) => !prevState),
    []
  );
  const ddootoggleDates = useCallback(
    () => setDropdownOptionOpenDates((prevState) => !prevState),
    []
  );

  const [apiChecker, setApiChecker] = useState(0);

  const [websoc, isWebsoc] = useState(false);

  useEffect(() => {
    if (!websoc) {
      console.log("hi");
      const socket = new WebSocket(webSockQF);

      // WebSocket event listeners
      socket.onopen = () => {
        hawkQuiver.dispatch(
          appendJourney(
            `${new Date().getTime()}_QF-WEBSOCKET_${"OPTIONCHAIN"}_WSS: ${option} | ${
              optionsDate || ""
            }`
          )
        );
        console.log("WebSocket connected:", option);
        socket.send(
          JSON.stringify({
            channel: option.toLowerCase(),
            expiry: optionsDate || "",
          })
        );
      };

      socket.onmessage = (event) => {
        const data = JSON.parse(event.data);

        const gotDates = sortDatesWebSockA1(data.expiryDates || []);

        if (data.message !== undefined) {
          return;
        }

        const preData = data.optionChain?.reduce((acc, curr) => {
          const { strikePrice, optionType } = curr;
          if (!acc[strikePrice]) acc[strikePrice] = {};
          acc[strikePrice][optionType] = curr;
          return acc;
        }, {});

        setOptionChain({
          data: preData,
          Exp: optionsDate || gotDates.length > 0 ? gotDates[0] : "date",
        });
        if (gotDates.length > 0) {
          setOptionExDates(gotDates);
          setOptionsDate(optionsDate || gotDates[0]);
        }
        setLoaderShow(false);
        setErrorShow(false);
        setToScrollSetMiddleHR((prevState) => prevState + 1);
        setUnderlying(data.underlying);
      };

      socket.onclose = () => {
        console.log("WebSocket disconnected:", option);
      };

      isWebsoc(true); // Mark WebSocket connections as established

      // Clean-up function to close WebSocket connections when component unmounts
      return () => {
        isWebsoc(false);
        socket.close();
        console.log("WebSocket closed:", option);
      };
    }
  }, [apiChecker]);

  const strikePrices = useMemo(() => {
    return optionChain.data
      ? Object.keys(optionChain.data).map(parseFloat)
      : [];
  }, [optionChain]);

  const underlyingValue = useMemo(() => {
    return optionChain.data
      ? optionChain.data[Object.keys(optionChain.data)[0]]?.CE?.underlyingValue
      : null;
  }, [optionChain]);

  const calculateNearestStrikePrice = useMemo(() => {
    if (!optionChain.data || !underlyingValue) return null;
    return strikePrices.reduce((prev, curr) =>
      Math.abs(curr - underlyingValue) < Math.abs(prev - underlyingValue)
        ? curr
        : prev
    );
  }, [strikePrices, underlyingValue]);

  const getTop3OIOTM = (optionChain, underlyingValue) => {
    if (!optionChain.data || !underlyingValue)
      return { topCallOI: [], topPutOI: [], topCallVol: [], topPutVol: [] };

    // Separate Call OTM and Put OTM
    const callOTM = [];
    const putOTM = [];

    Object.keys(optionChain.data).forEach((strikePrice) => {
      const price = parseFloat(strikePrice);
      const callData = optionChain.data[strikePrice]?.CE;
      const putData = optionChain.data[strikePrice]?.PE;

      if (price > underlyingValue && callData) {
        // Call is OTM when strikePrice > underlyingValue
        callOTM.push({ strikePrice: price, ...callData });
      }

      if (price < underlyingValue && putData) {
        // Put is OTM when strikePrice < underlyingValue
        putOTM.push({ strikePrice: price, ...putData });
      }
    });

    // Sort the OTM options by open interest (OI) in descending order and return the top 3
    const topCallOI = callOTM
      .sort((a, b) => b.openInterest - a.openInterest)
      .slice(0, 3);

    const topPutOI = putOTM
      .sort((a, b) => b.openInterest - a.openInterest)
      .slice(0, 3);

    // Sort the OTM options by total traded volume in descending order and return the top 3
    const topCallVol = callOTM
      .sort((a, b) => b.totalTradedVolume - a.totalTradedVolume)
      .slice(0, 3);

    const topPutVol = putOTM
      .sort((a, b) => b.totalTradedVolume - a.totalTradedVolume)
      .slice(0, 3);

    return { topCallOI, topPutOI, topCallVol, topPutVol };
  };

  useEffect(() => {
    if (optionChain.data && underlyingValue) {
      const { topCallOI, topPutOI, topCallVol, topPutVol } = getTop3OIOTM(
        optionChain,
        underlyingValue
      );
      setTopThrees({
        call: {
          byVolume: topCallVol.map((val) => val.strikePrice),
          byOI: topCallOI.map((val) => val.strikePrice),
        },
        put: {
          byVolume: topPutVol.map((val) => val.strikePrice),
          byOI: topPutOI.map((val) => val.strikePrice),
        },
      });
    }
  }, [optionChain, underlyingValue]);

  useEffect(() => {
    if (toScrollSetMiddleHR < 1 && calculateNearestStrikePrice > 0) {
      const tableContainer = tableContainerRef.current;
      const index = strikePrices.indexOf(calculateNearestStrikePrice);
      if (tableContainer)
        tableContainer.scrollTop =
          index * 45 + 25 - tableContainer.clientHeight / 2;
    }
  }, [toScrollSetMiddleHR, strikePrices, calculateNearestStrikePrice]);

  return (
    <CommonCardView
      id="commonCard_optionChain"
      sharable={false}
      header="Option Chain"
      headerInfo={{
        show: true,
        desc: "Option Chain (The most informative data on planet)",
        id: "option-chain",
      }}
      headerAlign="left"
      style={{ position: "relative" }}
      className="zoo-optionChn"
    >
      <div className="tab-weight absolute-tabGrp">
        <ButtonDropdown isOpen={dropdownOptionOpen} toggle={ddootoggle}>
          <DropdownToggle
            caret
            size="sm"
            color="transparent"
            className="general-nmphc-des text-color-themed mt-1 mx-2"
            style={{ fontSize: "12px" }}
          >
            {option}
          </DropdownToggle>
          <DropdownMenu
            className="general-nmphc-des non-trans-bg"
            style={{ maxHeight: "220px", overflowY: "scroll" }}
          >
            {["NIFTY", "BANKNIFTY", "FINNIFTY", "SENSEX", "BANKEX"].map(
              (val, key) => (
                <DropdownItem
                  key={key}
                  className="bg-set-on-active text-color-themed"
                  onClick={() => {
                    setOption(val);
                    setLoaderShow(true);
                    setOptionChain({});
                    setOptionsDate(undefined);
                    setToScrollSetMiddleHR(-1);
                    setApiChecker(apiChecker + 1);
                    isWebsoc(false);
                  }}
                  disabled={val === option}
                  style={{ fontSize: "12px" }}
                >
                  {val}
                </DropdownItem>
              )
            )}
          </DropdownMenu>
        </ButtonDropdown>

        <ButtonDropdown
          isOpen={dropdownOptionOpenDates}
          toggle={ddootoggleDates}
        >
          <DropdownToggle
            caret
            size="sm"
            color="transparent"
            className="general-nmphc-des text-color-themed mt-1"
            style={{ fontSize: "12px" }}
          >
            {optionsDate || "date"}
          </DropdownToggle>
          <DropdownMenu
            className="general-nmphc-des non-trans-bg"
            style={{ maxHeight: "220px", overflowY: "scroll" }}
          >
            {optionExDates.map((val, key) => (
              <DropdownItem
                key={key}
                className="bg-set-on-active text-color-themed"
                onClick={() => {
                  setOptionsDate(val);
                  setLoaderShow(true);
                  setOptionChain({});
                  setCallCheck(callCheck + 1);
                  setToScrollSetMiddleHR(-1);
                  setApiChecker(apiChecker + 1);
                  isWebsoc(false);
                }}
                disabled={val === optionsDate}
                style={{ fontSize: "12px" }}
              >
                {val}
              </DropdownItem>
            ))}
          </DropdownMenu>
        </ButtonDropdown>
      </div>

      {errorShow ? (
        <VisOops />
      ) : loaderShow ? (
        <QFLoader />
      ) : (
        <>
          <div className="row make-me-sticky non-trans-bg section-bb">
            <div className="col-5 text-center">
              CALL
              <hr className="m-0 p-0" />
              <div className="row">
                <div className="col-4" style={{ textAlign: "left" }}>
                  Vol
                </div>
                <div className="col-4" style={{ textAlign: "left" }}>
                  OI
                </div>
                <div className="col-4" style={{ textAlign: "left" }}>
                  LTP
                </div>
              </div>
            </div>
            <div className="col-2 text-center">
              STRIKE
              <hr className="m-0 p-0" />
              PCR
            </div>
            <div className="col-5 text-center">
              PUT
              <hr className="m-0 p-0" />
              <div className="row">
                <div className="col-4" style={{ textAlign: "right" }}>
                  LTP
                </div>
                <div className="col-4" style={{ textAlign: "right" }}>
                  OI
                </div>
                <div className="col-4" style={{ textAlign: "right" }}>
                  Vol
                </div>
              </div>
            </div>
          </div>
          <div className="table-container">
            <div
              className="row px-1 table-td-brdr section-bb"
              ref={tableContainerRef}
              style={{
                overflowY: "scroll",
                overflowX: "hidden",
                maxHeight: "350px",
              }}
            >
              <div className="col-5 call-put-sec-in-table-div p-0">
                <table
                  style={{ width: "100%" }}
                  className="table-container-inclpt"
                >
                  <tbody>
                    {Object.keys(optionChain.data).map((val, index) => {
                      const strikePrice = parseFloat(val);
                      const underlyingValue =
                        optionChain.data[Object.keys(optionChain.data)[0]]?.CE
                          ?.underlyingValue;
                      const isCallITM = strikePrice < underlyingValue;
                      const callBgColor = isCallITM
                        ? chartColor.opacGreenMore
                        : chartColor.opacRedMore;
                      const nxtStrkPrc =
                        optionChain.data[
                          Object.keys(optionChain.data)[index + 1]
                        ]?.CE?.strikePrice;
                      let toShowUV = isBetween(
                        parseFloat(underlyingValue),
                        strikePrice,
                        parseFloat(nxtStrkPrc)
                      );
                      return (
                        <React.Fragment key={`QF_OC_INDEX_KEY_CALL_${index}`}>
                          <tr>
                            <td
                              style={{
                                backgroundColor:
                                  strikePrice > underlyingValue
                                    ? decideColor(
                                        strikePrice,
                                        topThrees.call.byVolume,
                                        callBgColor
                                      )
                                    : callBgColor,
                                textAlign: "left",
                              }}
                            >
                              {groupINRCurrencyNSE(
                                optionChain.data[val]?.CE?.totalTradedVolume
                              ) || "-"}
                              <br />
                              <span>
                                IV:
                                {optionChain.data[val]?.CE?.impliedVolatility}
                              </span>
                            </td>
                            <td
                              style={{
                                backgroundColor:
                                  strikePrice > underlyingValue
                                    ? decideColor(
                                        strikePrice,
                                        topThrees.call.byOI,
                                        callBgColor
                                      )
                                    : callBgColor,
                                textAlign: "left",
                              }}
                            >
                              {groupINRCurrencyNSE(
                                optionChain.data[val]?.CE?.openInterest
                              ) || "-"}
                              <br />
                              {"-"}
                              {/* <span>
                              <span>
                                {groupINRCurrencyNSE(
                                  optionChain.data[val]?.CE
                                    ?.changeinOpenInterest
                                )}
                              </span> */}
                            </td>
                            <td
                              style={{
                                backgroundColor: callBgColor,
                                textAlign: "left",
                              }}
                            >
                              {groupINRCurrencyNSE(
                                optionChain.data[val]?.CE?.lastPrice,
                                true
                              ) || "-"}
                              <br />
                              <span>
                                {groupINRCurrencyNSE(
                                  optionChain.data[val]?.CE?.pChange
                                )}
                                %
                              </span>
                            </td>
                          </tr>
                          {toShowUV ? (
                            <tr>
                              <td
                                colSpan={3}
                                style={{
                                  borderLeft:
                                    "1px dotted var(--text-color) !important",
                                  borderRight: "none",
                                  textAlign: "right",
                                }}
                              >
                                ₹{underlying.netChange}
                              </td>
                            </tr>
                          ) : (
                            <></>
                          )}
                        </React.Fragment>
                      );
                    })}
                  </tbody>
                </table>
              </div>
              <div className="col-2 p-0">
                <table className="table-container-inclpt w-100 text-center">
                  <tbody>
                    {Object.keys(optionChain.data).map((val, index) => {
                      const strikePrice = parseFloat(val);
                      const nxtStrkPrc =
                        optionChain.data[
                          Object.keys(optionChain.data)[index + 1]
                        ]?.CE?.strikePrice;
                      let toShowUV = isBetween(
                        parseFloat(underlyingValue),
                        strikePrice,
                        parseFloat(nxtStrkPrc)
                      );
                      const pcr = (
                        parseFloat(
                          optionChain.data[val]?.PE?.openInterest || 0
                        ) /
                          parseFloat(
                            optionChain.data[val]?.CE?.openInterest || 0
                          ) || 0
                      ).toFixed(2);

                      return (
                        <React.Fragment key={`QF_OC_INDEX_KEY_STRK_${index}`}>
                          <tr>
                            <td className="no-brdr-x brdr-dtd-y">
                              {strikePrice}
                              <br />
                              <span>{groupINRCurrencyNSE(pcr)}</span>
                            </td>
                          </tr>
                          {toShowUV ? (
                            <tr>
                              <td className="no-brdr">₹{underlyingValue}</td>
                            </tr>
                          ) : (
                            <></>
                          )}
                        </React.Fragment>
                      );
                    })}
                  </tbody>
                </table>
              </div>
              <div className="col-5 call-put-sec-in-table-div p-0">
                <table
                  style={{ width: "100%" }}
                  className="table-container-inclpt"
                >
                  <tbody>
                    {Object.keys(optionChain.data).map((val, index) => {
                      const strikePrice = parseFloat(val);
                      const underlyingValue =
                        optionChain.data[Object.keys(optionChain.data)[0]]?.CE
                          ?.underlyingValue;
                      const isPutITM = strikePrice > underlyingValue;
                      const putBgColor = isPutITM
                        ? chartColor.opacGreenMore
                        : chartColor.opacRedMore;
                      const nxtStrkPrc =
                        optionChain.data[
                          Object.keys(optionChain.data)[index + 1]
                        ]?.CE?.strikePrice;
                      let toShowUV = isBetween(
                        parseFloat(underlyingValue),
                        strikePrice,
                        parseFloat(nxtStrkPrc)
                      );

                      return (
                        <React.Fragment key={`QF_OC_INDEX_KEY_PUT_${index}`}>
                          <tr>
                            <td
                              style={{
                                backgroundColor: putBgColor,
                                textAlign: "right",
                              }}
                            >
                              {groupINRCurrencyNSE(
                                optionChain.data[val]?.PE?.lastPrice,
                                true
                              ) || "-"}
                              <br />
                              <span>
                                {groupINRCurrencyNSE(
                                  optionChain.data[val]?.PE?.pChange
                                )}
                                %
                              </span>
                            </td>
                            <td
                              style={{
                                backgroundColor:
                                  strikePrice < underlyingValue
                                    ? decideColor(
                                        strikePrice,
                                        topThrees.put.byOI,
                                        putBgColor
                                      )
                                    : putBgColor,
                                textAlign: "right",
                              }}
                            >
                              {groupINRCurrencyNSE(
                                optionChain.data[val]?.PE?.openInterest
                              ) || "-"}
                              <br />
                              {"-"}
                              {/* <span>
                                {groupINRCurrencyNSE(
                                  optionChain.data[val]?.PE
                                    ?.changeinOpenInterest
                                )}
                              </span> */}
                            </td>
                            <td
                              style={{
                                backgroundColor:
                                  strikePrice < underlyingValue
                                    ? decideColor(
                                        strikePrice,
                                        topThrees.put.byVolume,
                                        putBgColor
                                      )
                                    : putBgColor,
                                textAlign: "right",
                              }}
                            >
                              {groupINRCurrencyNSE(
                                optionChain.data[val]?.PE?.totalTradedVolume
                              ) || "-"}
                              <br />
                              <span>
                                IV:
                                {optionChain.data[val]?.PE?.impliedVolatility}
                              </span>
                            </td>
                          </tr>
                          {toShowUV ? (
                            <tr>
                              <td
                                colSpan={3}
                                style={{
                                  borderLeft: "none",
                                  borderRight:
                                    "1px dotted var(--text-color) !important",
                                }}
                              >
                                {underlying.percentChange}%
                              </td>
                            </tr>
                          ) : (
                            <></>
                          )}
                        </React.Fragment>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </>
      )}
    </CommonCardView>
  );
};

export default OptionChain;
