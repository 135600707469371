import React, { useEffect, useRef } from "react";
import { monthBtob, sortme } from "./helper";
import Chart from "chart.js/auto";
import { themedColor } from "../../../../utils/configs";
import { useSelector } from "react-redux";

const SebiDebtEquityMonthlyChart = ({ data = [] }) => {
  const chartRef = useRef(null);

  const theme = useSelector((state) => state.theme);
  let dataToSee = {
    Debt: sortme(data.filter((val) => val.category === "Debt")),
    Equity: sortme(data.filter((val) => val.category === "Equity")),
  };

  const calculateHeight = () => {
    const dataSize = Math.max(dataToSee.Debt.length, dataToSee.Equity.length);
    return dataSize * 21 + 100; // 30px per data point + 100px for padding
  };

  useEffect(() => {
    if (!chartRef.current) return;

    const ctx = chartRef.current.getContext("2d");
    const chart = new Chart(ctx, {
      type: "bar",
      data: {
        labels: [...dataToSee.Debt.map((item) => monthBtob(item.recMonth))],
        datasets: [
          {
            label: "Debt",
            data: dataToSee.Debt.map((item) => item["Net Purchase / Sales"]),
            borderColor: "rgb(255, 192, 0)",
            backgroundColor: "rgba(255, 192, 0, 0.2)",
          },
          {
            label: "Equity",
            data: dataToSee.Equity.map((item) => item["Net Purchase / Sales"]),
            borderColor: "rgb(54, 162, 235)",
            backgroundColor: "rgba(54, 162, 235, 0.2)",
          },
        ],
      },
      options: {
        indexAxis: "y",
        elements: {
          bar: {
            borderWidth: 2,
          },
        },
        responsive: true,
        plugins: {
          legend: {
            position: "top",
            display: true,
          },
          title: {
            display: true,
            text: "Debt/Equity Monthly Net Purchase/Sales",
          },
        },
        scales: {
          y: {
            position: "left",
            grid: {
                display: false
            },
            ticks:{
                color: themedColor[theme]
            }
          },
          y1: {
            position: "right",
            grid: {
                display: false
            },
            ticks:{
                color: themedColor[theme]
            }
          },
          x: {
            position: "top",
            grid: {
                display: false
            },
            ticks:{
                color: themedColor[theme]
            }
          },
        },
      },
    });

    return () => {
      chart.destroy();
    };
  }, [data, theme]);

  return (
    <div>
      <canvas ref={chartRef} height={calculateHeight()} />
    </div>
  );
};

export default SebiDebtEquityMonthlyChart;
