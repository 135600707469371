import React, { useEffect } from "react";
import Navbar from "../components/Navbar";
import { HomePageCryptoCard } from "../components/HomePageCryptoCard";
import { useDispatch, useSelector } from "react-redux";
import { HomePageCryptoCardCombined } from "../components/HomePageCryptoCardCombined";
import { useNavigate } from "react-router-dom";
import { exchangeTo } from "../utils/glob-redux/store";

export const HomePage = () => {
  const exchange = useSelector((state) => state.exchange);
  const n = useNavigate();
  const d = useDispatch()
  
  useEffect(() => {
    // This function will run when the component is unmounted
    return () => {
      // Place your cleanup code here
      d(exchangeTo("NSE"))
      // Add any other code you want to run on unload
    };
  }, []); // Empty dependency array means this effect runs once on mount and cleanup on unmount

  if (exchange === "NSE") {
    n("/dashboard");
  }

  const toBeShow_Crypto = [
    {
      tickerSymbol: "BTCUSD",
      spotSymbol: ".DEXBTUSDT",
      sparklinesym: "BTCUSD",
    },
    {
      tickerSymbol: "ETHUSD",
      spotSymbol: ".DEETHUSDT",
      sparklinesym: "ETHUSD",
    },
  ];

  return (
    <div className="container-fluid">
      <Navbar />

      <div className="main-content">
        {exchange !== "COMBINED"
          ? toBeShow_Crypto.map((item, key) => (
              <div className="card-wrapper" key={"HomePageCryptoCardKey" + key}>
                <HomePageCryptoCard
                  cryptoInfo={{}}
                  forTicker={item.tickerSymbol}
                  forSpot={item.spotSymbol}
                  sparklinesym={item.sparklinesym}
                />
              </div>
            ))
          : toBeShow_Crypto.map((item, key) => (
              <div className="card-wrapper" key={"HomePageCryptoCardKey" + key}>
                <HomePageCryptoCardCombined
                  cryptoInfo={{}}
                  forTicker={item.tickerSymbol}
                  forSpot={item.spotSymbol}
                  sparklinesym={item.sparklinesym}
                />
              </div>
            ))}
      </div>
    </div>
  );
};