import { createStore } from "redux";

// Initial state
const initialState = {
  journey: ["QF-HAWKEYE-JOURNEY-START"],
  visWatchlist: undefined,
};

// Action types
const APPEND_JOURNEY = "APPEND_JOURNEY";
const VIS_WL = "VIS_WL";

// Action creator
export const appendJourney = (newEntry) => {
  return {
    type: APPEND_JOURNEY,
    payload: newEntry,
  };
};
// Action creator
export const updateVisWL = (updatedData) => {
  return {
    type: VIS_WL,
    payload: updatedData,
  };
};

// Reducer
const handleStore = (state = initialState, action) => {
  switch (action.type) {
    case APPEND_JOURNEY:
      return {
        ...state,
        journey: [...state.journey, action.payload],
      };
    case VIS_WL:
      return {
        ...state,
        visWatchlist: action.payload,
      };
    default:
      return state;
  }
};

// Create store
const hawkQuiver = createStore(handleStore);
export default hawkQuiver;
