import React, { useEffect, useState } from "react";
import { commonApiDelete, commonApiGet } from "../../../utils/api";
import { notificationTopup } from "../../../utils/NotificationTopup";
import VisOops from "../VisOops";
import QFLoader from "../QFLoader";
import VisAddWatchlist from "./VisAddWatchlist";
import {
  chartColor,
  isMobile,
  maxViewContent,
  themedColor,
} from "../../../utils/configs";
import { Nav, NavItem } from "reactstrap";
import { Link, NavLink } from "react-router-dom";
import hawkQuiver, { updateVisWL } from "../../../utils/hawkeye/hawkQuiver";
import { useDispatch, useSelector } from "react-redux";

const VisWatchlist = () => {
  const [watchlistDetails, setWatchlistDetails] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [toShowAddWatchlist, setToShowAddWatchlist] = useState(false);
  const [apiCaller, setApiCaller] = useState(0);

  const [isListClicked, setIsListClicked] = useState(false);
  const [selectedTab, setSelectedTab] = useState(null);

  const visWLUp = useSelector((state) => state.visWLUp);

  const d = useDispatch();

  useEffect(() => {
    (async () => {
      await commonApiGet("/user/watchlist", "VISEXPANDED_WATCHLIST", false)
        .then((res) => {
          setWatchlistDetails(res.data);
          hawkQuiver.dispatch(updateVisWL(res.data));
          d({ type: "VIS_WL_UP" });
          setLoading(false);
          setError(false);
        })
        .catch((err) => {
          notificationTopup(err.message);
          setLoading(false);
          setError(true);
        });
    })();
  }, [toShowAddWatchlist, apiCaller]);

  useEffect(() => {
    setWatchlistDetails(hawkQuiver.getState().visWatchlist);
  }, [visWLUp]);
  return (
    <div
      className="w-100 py-2"
      style={{
        maxHeight: "400px",
        overflowY: "scroll",
      }}
    >
      {loading ? (
        <QFLoader />
      ) : error ? (
        <VisOops />
      ) : (
        <>
          <div className="py-3">
            {!isListClicked ? (
              <table className="w-100 table-tr-bb table-px akp-zoom">
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Description</th>
                    <th
                      style={{
                        textAlign: "center",
                      }}
                      colSpan={2}
                    >
                      Actions
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {watchlistDetails.map((item, index) => (
                    <tr
                      key={index}
                      className="tr-hover-focus-selection c-pointer"
                    >
                      <td
                        style={{
                          textTransform: "capitalize",
                        }}
                        onClick={() => {
                          setIsListClicked(true);
                          setSelectedTab(item.id);
                        }}
                      >
                        {item.name}
                      </td>
                      <td
                        onClick={() => {
                          setIsListClicked(true);
                          setSelectedTab(item.id);
                        }}
                      >
                        {item.desc} | #{item.items?.length || 0} stocks
                      </td>
                      <td
                        onClick={() => {
                          setIsListClicked(true);
                          setSelectedTab(item.id);
                        }}
                        style={{
                          textAlign: "right",
                          color: "var(--bs-info)",
                        }}
                        className="py-1"
                      >
                        <i className="fas fa-angle-right"></i>
                      </td>
                      <td
                        className="py-1"
                        style={{
                          textAlign: "left",
                          color: chartColor.red,
                        }}
                        onClick={() => {
                          if (
                            (item.items || []).length === 0 ||
                            confirm(
                              `You are forwarding for deleting ${
                                item.name
                              }, with having ${
                                item.items?.length || 0
                              } stocks. This action is irreversible. You want to proceed further?`
                            )
                          ) {
                            setLoading(true);
                            commonApiDelete(`/user/watchlist/${item.id}`)
                              .then((res) => {
                                notificationTopup(res.data.message, "green");
                                setApiCaller(apiCaller + 1);
                              })
                              .catch((err) => {
                                notificationTopup(err.message);
                              });
                          }
                        }}
                      >
                        <i className="fas fa-trash"></i>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            ) : (
              <div>
                <Nav
                  tabs
                  style={{
                    color: "var(--text-color) !important",
                    ...(isMobile()
                      ? {
                          display: "flex",
                          flexWrap: "nowrap",
                          textWrap: "nowrap",
                          overflowX: "scroll",
                        }
                      : {}),
                  }}
                  className="mb-3 section-bb"
                >
                  {watchlistDetails.map((item, index) => (
                    <NavItem
                      key={index}
                      onClick={() => {
                        setSelectedTab(item.id);
                      }}
                      style={{
                        backgroundColor:
                          selectedTab === item.id
                            ? "var(--text-color)"
                            : "transparent",
                        borderRadius: "5px 5px 0px 0px",
                      }}
                      className="px-2"
                    >
                      <NavLink
                        active={selectedTab === item.id ? true : false}
                        style={{
                          fontSize: "16px",
                          color:
                            selectedTab === item.id
                              ? "var(--body-bg)"
                              : "var(--text-color)",
                        }}
                      >
                        {maxViewContent(item.name, 10)}
                      </NavLink>
                    </NavItem>
                  ))}
                </Nav>

                <div
                  style={{
                    overflowY: "scroll",
                    maxHeight: "200px",
                  }}
                  className="px-2"
                >
                  <table className="w-100 table-tr-bb table-px akp-zoom">
                    <thead>
                      <tr>
                        <th>STK</th>
                        <th>Price</th>
                        <th>Surveillance</th>
                        {/* <th>Scanner</th> */}
                        <th>Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {(
                        watchlistDetails.filter(
                          (val) => selectedTab === val.id
                        )[0]?.items || []
                      ).map((itemstk, indexstk) => (
                        <tr key={indexstk}>
                          <td>
                            <Link
                              to={
                                itemstk.data
                                  ? `/info/${itemstk.data.exchange}/${itemstk.data.tradingSymbol}`
                                  : `/`
                              }
                              style={{
                                fontWeight: "900",
                                fontSize: "12px",
                              }}
                            >
                              {itemstk.symbol}
                            </Link>
                          </td>
                          <td>{itemstk.stock.lastPrice.toFixed(2)}</td>
                          <td>{itemstk.stock?.surveillance?.surv || "-"}</td>
                          {/* <td></td> */}
                          <td
                            style={{
                              textAlign: "center",
                              cursor: "pointer",
                              color: chartColor.red,
                            }}
                            onClick={() => {
                              if (
                                confirm(
                                  `You are removing stock ${itemstk.symbol} from selected whatchlist. You want to proceed further?`
                                )
                              ) {
                                commonApiDelete(
                                  `/user/watchlist/${selectedTab}/deletestock?symbol=${itemstk.symbol}`
                                )
                                  .then((res) => {
                                    notificationTopup(
                                      res.data.message,
                                      "green"
                                    );
                                    setApiCaller(apiCaller + 1);
                                  })
                                  .catch((err) => {
                                    notificationTopup(err.message);
                                  });
                              }
                            }}
                          >
                            <i className="fas fa-heart-circle-xmark" />
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            )}
          </div>
        </>
      )}
      <center>
        {toShowAddWatchlist ? (
          <VisAddWatchlist closer={setToShowAddWatchlist} />
        ) : (
          <>
            {isListClicked ? (
              <button
                type="button"
                className="btn-sm rounded-pill px-4 m-2 general-nmphc-des our-border btn-hover-effect"
                style={{
                  fontWeight: 400,
                  color: "var(--text-color)",
                  fontWeight: "bold",
                }}
                onClick={() => {
                  setIsListClicked(false);
                }}
              >
                Back
              </button>
            ) : (
              <></>
            )}
            <button
              type="button"
              className="btn-sm rounded-pill px-4 m-2 general-nmphc-des our-border btn-hover-effect "
              style={{
                fontWeight: 400,
                color: "var(--text-color)",
                fontWeight: "bold",
              }}
              onClick={() => {
                setToShowAddWatchlist(true);
              }}
            >
              + Add Watchlist
            </button>
          </>
        )}
      </center>
    </div>
  );
};

export default VisWatchlist;
