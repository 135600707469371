import React from "react";
import Navbar from "../../components/Navbar";
import HomeBanner from "./components/HomeBanner";
import Divider from "./components/Divider";
import VisionMissionValues from "./components/VisionMissionValues";
import Scanners from "../../components/NSEComponents/Scanners";
import SharkFolio from "../../components/NSEComponents/SharkFolio";
import EodBandChanges from "../../components/NSEComponents/EodBandChanges";
import PriceBandHitters from "../../components/NSEComponents/PriceBandHitters";
import Ctas from "./components/Ctas";
import Footer from "./components/Footer";
import LivePrice from "./components/LivePrice";

export const Home = () => {
  return (
    <div className="container-fluid">
      <Navbar />

      <div className="main-content">
        <div className="row">
          <div
            className="futures-card m-0 py-0 col-12"
            style={{
              overflowY: "scroll",
              maxHeight: "90vh",
              overflowX: "hidden",
            }}
          >
            <div className="row">
              <div className="col-12">
                <LivePrice />
              </div>
              <div className="col-12">
                <HomeBanner />
                <Divider />
              </div>
              <div className="col-12">
                <VisionMissionValues />
                <Divider />
              </div>
              <div className="col-12">
                <div className="row g-3">
                  <div className="col-xxxl-3 col-md-6">
                    <Scanners />
                  </div>
                  <div className="col-xxxl-3 col-md-6">
                    <EodBandChanges />
                  </div>
                  <div className="col-xxxl-3 col-md-6">
                    <PriceBandHitters />
                  </div>
                  <div className="col-xxxl-3 col-md-6">
                    <SharkFolio />
                  </div>
                  <center className="mt-5">
                    <div className="d-block d-md-none">
                      <Ctas lgSm="sm" />
                    </div>
                    <div className="d-none d-md-block">
                      <Ctas lgSm="lg" />
                    </div>
                  </center>
                </div>
                <Divider />
              </div>
              <div className="col-12">
                <Footer />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
