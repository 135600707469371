import React, { useEffect, useState } from "react";
import { commonApiGet } from "../../utils/api";
import { chartColor, getRandomStockMessage } from "../../utils/configs";
import { notificationTopup } from "../../utils/NotificationTopup";
import QFLoader from "../vision/QFLoader";
import CommonCardView from "../vision/CommonCardView";
import VisOops from "../vision/VisOops";
import {
  ButtonDropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap";
import { groupINRCurrencyNSE } from "../../utils/functions";

const PriceBandHitters = () => {
  const options = {
    UPPER: "UPPER",
    BOTH: "BOTH",
    LOWER: "LOWER",
  };
  const [dropdownOptionOpen, setDropdownOptionOpen] = useState(false);
  const ddootoggle = () => setDropdownOptionOpen((prevState) => !prevState);
  const [priceBandHittersData, setPriceBandHittersData] = useState([]);
  const [bandHitterType, setBandHitterType] = useState("BOTH");
  const [search, setSearch] = useState("");
  const [loaderShow, setLoaderShow] = useState(true);
  const [errorShow, setErrorShow] = useState(false);

  useEffect(() => {
    (async () => {
      await commonApiGet(`/stocks/price_band_hitters?type=${bandHitterType}`)
        .then((res) => {
          setPriceBandHittersData(res.data);
          setSearch("");
          setLoaderShow(false);
          setErrorShow(false);
        })
        .catch((err) => {
          notificationTopup(err.message);
          setErrorShow(true);
          setLoaderShow(false);
        });
    })();
  }, [bandHitterType]);

  return (
    <CommonCardView
      id="commonCard_bandHitter"
      header="Band Hitters"
      headerInfo={{
        show: true,
        desc: "Upper Circuit/Lower Circuit",
        id: "band-hitters",
      }}
      headerAlign="left"
      style={{
        position: "relative",
      }}
    >
      <ButtonDropdown
        isOpen={dropdownOptionOpen}
        toggle={ddootoggle}
        className="tab-weight absolute-tabGrp"
      >
        <DropdownToggle
          caret
          size="sm"
          color="transparent"
          className="general-nmphc-des text-color-themed mt-1"
          style={{
            fontSize: "12px",
          }}
        >
          {options[bandHitterType]}
        </DropdownToggle>
        <DropdownMenu
          className="general-nmphc-des non-trans-bg"
          style={{
            maxHeight: "220px",
            overflowY: "scroll",
          }}
        >
          {Object.keys(options).map((val, key) => (
            <DropdownItem
              className="bg-set-on-active text-color-themed"
              key={`QF_PBH_${key}`}
              onClick={() => {
                setLoaderShow(true);
                setPriceBandHittersData([]);
                setBandHitterType(val);
              }}
              style={{
                fontSize: "12px",
              }}
              disabled={val === bandHitterType ? true : false}
            >
              {options[val]}
            </DropdownItem>
          ))}
        </DropdownMenu>
      </ButtonDropdown>
      {errorShow ? (
        <VisOops />
      ) : loaderShow ? (
        <QFLoader />
      ) : (
        <>
          <div className=" make-me-sticky non-trans-bg py-2">
            <input
              type="text"
              className="qfInputType py-1 px-2"
              placeholder="Search"
              onChange={(e) => {
                e.preventDefault();
                setSearch(e.target.value);
              }}
              value={search}
            />
          </div>
          {priceBandHittersData
            .filter((val) => {
              let searchableStr = `${val.symbol} (${val.series})`;
              return searchableStr.toLowerCase().includes(search.toLowerCase());
            })
            .map((val, key) => {
              return (
                <div
                  key={`selectedBandHitterType_${bandHitterType}_${key}`}
                  className="m-0 mb-1 pb-1"
                  style={{
                    borderBottom: "1px solid",
                  }}
                >
                  <table
                    style={{
                      width: "100%",
                    }}
                    className="table-2nd-td-r"
                  >
                    <tbody>
                      <tr>
                        <td>
                          <b>{val.symbol}</b> ({val.series})<br />
                          Volume:{" "}
                          {groupINRCurrencyNSE(
                            parseFloat(val.totalTradedVol) * 100000
                          )}{" "}
                          <br />
                          Turnover: {parseFloat(val.turnover).toFixed(2)} Cr.
                        </td>
                        <td
                          style={{
                            color:
                              parseInt(val.pChange) > 0
                                ? chartColor.green
                                : chartColor.red,
                          }}
                        >
                          {val.ltp}
                          <br />
                          {val.change} ({val.pChange}%)
                        </td>
                      </tr>
                      <tr>
                        <td>
                          1D ({val.priceBand}%) <br /> {val.lowPrice} -{" "}
                          {val.highPrice}
                        </td>
                        <td>
                          52W
                          <br /> {val.yearLow} - {val.yearHigh}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              );
            })}
          {priceBandHittersData.filter((val) => {
            let searchableStr = `${val.symbol} (${val.series})`;
            return searchableStr.toLowerCase().includes(search.toLowerCase());
          }).length === 0
            ? getRandomStockMessage()
            : ""}
        </>
      )}
    </CommonCardView>
  );
};

export default PriceBandHitters;
