import React from "react";
import LivePrice from "../../views/preLogin/components/LivePrice";
import Navbar from "../Navbar";

const CommonCardViewer = ({ children }) => {
  return (
    <div className="container-fluid">
      <Navbar />
      <LivePrice />
      <div className="main-content">
        <center>
          <div className="row">
            <div
              className="futures-card m-0 col-12"
              style={{
                overflowY: "scroll",
                maxHeight: "85vh",
                overflowX: "hidden",
              }}
            >
              <div className="row g-3" style={{
                justifyContent: 'center'
              }}>
                {/* <div className="col-12">
          </div> */}
                {/* <div className="col-12 p-1"></div> */}
                <div className="col-md-4">{children}</div>
              </div>
            </div>
          </div>
        </center>
      </div>
    </div>
  );
};

export default CommonCardViewer;
