import React, { useEffect } from "react";
import { exchangeTo } from "../utils/glob-redux/store";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

const CryptoRedirector = () => {
  const dispatch = useDispatch();
  const n = useNavigate();
  useEffect(() => {
    dispatch(exchangeTo("INDIA"));
    n("/crypto");
  }, []);
  return (
    <div className="w-100 h-100 vert-center">
      <center>Exchanging to Crypto...</center>
    </div>
  );
};

export default CryptoRedirector;
