import React, { useEffect, useState } from "react";
import { commonApiGet } from "../../utils/api";
import {
  convertToTimestamp,
  dbyTodb,
  getRandomStockMessage,
  themedColor,
} from "../../utils/configs";
import { useSelector } from "react-redux";
import QFLoader from "../vision/QFLoader";
import { notificationTopup } from "../../utils/NotificationTopup";
import CommonCardView from "../vision/CommonCardView";
import VisOops from "../vision/VisOops";

const ShortSelling = () => {
  const [shortSellingData, setShortSellingData] = useState([]);

  const theme = useSelector((state) => state.theme);
  const [search, setSearch] = useState("");
  const [loaderShow, setLoaderShow] = useState(true);
  const [errorShow, setErrorShow] = useState(false);

  useEffect(() => {
    (async () => {
      await commonApiGet("/market/short_selling")
        .then((res) => {
          setShortSellingData(
            res.data.sort(
              (a, b) => convertToTimestamp(b.date) - convertToTimestamp(a.date)
            )
          );
          setLoaderShow(false);
          setErrorShow(false);
        })
        .catch((err) => {
          notificationTopup(err.message);
          setErrorShow(true);
          setLoaderShow(false);
        });
    })();
  }, []);
  return (
    <CommonCardView
      id="commonCard_shortSelling"
      header="Short Selling"
      headerInfo={{
        id: "short-selling",
        show: true,
        desc: "Stocks in which short selling is happening",
      }}
      toWatermark={false}
    >
      {errorShow ? (
        <VisOops />
      ) : loaderShow ? (
        <QFLoader />
      ) : (
        <>
          <div className=" make-me-sticky non-trans-bg py-2">
            <input
              type="text"
              className="qfInputType py-1 px-2"
              placeholder="Search"
              onChange={(e) => {
                e.preventDefault();
                setSearch(e.target.value);
              }}
              value={search}
            />
          </div>

          <table className="w-100">
            <thead className="make-me-sticky non-trans-bg " style={{ top: 40 }}>
              <tr>
                <th>Date</th>
                <th>Symbol</th>
                <th>Quantity</th>
              </tr>
            </thead>
            <tbody>
              {shortSellingData
                .filter((val) =>
                  val["Symbol Name"]
                    .toLowerCase()
                    .includes(search.toLowerCase())
                )
                .map((data, index) => {
                  return (
                    <tr
                      key={index}
                      style={{
                        borderTop: `1px solid ${
                          theme === "dark"
                            ? themedColor.dark
                            : themedColor.light
                        }`,
                      }}
                    >
                      <td className="py-1">{dbyTodb(data["date"])}</td>
                      <td className="py-1">{data["Symbol Name"]}</td>
                      <td className="py-1" style={{ textAlign: "right" }}>
                        {data["Quantity"]}
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
          {shortSellingData.filter((val) =>
            val["Symbol Name"].toLowerCase().includes(search.toLowerCase())
          ).length === 0
            ? getRandomStockMessage()
            : ""}
        </>
      )}
    </CommonCardView>
  );
};

export default ShortSelling;
