import React from "react";

const VIsAdvancerDeclinerUnchanged = ({ gainerLoser }) => {
  return (
    <div className="row p-2">
      <div className="col-12">
        <div
          style={{
            height: "20px",
            borderRadius: "100px",
            backgroundImage: `linear-gradient(to right, 
              ${
                parseInt(gainerLoser["advance"]?.advances)
                  ? `rgba(0, 128, 0, ${
                      (parseInt(gainerLoser["advance"]?.advances) /
                        (parseInt(gainerLoser["advance"]?.advances) +
                          parseInt(gainerLoser["advance"]?.unchanged) +
                          parseInt(gainerLoser["advance"]?.declines))) *
                      100
                    }%)`
                  : "transparent"
              },
              ${
                parseInt(gainerLoser["advance"]?.unchanged)
                  ? `rgba(255, 255, 255, ${
                      (parseInt(gainerLoser["advance"]?.unchanged) /
                        (parseInt(gainerLoser["advance"]?.advances) +
                          parseInt(gainerLoser["advance"]?.unchanged) +
                          parseInt(gainerLoser["advance"]?.declines))) *
                      100
                    }%)`
                  : "transparent"
              },
              ${
                parseInt(gainerLoser["advance"]?.declines)
                  ? `rgba(255, 0, 0, ${
                      (parseInt(gainerLoser["advance"]?.declines) /
                        (parseInt(gainerLoser["advance"]?.advances) +
                          parseInt(gainerLoser["advance"]?.unchanged) +
                          parseInt(gainerLoser["advance"]?.declines))) *
                      100
                    }%)`
                  : "transparent"
              })`,
          }}
          className=""
        ></div>{" "}
      </div>
      <div className="col-4">{parseInt(gainerLoser["advance"]?.advances) || 0}</div>
      <div className="col-4 text-center">
        {parseInt(gainerLoser["advance"]?.unchanged) || 0}
      </div>
      <div className="col-4 text-end">
        {parseInt(gainerLoser["advance"]?.declines) || 0}
      </div>
    </div>
  );
};

export default VIsAdvancerDeclinerUnchanged;
