import React, { useEffect, useState } from "react";

import { notificationTopup } from "../../utils/NotificationTopup";
import { getRandomStockMessage } from "../../utils/configs";
import QFLoader from "../vision/QFLoader";
import {
  ButtonDropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap";
import { commonApiGet } from "../../utils/api";
import CommonCardView from "../vision/CommonCardView";
import VisOops from "../vision/VisOops";
const Trending = () => {
  const tnds2toggle = () => setDropdowntnds2Open((prevState) => !prevState);
  const tndstoggle = () => setDropdowntndsOpen((prevState) => !prevState);

  const [search, setSearch] = useState("");
  const [dropdowntnds2Open, setDropdowntnds2Open] = useState(false);
  const [tnds, settnds] = useState([]);
  const [dropdowntndsOpen, setDropdowntndsOpen] = useState(false);

  const [loaderShow, setLoaderShow] = useState(true);
  const [errorShow, setErrorShow] = useState(false);
  const tndsOptions = [
    "Bullish",
    "Bearish",
    "Turning-bullish",
    "Turning-bearish",
  ];
  const [selectedtndsOption, setSelectedtndsOption] = useState(tndsOptions[0]);
  const tnds2Options = ["FNO", "LCAP", "MDCAP", "SMCAP"];
  const [selectedtnds2Option, setSelectedtnds2Option] = useState(
    tnds2Options[0]
  );

  useEffect(() => {
    (async () => {
      await commonApiGet(
        `/analysis/stocksbytrend?trend=${selectedtndsOption}&collection=${selectedtnds2Option}`
      )
        .then((res) => {
          settnds(Array.isArray(res.data) ? res.data : []);
          setLoaderShow(false);
          setErrorShow(false);
        })
        .catch((err) => {
          notificationTopup(err.message);
          setErrorShow(true);
          setLoaderShow(false);
        });
    })();
  }, [selectedtndsOption, selectedtnds2Option]);
  return (
    <CommonCardView
      id="commonCard_trending"
      style={{
        position: "relative",
      }}
      header="Trending"
      headerAlign="left"
      headerInfo={{
        show: true,
        desc: "Upper Circuit/Lower Circuit",
        id: "band-hitters",
      }}
    >
      <div className="tab-weight absolute-tabGrp">
        <ButtonDropdown isOpen={dropdowntndsOpen} toggle={tndstoggle}>
          <DropdownToggle
            caret
            size="sm"
            color="transparent"
            className="general-nmphc-des text-color-themed  mt-1  mx-2"
            style={{
              fontSize: "12px",
            }}
          >
            {selectedtndsOption.toUpperCase()}
          </DropdownToggle>
          <DropdownMenu
            className="general-nmphc-des non-trans-bg"
            style={{
              maxHeight: "220px",
              overflowY: "scroll",
            }}
          >
            {tndsOptions.map((val, key) => (
              <DropdownItem
                className="bg-set-on-active text-color-themed"
                key={`tnds_${key}`}
                onClick={() => {
                  setLoaderShow(true);
                  settnds([]);
                  setSelectedtndsOption(val);
                }}
                style={{
                  fontSize: "12px",
                }}
                disabled={val === selectedtndsOption ? true : false}
              >
                {val.toUpperCase()}
              </DropdownItem>
            ))}
          </DropdownMenu>
        </ButtonDropdown>
        <ButtonDropdown isOpen={dropdowntnds2Open} toggle={tnds2toggle}>
          <DropdownToggle
            caret
            size="sm"
            color="transparent"
            className="general-nmphc-des text-color-themed mt-1"
            style={{
              fontSize: "12px",
            }}
          >
            {selectedtnds2Option.toUpperCase()}
          </DropdownToggle>
          <DropdownMenu
            className="general-nmphc-des non-trans-bg"
            style={{
              maxHeight: "220px",
              overflowY: "scroll",
            }}
          >
            {tnds2Options.map((val, key) => (
              <DropdownItem
                className="bg-set-on-active text-color-themed"
                key={`TNDS2_${key}`}
                onClick={() => {
                  setLoaderShow(true);
                  settnds([]);
                  setSelectedtnds2Option(val);
                }}
                style={{
                  fontSize: "12px",
                }}
                disabled={val === selectedtnds2Option ? true : false}
              >
                {val.toUpperCase()}
              </DropdownItem>
            ))}
          </DropdownMenu>
        </ButtonDropdown>
      </div>
      {errorShow ? (
        <VisOops />
      ) : loaderShow ? (
        <QFLoader />
      ) : (
        <>
          <div className=" make-me-sticky non-trans-bg py-2">
            <input
              type="text"
              className="qfInputType py-1 px-2"
              placeholder="Search"
              onChange={(e) => {
                e.preventDefault();
                setSearch(e.target.value);
              }}
              value={search}
            />
          </div>

          {tnds
            .filter((val) =>
              val.data.StockName.toLowerCase().includes(search.toLowerCase())
            )
            .map((val, key) => {
              return (
                <div
                  key={`selectedtndsOpt_${selectedtndsOption}_selectedtnds2Opt_${selectedtnds2Option}_${key}`}
                  className="m-0 mb-1 pb-1"
                  style={{
                    borderBottom: "1px solid",
                  }}
                >
                  <table className="table-td-r w-100 mt-2">
                    <tbody>
                      <tr>
                        <th>
                          {val.data.StockName} (₹{val.data.currPrice})
                        </th>
                        <td>{val.data.updatedTime}</td>
                      </tr>
                      <tr>
                        <th>Performance:</th>
                        <td>{val.data.performance}%</td>
                      </tr>
                      <tr>
                        <th>Prev Trend:</th>
                        <td>{val.data.prevTrend}</td>
                      </tr>
                      <tr>
                        <th>Current Trend:</th>
                        <td>{val.data.currTrend}</td>
                      </tr>
                      <tr>
                        <th>Trend Change:</th>
                        <td>{val.data.trendChngDate}</td>
                      </tr>
                      <tr>
                        <th>Trend Change Price:</th>
                        <td>₹{val.data.trendChngPrice}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              );
            })}
          {tnds.filter((val) =>
            val.data.StockName.toLowerCase().includes(search.toLowerCase())
          ).length === 0 && getRandomStockMessage()}
        </>
      )}
    </CommonCardView>
  );
};

export default Trending;
