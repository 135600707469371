import { createStore } from "redux";

const initialState = {
  exchange: localStorage.getItem("exchange") || "NSE", // GLOBAL, INDIA, COMBINED, NSE
  visSearch: false, // GLOBAL, INDIA, COMBINED, NSE
  visExpandedToolBox: true,
  theme:
    localStorage.getItem("theme") ||
    (window.matchMedia("(prefers-color-scheme: dark)").matches
      ? "dark"
      : "light"), // light, dark
  addWlPopup: false,
  visWLUp: 0,
  visAddtowlStock: undefined,
};

const handleStore = (state = initialState, action) => {
  switch (action.type) {
    case "SET_EXCHANGE":
      let exchange = action.to;
      localStorage.setItem("exchange", exchange);
      return { ...state, exchange };
    case "SWITCH_THEME":
      let theme = state.theme === "light" ? "dark" : "light";
      localStorage.setItem("theme", theme);
      return { ...state, theme }; // toggle theme
    case "SHOW_VISSEARCH":
      let visSearch = !state.visSearch;
      return { ...state, visSearch }; // toggle theme
    case "HIDE_VISSEARCH":
      return { ...state, visSearch: false }; // toggle theme
    case "TOOLBOX_SEARCH":
      return { ...state, visExpandedToolBox: false }; // toggle theme
    case "TOOLBOX_TOOLBOX":
      return { ...state, visExpandedToolBox: true }; // toggle theme
    case "VISEQ_ADDWL_POPUP_T":
      return { ...state, addWlPopup: !state.addWlPopup }; // toggle theme
    case "VIS_WL_UP":
      return { ...state, visWLUp: state.visWLUp + 1 }; // toggle theme
    case "VISEQ_ADDWL_STK":
      return { ...state, visAddtowlStock: action.symbol };
    default:
      return state;
  }
};

const store = createStore(handleStore);
export default store;

export const exchangeTo = (exchangeName = "INDIA") => ({
  type: "SET_EXCHANGE",
  to: exchangeName,
});
