import React from "react";

const Divider = ({ role = "main", py = 5, text = "or" }) => {
  return (
    <div className={`qf-sec-divider qf-text-responsive py-${py}`}>
      <span className="qf-sec-divider-line"></span>
      <span className="qf-sec-divider-icon">
        {role === "main" ? (
          <i className="fas fa-money-bill-trend-up"></i>
        ) : role === "text" ? (
          <span>{text}</span>
        ) : (
          <img src="/transparent-logo.webp" alt="Divider Icon" height={15} />
        )}
      </span>
      <span className="qf-sec-divider-line"></span>
    </div>
  );
};

export default Divider;
