import React from "react";
import Ctas from "./Ctas";
import dark00 from "../../../static/images/banner-flow/dark-00.gif";
import light00 from "../../../static/images/banner-flow/light-00.gif";
import { useSelector } from "react-redux";

const HomeBanner = () => {
  const theme = useSelector((state) => state.theme);
  return (
    <div className="w-100">
      <main
        style={{
          height: "100%",
        }}
      >
        <div className="row align-items-center m-0">
          <div className="col-lg-6 pe-lg-5 pt-4 pt-md-0">
            <h1 className="display-4 fw-bold mb-4">
              <span className="text-info">Illuminate </span>Your
              <br />
              Investment Journey
            </h1>
            <p className="lead">
              <i style={{ fontWeight: 900 }}>Unlock Smarter Investing</i> with{" "}
              <i style={{ fontWeight: 900 }} className="text-info">
                {" "}
                Quibblefrost
              </i>
              , India’s most comprehensive platform for real-time stock market
              data and intelligence.
            </p>

            <div className="position-relative d-block d-md-none">
              <center>
                <img
                  src={theme === "dark" ? dark00 : light00}
                  style={{ width: "110%", marginLeft: "-5%" }}
                  alt="Quibblefrost | Option chain support/resistance by volume"
                  title="Quibblefrost | Option chain support/resistance by volume"
                />
              </center>
            </div>
            <div className="d-block d-md-none">
              <center>
                <Ctas lgSm="sm" />
              </center>
              <br />
              <br />
            </div>
            <div className="row">
              <div className="col-md-6 mb-3 d-flex align-items-center">
                <i className="fas fa-chart-line fa-3x text-info me-3"></i>
                <div>
                  <h5 className="mb-1">Real-Time Data</h5>
                  <p className="mb-0">
                    Access up-to-the-minute stock market data.
                  </p>
                </div>
              </div>
              <div className="col-md-6 mb-3 d-flex align-items-center">
                <i className="fas fa-brain fa-3x text-info me-3"></i>
                <div>
                  <h5 className="mb-1">Curated Insights</h5>
                  <p className="mb-0">
                    Make informed decisions with expert-curated market insights.
                  </p>
                </div>
              </div>
              <div className="col-md-6 mb-3 d-flex align-items-center">
                <i className="fas fa-tools fa-3x text-info me-3"></i>
                <div>
                  <h5 className="mb-1">User-Friendly Tools</h5>
                  <p className="mb-0">
                    Leverage powerful tools designed for investors.
                  </p>
                </div>
              </div>
              <div className="col-md-6 mb-3 d-flex align-items-center">
                <i className="fas fa-lock fa-3x text-info me-3"></i>
                <div>
                  <h5 className="mb-1">Secure Platform</h5>
                  <p className="mb-0">
                    Enjoy a safe and reliable data experience.
                  </p>
                </div>
              </div>
            </div>
            <div className="d-md-block d-none mt-5">
              <Ctas />
            </div>
          </div>
          <div className="col-lg-6 mt-5 mt-lg-0 d-md-block d-none">
            <div className="position-relative pt-5">
              <center>
                <img
                  src={theme === "dark" ? dark00 : light00}
                  style={{ width: "85%" }}
                  alt="Quibblefrost | Option chain support/resistance by volume"
                  title="Quibblefrost | Option chain support/resistance by volume"
                />
              </center>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};

export default HomeBanner;
