import React, { useEffect } from 'react';
import * as echarts from 'echarts';
import { useSelector } from 'react-redux';
import { chartColor } from '../../../../utils/configs';

const VisOHLCEChart = ({ ohlc = [[0, 0, 0, 0]] }) => {
  const theme = useSelector((state) => state.theme);

  useEffect(() => {
    const chartDom = document.getElementById('ohlcuclc_visualizer_apache_echarts');
    const myChart = echarts.init(chartDom);

    const options = {
      xAxis: {
        type: 'category',
        data: ohlc.map((_, index) => index), // You can replace this with actual dates or categories
        axisLine: { show: false },
        axisTick: { show: false }
      },
      yAxis: {
        scale: true,
        axisLine: { show: false },
        axisTick: { show: false },
        show: false
      },
      grid: {
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
      },
      series: [
        {
          type: 'candlestick',
          data: ohlc, // Format is [open, close, lowest, highest]
          itemStyle: {
            color0: chartColor.red,
            color: chartColor.green,
            borderColor0: chartColor.red,
            borderColor: chartColor.green,
          },
        }
      ],
    };

    myChart.setOption(options);

    return () => {
      myChart.dispose();
    };
  }, [ohlc, theme]);

  return <div id="ohlcuclc_visualizer_apache_echarts" style={{ width: '25px', height:'100%' }}></div>;
};

export default VisOHLCEChart;