import React from "react";
import universalWMDark from "./watermark-dark.png";
import universalWMLight from "./watermark-light.png";
import { useSelector } from "react-redux";

const Watermark = () => {
  const theme = useSelector((state) => state.theme);
  return (
    <div
      style={{
        position: "absolute",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundImage: `url(${
          theme === "dark" ? universalWMDark : universalWMLight
        })`,
        opacity: 0.1,
        backgroundRepeat: "repeat-y", // Repeat only vertically
        backgroundPosition: "center", // Center the watermark horizontally
        zIndex: "9999", // Ensure it's on top
        pointerEvents: "none", // Prevent interaction
        backgroundSize: 'contain'
      }}
    />
  );
};

export default Watermark;
