import React, { useEffect, useState } from "react";
import ModalQF from "../ModalQF";
import { useDispatch } from "react-redux";
import {
  commonApiDelete,
  commonApiGet,
  commonApiPost,
} from "../../../utils/api";
import { notificationTopup } from "../../../utils/NotificationTopup";
import hawkQuiver, { updateVisWL } from "../../../utils/hawkeye/hawkQuiver";
import VisAddWatchlist from "./VisAddWatchlist";
import { useSelector } from "react-redux";
import { chartColor } from "../../../utils/configs";
import QFLoader from "../QFLoader";

const VisAddToWatchlist = ({ symbol, wl, update, updater }) => {
  const d = useDispatch();
  const [toShowAddWatchlist, setToShowAddWatchlist] = useState(false);
  const [showLoader, setShowLoader] = useState(false);

  const handleUpdate = async () => {
    await commonApiGet("/user/watchlist", "EQ_PAGE_AF_WATCHLIST_ADD", false)
      .then((res) => {
        hawkQuiver.dispatch(updateVisWL(res.data));
        updater(update);
        d({ type: "VIS_WL_UP" });
      })
      .catch((err) => {
        notificationTopup(err.message);
      });
  };

  const handleToogle = async (isIn, item) => {
    if (isIn) {
      await commonApiDelete(`/user/watchlist/${item.id}/deletestock?symbol=${symbol}`)
        .then((res) => {
          notificationTopup(res.data.message, "green");
          handleUpdate();
        })
        .catch((err) => {
          notificationTopup(err.message);
        });
    } else {
      await commonApiPost(`/user/watchlist/${item.id}/addstock?symbol=${symbol}`)
        .then((res) => {
          notificationTopup(res.data.message, "green");
          handleUpdate();
        })
        .catch((err) => {
          notificationTopup(err.message);
        });
    }
  };

  useEffect(() => {
    handleUpdate();
  }, [toShowAddWatchlist]);
  return (
    <ModalQF
      dataClearFunc={() => {}}
      dataClearTo={-1}
      header={
        <div
          style={{
            padding: "0 20px",
          }}
        >
          Add to watchlist: {symbol}
        </div>
      }
      closeVar={d}
      closeVal={{ type: "VISEQ_ADDWL_POPUP_T" }}
      data={
        <div
          style={{
            position: "relative",
          }}
        >
          <div className="left-border-div-sec px-2">
            {wl.map((item, index) => {
              const isIn =
                item.items.filter((val) => val.symbol === symbol).length > 0
                  ? true
                  : false;
              return (
                <div
                  key={index}
                  onClick={async () => {
                    setShowLoader(true);
                    await handleToogle(isIn, item);
                    setShowLoader(false);
                  }}
                  style={{
                    fontSize: "18px",
                  }}
                >
                  <input type="checkbox" checked={isIn} readOnly={true} />{" "}
                  {item.name}
                </div>
              );
            })}
            {showLoader ? <div className="mt-2"><QFLoader /></div> : <></>}
          </div>
          <div className="pt-3 pb-0 px-0 mx-0">
            {toShowAddWatchlist ? (
              <VisAddWatchlist closer={setToShowAddWatchlist} />
            ) : (
              <center>
                <button
                  type="button"
                  className="btn-sm rounded-pill px-4 m-2 general-nmphc-des our-border btn-hover-effect"
                  style={{
                    fontWeight: 400,
                    color: "var(--text-color)",
                    fontWeight: "bold",
                  }}
                  onClick={() => {
                    setToShowAddWatchlist(true);
                  }}
                >
                  + Add Watchlist
                </button>
              </center>
            )}
          </div>
        </div>
      }
      isExpandBtn={false}
      responsive={true}
      responsiveH={true}
    />
  );
};

export default VisAddToWatchlist;

export const AddtowlHelper = () => {
  const [update, updater] = useState(0);

  const visWLUp = useSelector((state) => state.visWLUp);
  const addWlPopup = useSelector((state) => state.addWlPopup);
  const visAddtowlStock = useSelector((state) => state.visAddtowlStock);
  const [visWL, setVisWL] = useState(hawkQuiver.getState().visWatchlist);
  useEffect(() => {
    setVisWL(hawkQuiver.getState().visWatchlist);
  }, [visWLUp, update]);

  return (
    <>
      {addWlPopup && (
        <VisAddToWatchlist
          symbol={visAddtowlStock}
          wl={visWL}
          update={update + 1}
          updater={updater}
        />
      )}
    </>
  );
};

export const IconForAddtowl = ({ symbol, vers = "auto" }) => {
  const d = useDispatch();
  const fetchWL = async () => {
    await commonApiGet("/user/watchlist", "VIS_FETCH_WL", false)
      .then((res) => {
        hawkQuiver.dispatch(updateVisWL(res.data));
        d({ type: "VIS_WL_UP" });
      })
      .catch((err) => {
        notificationTopup(err.message);
      });
  };
  const visWLUp = useSelector((state) => state.visWLUp);
  const [visWL, setVisWL] = useState(hawkQuiver.getState().visWatchlist);
  useEffect(() => {
    setVisWL(hawkQuiver.getState().visWatchlist);
  }, [visWLUp]);
  useEffect(() => {
    if (visWL === undefined) fetchWL();
  }, []);
  let addedStockList = [];
  (visWL || []).map((wl, key1) => {
    return (wl.items || []).map((item, key2) => {
      addedStockList.push(item.symbol);
    });
  });
  return (
    <i
      className={
        addedStockList.includes(symbol)
          ? "fas fa-heart-circle-check"
          : "fas fa-heart-circle-plus"
      }
      style={{
        ...(vers === "auto"
          ? {
              position: "absolute",
              top: "25px",
              right: "25px",
              fontSize: "24px",
            }
          : {}),
        cursor: "pointer",
        ...(addedStockList.includes(symbol) ? { color: chartColor.green } : {}),
      }}
      onClick={() => {
        d({ type: "VISEQ_ADDWL_POPUP_T" });
        d({ type: "VISEQ_ADDWL_STK", symbol });
      }}
    />
  );
};
