import React, { useEffect, useState, useRef } from "react";
import { webSockQF } from "../../../utils/configs";
import { notificationTopup } from "../../../utils/NotificationTopup";

const LivePrice = () => {
  const [prices, setPrices] = useState([]);
  const [websoc, setWebsoc] = useState(false);
  const [isPaused, setIsPaused] = useState(false);
  const tickerRef = useRef(null);
  const contentRef = useRef(null);
  const [tickerItems, setTickerItems] = useState([]);
  const [position, setPosition] = useState(0);

  useEffect(() => {
    if (!websoc) {
      const socket = new WebSocket(webSockQF);

      socket.onopen = () => {
        console.log("WebSocket connected");
        socket.send(
          JSON.stringify({
            channel: "ltp/home",
            expiry: "",
          })
        );
      };

      socket.onmessage = (event) => {
        const data = JSON.parse(event.data);
        if (data.message !== undefined) {
          notificationTopup(`LIVE PRICES: ${data.message}`);
        } else {
          console.log("New msg Received", data);
          setPrices(data);
        }
      };

      socket.onclose = () => {
        console.log("WebSocket disconnected");
      };

      setWebsoc(true);

      return () => {
        socket.close();
        console.log("WebSocket closed");
        setWebsoc(false);
      };
    }
  }, []);
  useEffect(() => {
    const interval = setInterval(() => {
      setTickerItems(prices);
    }, 5000); // Update every 5 seconds

    return () => clearInterval(interval);
  }, [prices]);

  useEffect(() => {
    const ticker = tickerRef.current;
    const content = contentRef.current;

    if (ticker && content && tickerItems.length > 0) {
      const tickerWidth = ticker.offsetWidth;
      const contentWidth = content.offsetWidth;

      let animationFrameId;

      const animate = () => {
        if (!isPaused) {
          setPosition((prevPosition) => {
            let newPosition = prevPosition - 1;
            if (newPosition <= -contentWidth / 2) {
              newPosition = 0;
            }
            return newPosition;
          });
        }
        animationFrameId = requestAnimationFrame(animate);
      };

      animationFrameId = requestAnimationFrame(animate);

      return () => {
        cancelAnimationFrame(animationFrameId);
      };
    }
  }, [tickerItems, isPaused]);

  const handleTouchStart = () => {
    setIsPaused(true);
  };

  const handleTouchEnd = () => {
    setIsPaused(false);
  };

  const renderTickerItems = () => {
    return tickerItems.map((value, index) => (
      <span
        key={index}
        className={`ticker-item px-2 mx-1 my-3 general-nmphc-desV2`}
        style={{
          display: "inline-block",
          fontSize: "14px",
        }}
      >
        <strong>
          <i
            className={
              value.percentChange < 0
                ? "fas fa-arrow-down text-danger"
                : value.percentChange > 0
                ? "fas fa-arrow-up text-success"
                : ""
            }
          ></i>{" "}
          {value.symbol}
        </strong>{" "}
        | {`${value.ltp} (${value.percentChange}%)`}
      </span>
    ));
  };

  return (
    <div
      ref={tickerRef}
      className="ticker-container"
      style={{ overflow: "hidden", width: "100%" }}
      onTouchStart={handleTouchStart}
      onTouchEnd={handleTouchEnd}
    >
      <div
        ref={contentRef}
        className="ticker-content"
        style={{
          display: "inline-block",
          whiteSpace: "nowrap",
          transform: `translateX(${position}px)`,
        }}
      >
        {renderTickerItems()}
        {renderTickerItems()}
      </div>
    </div>
  );
};

export default LivePrice;
