import DateTimeRangePicker from "@wojtekmaj/react-datetimerange-picker";
import '@wojtekmaj/react-datetimerange-picker/dist/DateTimeRangePicker.css';
import 'react-calendar/dist/Calendar.css';
import 'react-clock/dist/Clock.css';

const DateTimePicker = ({onChange, value}) => {

  return (
    <div className="h-min-full vert-center">
      <DateTimeRangePicker onChange={onChange} value={value} format="yyyy-MM-dd" className="general-nmphc-des p-1" calendarIcon={null} clearIcon={null} />
    </div>
  );
};

export default DateTimePicker;
