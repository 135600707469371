const VisionMissionValues = () => {
  const data = {
    vision: {
      title: "Our Vision",
      description: (
        <>
          Empower investors with real-time insights, enabling informed decisions
          and fostering financial growth through transparent, actionable market data.
        </>
      ),
      icon: "fas fa-compass",
    },
    mission: {
      title: "Our Mission",
      description: (
        <>
          Provide accurate stock market data, simplifying vast information into
          a user-friendly platform for confident, data-driven decision-making.
        </>
      ),
      icon: "fas fa-bullseye",
    },
    values: {
      title: "Our Values",
      description: (
        <>
          Uphold accuracy, integrity, and innovation, ensuring investors have
          access to high-quality, transparent market intelligence.
        </>
      ),
      icon: "fas fa-shield-alt",
    },
  };

  return (
    <div className="row">
      {Object.keys(data).map((key, index) => (
        <div className="col-md-4 p-3 h-min-full" key={index}>
          <div className="general-nmphc-des p-4 h-min-full">
            <h2
              style={{
                textDecoration: "underline",
                textUnderlineOffset: "10px",
              }}
            >
              <i
                className={data[key].icon}
                style={{
                  fontSize: "24px",
                  marginRight: "10px",
                }}
              ></i>
              {data[key].title}
            </h2>
            <br />
            <p>{data[key].description}</p>
          </div>
        </div>
      ))}
    </div>
  );
};

export default VisionMissionValues;