import React, { useEffect, useState } from "react";
import {
  candleCountAccordingTimeframes,
  chartColor,
  historicOHLCCandlesApi,
  socketFieldForMultiExchange,
} from "../utils/configs";
// import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { useSelector } from "react-redux";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import Highcharts from "highcharts/highstock";

const SparklineChart = ({ sparklinesym }) => {
  const theme = useSelector((state) => state.theme);
  const [chartData, setChartData] = useState([]);
  const [chartHistoricData, setChartHistoricData] = useState([]);
  const [options, setOptions] = useState({});

  const [isWindowChartCandle, setIsWindowChartCandle] = useState(true);

  const [resolution, setResolution] = useState("1h");
  const [ManageLiveCandle, setManageLiveCandle] = useState({});

  useEffect(() => {
    // Establish WebSocket connection
    const socket = new WebSocket(socketFieldForMultiExchange["GLOBAL"]);

    // WebSocket event listeners
    socket.onopen = () => {
      console.log("WebSocket connected");
      const subscriptionMessage = JSON.stringify({
        type: "subscribe",
        payload: {
          channels: [
            {
              name: "candlestick_" + resolution,
              symbols: [sparklinesym],
            },
          ],
        },
      });

      socket.send(subscriptionMessage);
      console.log("Subscribed to v2 ticker");
    };

    socket.onmessage = (event) => {
      const data = JSON.parse(event.data);
      if (data.type !== "subscriptions") {
        setManageLiveCandle(data);
      }
      // Handle incoming messages from the WebSocket server
    };

    socket.onclose = () => {
      console.log("WebSocket disconnected candlestick");
    };

    // Clean-up function
    return () => {
      // Close WebSocket connection when component unmounts
      socket.close();
    };
  }, [resolution]);
  // 1718105400        - seconds
  // 1718105400000     - milliseconds
  // 1718105400000000  - microseconds

  useEffect(() => {
    if (
      Object.keys(ManageLiveCandle).length === 0 &&
      ManageLiveCandle.constructor === Object
    ) {
      return;
    } else {
    }

    setChartHistoricData((prevData) => {
      const currentDataTime = ManageLiveCandle.candle_start_time / 1000 / 1000;
      let isToUpdateInSame = false;
      let onKey = -1;

      const newData = prevData.map((val, key) => {
        if (val.time === currentDataTime) {
          isToUpdateInSame = true;
          onKey = key;
          return {
            ...val,
            close: ManageLiveCandle.close || val.close,
            high: ManageLiveCandle.high || val.high,
            low: ManageLiveCandle.low || val.low,
            open: ManageLiveCandle.open || val.open,
            volume: ManageLiveCandle.volume || val.volume,
          };
        }
        return val;
      });

      if (!isToUpdateInSame) {
        newData.push({
          close: ManageLiveCandle.close || 0,
          high: ManageLiveCandle.high || 0,
          low: ManageLiveCandle.low || 0,
          open: ManageLiveCandle.open || 0,
          time: currentDataTime || 0,
          volume: ManageLiveCandle.volume || 0,
        });
        newData.shift()
      }

      return newData;
    });
  }, [ManageLiveCandle]);

  useEffect(() => {
    (async () => {
      const myHeaders = new Headers();
      myHeaders.append("Accept", "application/json");

      const requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow",
      };

      fetch(
        `${historicOHLCCandlesApi}?resolution=${resolution}&symbol=${sparklinesym}&start=${candleCountAccordingTimeframes[resolution].miniChart.startTime}&end=${candleCountAccordingTimeframes[resolution].miniChart.endTime}`,
        requestOptions
      )
        .then((response) => response.text())
        .then((result) => {
          let data;
          data = JSON.parse(result);
          if (data.success) {
            data = data.result;
            setChartHistoricData(data);
          }
        })
        .catch((error) => console.error(error));
    })();
  }, [resolution]);

  useEffect(() => {
    if (!isWindowChartCandle) {
      setChartData(
        chartHistoricData
          .sort((a, b) => a.time - b.time)
          .map((val) => [val.time, val.close])
      );
    } else {
      setChartData(
        chartHistoricData
          .sort((a, b) => a.time - b.time)
          .map((val) => [val.time, val.open, val.high, val.low, val.close])
      );
    }
  }, [chartHistoricData, isWindowChartCandle]);

  useEffect(() => {
    if (chartData.length < 1) {
      return;
    }
    let isPriceIncreased24Hrs =
      chartData[0][1] < chartData[chartData.length - 1][1];
    let color = isPriceIncreased24Hrs ? chartColor.green : chartColor.red;
    let opacColor = isPriceIncreased24Hrs
      ? chartColor.opacGreen
      : chartColor.opacRed;
    if (isWindowChartCandle) {
      setOptions({
        chart: {
          height: 125,
          zoomType: "x", // Adjusted zoom type
          backgroundColor: null,
          margin: [0, 0, 0, 0],
        },
        title: {
          text: null,
        },
        subtitle: {
          text: null,
        },
        xAxis: {
          type: "datetime",
          labels: {
            format: "{value:%Y-%m-%d %H:%M:%S}",
            enabled: false,
          },
          title: {
            text: null,
          },
          lineColor: "transparent",
          tickLength: 0,
        },
        yAxis: {
          title: {
            text: null,
          },
          labels: {
            enabled: false,
          },
          gridLineColor: "transparent",
        },
        legend: {
          enabled: false,
        },
        plotOptions: {
          candlestick: {
            // Adjusted to candlestick type
            // color: color,
            // upColor: chartColor.green,
            // lineColor: color,
            // upLineColor: chartColor.green,
            // lineWidth: 1,
            // upLineWidth: 1,
          },
        },
        series: [
          {
            type: "candlestick", // Changed to candlestick type
            name: sparklinesym,
            data: chartData.map((val) => {
              const unixDate = val[0] * 1000;
              const d = new Date(unixDate);
              return [d.toLocaleString(), val[1], val[2], val[3], val[4]]; // OHLC data format
            }),
            lineColor: theme == "dark" ? "white" : "black",
            upColor: chartColor.green,
            color: chartColor.red,
          },
        ],
      });
    } else {
      setOptions({
        chart: {
          height: 125,
          zooming: {
            type: "x",
          },
          backgroundColor: null,
          margin: [0, 0, 0, 0],
        },
        title: {
          text: null, // Hide title
        },
        subtitle: {
          text: null, // Hide subtitle
        },
        xAxis: {
          type: "datetime",
          labels: {
            format: "{value:%Y-%m-%d %H:%M:%S}",
            enabled: false, // Hide x-axis labels
          },
          title: {
            text: null, // Hide x-axis title
          },
          lineColor: "transparent", // Hide x-axis line
          tickLength: 0, // Remove x-axis ticks
        },
        yAxis: {
          title: {
            text: null, // Hide y-axis title
          },
          labels: {
            enabled: false, // Hide y-axis labels
          },
          gridLineColor: "transparent", // Hide y-axis grid lines
        },
        legend: {
          enabled: false, // Hide legend
        },
        plotOptions: {
          area: {
            color: opacColor,
            marker: {
              radius: 0,
            },
            states: {
              hover: {
                lineWidth: 0,
              },
            },
            threshold: null,
            lineColor: color,
            lineWidth: 3,
          },
        },
        series: [
          {
            type: "area",
            name: sparklinesym,
            data: chartData.map((val) => {
              const unixDate = val[0] * 1000;
              const d = new Date(unixDate);
              return [d.toLocaleString(), val[1]];
            }),
            lineColor: color,
            color: opacColor,
          },
        ],
      });
    }
  }, [chartData, theme]);

  // Your Highcharts code here

  return (
    <div className="general-nmphc-in-chart-i-des">
      <SkeletonTheme borderRadius={30} width={50}>
        {options.hasOwnProperty("chart") ? (
          <center>
            {candleCountAccordingTimeframes.resolutionsAvailable.miniChart.map(
              (val, key) => (
                <span
                  onClick={() => {
                    setResolution(val);
                  }}
                  className={
                    "div-pill rounded m-1 " +
                    (resolution == val ? "our-border" : "")
                  }
                  key={`${sparklinesym}_resolutionAvailable_${key}`}
                >
                  {val}
                </span>
              )
            )}
            <i
              className={
                "div-pill m-1 general-nmphc-des our-border " +
                (!isWindowChartCandle
                  ? "fas fa-chart-column"
                  : "fas fa-chart-line")
              }
              onClick={() => setIsWindowChartCandle(!isWindowChartCandle)}
            ></i>
            <HighchartsReact highcharts={Highcharts} options={options} />
          </center>
        ) : (
          <center>
            <Skeleton />
          </center>
        )}
      </SkeletonTheme>
    </div>
  );
};

export default SparklineChart;
