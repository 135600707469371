import React, { useEffect, useState } from "react";
import { isMobile } from "../../utils/configs";

const BottomIcons = () => {
  const [visible, setVisible] = useState(true);
  const [hovered, setHovered] = useState({ reload: false, scroll: false });

  let timeoutId;

  // Function to scroll to the top of the page
  const scrollToTop = () => {
    try {
      // Scroll the window to the top
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });

      // Check if the elements exist before scrolling
      const mainElement = document.getElementById("main");
      if (mainElement) {
        mainElement.scrollTo({ top: 0, left: 0, behavior: "smooth" });
      }

      const appElement = document.getElementById("app");
      if (appElement) {
        appElement.scrollTo({ top: 0, left: 0, behavior: "smooth" });
      }

      const futuresCardElement =
        document.getElementsByClassName("futures-card")[0];
      if (futuresCardElement) {
        futuresCardElement.scrollTo({ top: 0, left: 0, behavior: "smooth" });
      }
    } catch (e) {
      console.error("Error scrolling to top:", e);
    }
  };

  // Function to reload the page
  const reloadPage = () => {
    window.location.reload();
  };

  // Function to reset the visibility timer and make buttons visible
  const resetTimer = () => {
    // Immediately show the buttons on any interaction
    setVisible(true);
    if (timeoutId) {
      clearTimeout(timeoutId);
    }
    // Set a new timeout to hide the buttons after 10 seconds of inactivity
    timeoutId = setTimeout(() => {
      setVisible(false);
    }, 10000); // 10 seconds
  };

  useEffect(() => {
    // Set up event listeners for user interactions
    const handleInteraction = () => {
      resetTimer();
    };

    // Add event listeners to relevant elements
    window.addEventListener("scroll", handleInteraction);
    window.addEventListener("click", handleInteraction);
    window.addEventListener("mousemove", handleInteraction); // Optional: to detect mouse movement

    // Check for interactions on specific elements
    const mainElement = document.getElementById("main");
    const appElement = document.getElementById("app");
    const futuresCardElement =
      document.getElementsByClassName("futures-card")[0];

    if (mainElement) {
      mainElement.addEventListener("scroll", handleInteraction);
      //   mainElement.addEventListener("click", handleInteraction);
      //   mainElement.addEventListener("mousemove", handleInteraction);
    }

    if (appElement) {
      appElement.addEventListener("scroll", handleInteraction);
      //   appElement.addEventListener("click", handleInteraction);
      //   appElement.addEventListener("mousemove", handleInteraction);
    }

    if (futuresCardElement) {
      futuresCardElement.addEventListener("scroll", handleInteraction);
      //   futuresCardElement.addEventListener("click", handleInteraction);
      //   futuresCardElement.addEventListener("mousemove", handleInteraction);
    }

    // Start the timer when the component mounts
    resetTimer();

    // Clean up event listeners on component unmount
    return () => {
      window.removeEventListener("scroll", handleInteraction);
      //   window.removeEventListener("click", handleInteraction);
      //   window.removeEventListener("mousemove", handleInteraction);

      if (mainElement) {
        mainElement.removeEventListener("scroll", handleInteraction);
        // mainElement.removeEventListener("click", handleInteraction);
        // mainElement.removeEventListener("mousemove", handleInteraction);
      }

      if (appElement) {
        appElement.removeEventListener("scroll", handleInteraction);
        // appElement.removeEventListener("click", handleInteraction);
        // appElement.removeEventListener("mousemove", handleInteraction);
      }

      if (futuresCardElement) {
        futuresCardElement.removeEventListener("scroll", handleInteraction);
        // futuresCardElement.removeEventListener("click", handleInteraction);
        // futuresCardElement.removeEventListener("mousemove", handleInteraction);
      }

      clearTimeout(timeoutId);
    };
  }, []);

  return (
    <div style={{ ...styles.container, display: visible ? "flex" : "none" }}>
      <div
        style={{
          ...styles.icon,
          ...(hovered.reload ? styles.iconHover : {}),
        }}
        className="vert-center"
        onClick={reloadPage}
        onMouseEnter={() => setHovered((prev) => ({ ...prev, reload: true }))}
        onMouseLeave={() => setHovered((prev) => ({ ...prev, reload: false }))}
      >
        <i className="fas fa-refresh" title="Reload Page" />
      </div>
      <div
        style={{
          ...styles.icon,
          ...(hovered.scroll ? styles.iconHover : {}),
        }}
        className="vert-center"
        onClick={scrollToTop}
        onMouseEnter={() => setHovered((prev) => ({ ...prev, scroll: true }))}
        onMouseLeave={() => setHovered((prev) => ({ ...prev, scroll: false }))}
      >
        <i className="fas fa-caret-up p-1" title="Scroll to Top" />
      </div>
    </div>
  );
};
const styles = {
  container: {
    position: "fixed",
    ...(isMobile()
      ? {
          bottom: "33px",
          left: "50%",
          transform: "translateX(-50%)",
        }
      : {
          bottom: "10px",
          right: "10px",
        }),
    display: "flex",
    gap: "10px",
    zIndex: 1000,
  },
  icon: {
    cursor: "pointer",
    fontSize: "15px",
    color: "var(--bs-dark)",
    transition: "color 0.3s, transform 0.3s", // Add transition for transform
    borderRadius: "20px",
    backgroundColor: "var(--bs-info)",
    width: "25px",
    height: "25px",
    textAlign: "center",
    display: "flex", // Center icon
    alignItems: "center", // Center icon vertically
    justifyContent: "center", // Center icon horizontally
  },
  iconHover: {
    transform: "scale(1.2)", // Scale up on hover
    color: "var(--bs-light)", // Change color on hover (optional)
  },
};
export default BottomIcons;
