import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import ModalQF from "../../../components/vision/ModalQF";
import Contact from "./Contact";
import RAB from "./RAB";
import Tnc, { tncList } from "./Tnc";
import { privacyPolicy } from "./PrivacyPolicy";

const Footer = () => {
  const links = {
    linkedin: {
      url: "https://www.linkedin.com/",
      icon: "fab fa-linkedin",
    },
    instagram: {
      url: "https://www.instagram.com/quibblefrost",
      icon: "fab fa-instagram",
    },
    twitter: {
      url: "https://www.twitter.com/",
      icon: "fab fa-x-twitter",
    },
    email: {
      url: "mailto:ping@quibblefrost.in",
      icon: "fas fa-at",
    },
  };
  const tnc = (
    <div>
      <div
        style={{
          maxHeight: "60vh",
          overflowY: "scroll",
          padding: "10px",
        }}
      >
        {tncList}
        <hr />
        {privacyPolicy}
      </div>
      <hr className="m-2" />
      <center
        style={{
          marginTop: "10px",
          width: "100%",
        }}
      >
        <label
          className="btn btn-info"
          onClick={() => {
            localStorage.setItem("tncPPAgreed", true);
            setIsTncOpen(false);
          }}
          style={{
            width: "100%",
            maxWidth: "200px",
          }}
        >
          Accept & continue
        </label>
      </center>
    </div>
  );

  const [isTncOpen, setIsTncOpen] = useState(false);
  const [isContactOpen, setIsContactOpen] = useState(false);
  const [isRABOpen, setIsRABOpen] = useState(false);

  const [isContacted, setIsContacted] = useState(false);
  const [contactSuccess, setContactSuccess] = useState(false);

  useEffect(() => {
    if (!localStorage.getItem("tncPPAgreed")) {
      setIsTncOpen(true);
    }
  }, []);

  return (
    <div className="w-100 pb-5 row gx-3 gy-3 gy-md-0">
      <div className="col-md-3">
        <h5>
          <b>@Connect</b>
        </h5>
        <hr className="w-50 mt-0 mb-4" />
        {Object.keys(links).map((key, ind) => (
          <span
            className="general-nmphc-des"
            style={{
              padding: "7px 10px",
              marginRight: "15px",
              fontSize: "22px",
              borderRadius: "50%",
            }}
            key={ind}
          >
            <a
              key={key}
              href={links[key].url}
              className="text-color-themed m-0"
              target="_blank"
              rel="noopener noreferrer"
            >
              <i className={links[key].icon} />
            </a>
          </span>
        ))}
      </div>
      <div className="col-md-3">
        <h5>
          <b>Report a bug</b>
        </h5>
        <hr className="w-50 mt-0 mb-4" />
        <Link to="/">
          <button
            type="button"
            className="btn rounded-pill px-4 general-nmphc-des"
            style={{
              fontWeight: 400,
              color: "var(--text-color)",
            }}
            onClick={() => {
              setIsRABOpen(true);
            }}
          >
            Bug Bounty
          </button>
        </Link>
      </div>
      <div className="col-md-3">
        <h5>
          <b>Contact</b>
        </h5>
        <hr className="w-50 mt-0 mb-4" />
        <Link to="/">
          <button
            type="button"
            className="btn rounded-pill px-4 general-nmphc-des"
            style={{
              fontWeight: 400,
              color: "var(--text-color)",
            }}
            onClick={() => {
              setIsContactOpen(true);
            }}
            disabled={contactSuccess}
          >
            Get in touch
          </button>
        </Link>
      </div>
      <div className="col-md-3">
        <h5>
          <b>Read carefully</b>
        </h5>
        <hr className="w-50 mt-0 mb-4" />
        <Link to="/terms-and-conditions">
          <button
            type="button"
            className="btn rounded-pill px-4 general-nmphc-des"
            style={{
              fontWeight: 400,
              color: "var(--text-color)",
            }}
          >
            T&C
          </button>
        </Link>
        <Link to="/privacy-policy">
          <button
            type="button"
            className="btn ms-3 rounded-pill px-4 general-nmphc-des"
            style={{
              fontWeight: 400,
              color: "var(--text-color)",
            }}
          >
            Privacy Policy
          </button>
        </Link>
      </div>
      {isTncOpen && (
        <div>
          <ModalQF
            dataClearFunc={setIsTncOpen}
            dataClearTo={false}
            header="T&C | Privacy Policy"
            closeVar={setIsTncOpen}
            closeVal={false}
            data={tnc}
            isExpandBtn={false}
            closeBtn={false}
          />
        </div>
      )}
      {isContactOpen && !isContacted && (
        <div>
          <ModalQF
            dataClearFunc={setIsContactOpen}
            dataClearTo={false}
            header="Let's get in touch"
            closeVar={setIsContactOpen}
            closeVal={false}
            data={
              <Contact
                closeFunc={(isSuccess) => {
                  setIsContactOpen(false);
                  if (isSuccess) {
                    setIsContacted(true);
                  }
                  setContactSuccess(isSuccess);
                }}
              />
            }
            isExpandBtn={false}
            responsive={true}
          />
        </div>
      )}
      {isRABOpen && (
        <div>
          <ModalQF
            dataClearFunc={setIsRABOpen}
            dataClearTo={false}
            header="Report a bug"
            closeVar={setIsRABOpen}
            closeVal={false}
            data={
              <RAB
                closeFunc={() => {
                  setIsRABOpen(false);
                }}
              />
            }
            isExpandBtn={false}
            responsive={true}
          />
        </div>
      )}
    </div>
  );
};

export default Footer;
