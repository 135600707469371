import sha1 from "crypto-js/sha1";
import { backURL } from "../api";
import hawkQuiver from "./hawkQuiver";

const hawkOnDev = false;

async function hawkGetIp() {
  const res = await fetch("https://httpbin.org/ip");
  const data = await res.json();
  let ip = data.origin; // correctly access the IP
  localStorage.setItem("myip", ip);
  return ip;
}

async function getIp() {
  hawkArrows.promised.ip = await hawkGetIp();
}

const hawkArrows = {
  promised: {
    ip:
      localStorage.getItem("myip") ||
      (async () => {
        return await hawkGetIp();
      })(),
    uuid: (() => {
      let uuid =
        localStorage.getItem("myuuid") ||
        (() => {
          let a = 'QF-HAWK-SAYS-CRYPTO-NOT-SUPPORT'
          try{
            a = crypto?.randomUUID()
          }catch (error) {
            console.log('QF-HAWK-SAYS-CRYPTO-NOT-SUPPORT')
            // alert('QF-HAWK-SAYS-CRYPTO-NOT-SUPPORT')
          }
          localStorage.setItem("myuuid", a);
          return a;
        })();
      return uuid;
    })(),
  },
};

function detectBrowser() {
  var userAgent = navigator.userAgent;
  if (userAgent.indexOf("Edg") > -1) {
    return "Microsoft Edge";
  } else if (userAgent.indexOf("Chrome") > -1) {
    return "Chrome";
  } else if (userAgent.indexOf("Firefox") > -1) {
    return "Firefox";
  } else if (userAgent.indexOf("Safari") > -1) {
    return "Safari";
  } else if (userAgent.indexOf("Opera") > -1) {
    return "Opera";
  } else if (
    userAgent.indexOf("Trident") > -1 ||
    userAgent.indexOf("MSIE") > -1
  ) {
    return "Internet Explorer";
  }

  return "Unknown";
}

function hawkGetLoadTime() {
  const startTime = performance?.timing?.navigationStart || 0;
  const endTime = performance?.timing?.loadEventEnd || 0;
  return endTime - startTime;
}

function hawkGetPaintMatrix() {
  const paintMatrix = performance.getEntriesByType("paint");
  return {
    firstPaint: paintMatrix[0]?.startTime || 0,
    firstContentfulPaint: paintMatrix[1]?.startTime || 0,
    paintMatrix,
  };
}

function hawkGetNavigationMatrix() {
  const navigationMatrix = performance.getEntriesByType("navigation")[0];
  const {
    navigationStart,
    unloadEventStart,
    unloadEventEnd,
    redirectStart,
    redirectEnd,
    fetchStart,
    domainLookupStart,
    domainLookupEnd,
    connectStart,
    connectEnd,
    secureConnectionStart,
    requestStart,
    responseStart,
    responseEnd,
    domLoading,
    domInteractive,
    domContentLoadedEventStart,
    domContentLoadedEventEnd,
    domComplete,
    loadEventStart,
    loadEventEnd,
  } = navigationMatrix;

  const timings = {
    overall: {
      totalLoadTime: loadEventEnd - navigationStart,
      totalResponseTime: responseEnd - requestStart,
    },
    breakdown: {
      redirectTime: redirectEnd - redirectStart,
      dnsLookupTime: domainLookupEnd - domainLookupStart,
      tcpConnectTime: connectEnd - connectStart,
      requestSentTime: responseStart - requestStart,
      responseReceiveTime: responseEnd - responseStart,
      domLoadingTime: domInteractive - domLoading,
      domContentLoadedTime: domContentLoadedEventEnd - domInteractive,
      domFullyLoadedTime: domComplete - domContentLoadedEventEnd,
      loadEventTime: loadEventEnd - loadEventStart,
    },
    other: {
      unloadEventTime: unloadEventEnd - unloadEventStart,
      navigationToUnload: unloadEventStart - navigationStart,
    },
  };

  return {
    navigationMatrix,
    timings,
  };
}

function hawkGetHeapSize() {
  return {
    heapUsed:
      (performance.memory?.usedJSHeapSize / (1024 * 1024)).toFixed(2) + " MB",
    heapTotal:
      (performance.memory?.totalJSHeapSize / (1024 * 1024)).toFixed(2) + " MB",
    jsHeapSizeLimit:
      (performance.memory?.jsHeapSizeLimit / (1024 * 1024)).toFixed(2) + " MB",
  };
}

function hawkGetResourceMatrix() {
  const resourceMatrix = performance.getEntriesByType("resource");
  return {
    resourceMatrix,
    combinedLoadTime:
      resourceMatrix.reduce(
        (acc, resource) => acc + resource.responseEnd - resource.requestStart,
        0
      ) /
        1000 +
      "S",
  };
}

export const hawkReportTypeIdentifiers = {
  apiError: "ERR | API",
  initReport: "INIT REPORT",
  sessionEndReport: "SESSION END REPORT",
  feUnexError: "FE | UNEXPECTED",
  reportBug: "USER RAB"
};

const hawkActivatedOn = [
  hawkReportTypeIdentifiers.initReport,
  hawkReportTypeIdentifiers.sessionEndReport,
  hawkReportTypeIdentifiers.reportBug,
];

export const hawkReport = async (error, additionalData, errType) => {
  if (!hawkArrows.promised.ip) {
    await getIp(); // Wait until IP is resolved
  }
  const hawk = {
    errorMessage: error?.toString() || "NA",
    errorStack: error?.stack?.toString() || "NA",
    language: navigator.language,
    userAgent: navigator.userAgent,
    platform: navigator.platform,
    hardwareConcurrency: navigator.hardwareConcurrency,
    deviceMemory: navigator.deviceMemory || 0,
    online: navigator.onLine,
    cookieEnabled: navigator.cookieEnabled,
    screenWidth: window.screen.width,
    screenHeight: window.screen.height,
    tz: new Date(),
    tl: new Date().toLocaleString(),
    browser: detectBrowser(),
    additionalData: {
      ...additionalData,
      ...(hawkActivatedOn.includes(errType)
        ? {
            hawkeye: {
              getLoadTime: hawkGetLoadTime(),
              getJourney: hawkQuiver.getState().journey,
              getSessionDuration:
                (new Date().getTime() - performance.timing.navigationStart) /
                  1000 +
                "S",
              getFullTimimg: performance.timing,
              getPerformance: {
                paint: hawkGetPaintMatrix(),
                navigation: hawkGetNavigationMatrix(),
                resource: hawkGetResourceMatrix(),
              },
            },
          }
        : {}),
      ...hawkArrows,
      heapMemory: hawkGetHeapSize(),
      pageHistory: window.history.length,
      referrer: document.referrer,
      currentURL: window.location.href,
    },
    errType,
  };

  const pluidtoreturn = `ERROR_pluid_${sha1(JSON.stringify(hawk))}`
  try {
    const hawkPrepareToReport = {
      ...hawk,
      erruid: `ERROR_erruid_${sha1(hawk.errorMessage)}`,
      pluid: pluidtoreturn,
    };
    if (
      !hawkOnDev &&
      hawkPrepareToReport.additionalData.currentURL.includes(":3000")
    ) {
      console.log('QF-JOURNAL-HAWKREPORT', hawkPrepareToReport);
    } else {
      fetch(`${backURL}/utils/logs`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(hawkPrepareToReport),
      });
    }
  } catch (err) {
    console.error("QF-JOURNAL-HAWKCRITICAL", err);
  }

  return pluidtoreturn
};
