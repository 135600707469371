import React, { useEffect, useRef, useState } from "react";
import * as echarts from "echarts";
import { commonApiGet } from "../../../utils/api";
import { notificationTopup } from "../../../utils/NotificationTopup";
import { isMobile, themedColor } from "../../../utils/configs";
import { useParams } from "react-router-dom";

const VisSectorHeatMap = ({ symbol, name }) => {
  const chartRef = useRef(null); // Reference for the chart DOM element

  const [data, setData] = useState([]);

  const { exchange } = useParams();

  const customColorCodes = [
    "#2c3e50", // Dark Blue
    "#34495e", // Dark Slate Blue
    "#1abc9c", // Dark Cyan
    "#16a085", // Dark Teal
    "#2980b9", // Dark Sky Blue
    "#3498db", // Light Blue
    "#8e44ad", // Dark Purple
    "#9b59b6", // Medium Purple
    "#d35400", // Dark Orange
    "#e67e22", // Orange
    "#f39c12", // Bright Orange
    "#f1c40f", // Bright Yellow
    "#4b0082", // Indigo
    "#5d3fd3", // Dark Violet
    "#3f51b5", // Indigo
    "#6a5acd", // Slate Blue
    "#7b68ee", // Medium Slate Blue
    "#483d8b", // Dark Slate Blue
    "#2e8b57", // Sea Green (not too bright)
    "#4682b4", // Steel Blue
    "#4169e1", // Royal Blue
    "#00008b", // Dark Blue
    "#191970", // Midnight Blue
    "#3b3b3b", // Dark Charcoal
];

  useEffect(() => {
    // Fetching data from the API
    (async () => {
      const customMapping = {
        "Nifty Fin Service": "Nifty Financial Services",
      };
      await commonApiGet(
        `/indices/sector_heatmap?symbol=${encodeURIComponent(
          customMapping[symbol] || symbol
        )}`,
        "INDEX_SEC_HM_01",
        true,
        false
      )
        .then((res) => {
          setData(res.data.sectorHeatmap.groups); // Updating the state with sector heatmap groups
        })
        .catch(async (err) => {
          await commonApiGet(
            `/indices/sector_heatmap?symbol=${encodeURIComponent(
              customMapping[name] || name
            )}`,
            "INDEX_SEC_HM_02",
            true,
            false
          )
            .then((res) => {
              setData(res.data.sectorHeatmap.groups);
            })
            .catch((err) => {
              notificationTopup(err.message);
            });
        });
    })();
  }, [symbol, name]);

  useEffect(() => {
    const myChart = echarts.init(chartRef.current); // Initialize the chart

    const chartData = data.map((sector, index) => {
      console.log(customColorCodes[index % customColorCodes.length], index, customColorCodes, )
      return {
      name: sector.label.split(" ").slice(0, -1).join(" "), // Sector name
      value: sector.weight, // Sector weight
      children: sector.groups.map((stock) => ({
        name: stock.label.split(" ").slice(0, -1).join(" "), // Stock name
        value: stock.weight, // Stock weight
      })),
      itemStyle: {
        color:
          customColorCodes[index % customColorCodes.length], // Sector color
      },
      link: `/info/${exchange}/${symbol}`,
    }});

    const option = {
      animation: true,
      title: {
        text: "Sector Performance",
        left: "center",
        show: false,
      },
      label: {
        // formatter: "{testing|{b}: {c}%}",
        formatter: "{b}: {c}%",
        // rich:{
        //   testing:{
        //     // color: themedColor.light
        //   }
        // },
        position: ["50%", "50%"],
      },
      tooltip: {
        trigger: "item",
        // '{budget|$ ' +
        // echarts.format.addCommas(params.value[0]) +
        // '} {label|budget}'
        formatter: "<b>{b}</b>: {c}%",
      },
      series: [
        {
          height: '85%',
          width: '100%',
          name: "Sector",
          type: "treemap",
          visibleMin: 300,
          data: chartData,
          leafDepth: data.length === 1 ? 2 : 1, // Setting the depth to 2
          levels: [
            {
              itemStyle: {
                borderColor: "var(--text-color)",
                borderWidth: 1,
                gapWidth: 1,
                color: "rgba(255, 255, 255, 0.6)", // Brightened level one background color (example)
                textStyle: {
                  color: "#000", // Add text color for level one
                },
              },
            },
            {
              colorSaturation: [0.3, 0.5],
              itemStyle: {
                borderColorSaturation: 0.6,
                gapWidth: 1,
                textStyle: {
                  color: "#000", // Add text color for level two
                },
              },
            },
          ],
        },
      ],
    };

    myChart.setOption(option); // Set the options for the chart

    // Cleanup function to dispose of the chart instance on unmount
    return () => {
      myChart.dispose();
    };
  }, [data]);

  return (
    <div
      ref={chartRef}
      style={{ width: "100%", height: isMobile() ? "200px" : "350px" }}
    />
  );
};

export default VisSectorHeatMap;
