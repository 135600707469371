import React, { useEffect, useState } from "react";
import * as echarts from "echarts";
import {
  chartColor,
  formatDateAO,
  getDateRangeAO,
  isMobile,
  lastDays,
  themedColor,
} from "../../../utils/configs";
import { useSelector } from "react-redux";
import { commonApiGet } from "../../../utils/api";
import {
  ButtonDropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap";
import DateTimePicker from "../../../utils/VisionExamples/DateTimePicker";

const VisPreviewChart = ({ symbol, exchange, name }) => {
  const theme = useSelector((state) => state.theme);
  const upColor = chartColor.green;
  const downColor = chartColor.red;

  const [options, setOptions] = useState({
    ONE_MINUTE: 30,
    THREE_MINUTE: 60,
    FIVE_MINUTE: 100,
    TEN_MINUTE: 100,
    FIFTEEN_MINUTE: 200,
    THIRTY_MINUTE: 200,
    ONE_HOUR: 400,
    ONE_DAY: 2000,
  });

  const [selectedOption, setSelectedOption] = useState("ONE_DAY");

  let dates = getDateRangeAO(options[selectedOption]);
  const [dtpvalue, ondtpChange] = useState([dates.from, dates.today]);

  const splitData = (rawData) => {
    let categoryData = [];
    let values = [];
    let volumes = [];
    for (let i = 0; i < rawData.length; i++) {
      categoryData.push(rawData[i].splice(0, 1)[0]);
      values.push([
        rawData[i][0],
        rawData[i][3],
        rawData[i][2],
        rawData[i][1],
        rawData[i][4],
      ]); // Format is [open, close, lowest, highest]
      volumes.push([i, rawData[i][4], rawData[i][0] < rawData[i][3] ? 0 : 1]);
    }
    return {
      categoryData: categoryData,
      values: values,
      volumes: volumes,
    };
  };

  const calculateMA = (dayCount, data) => {
    const result = [];
    for (let i = 0, len = data.values.length; i < len; i++) {
      if (i < dayCount) {
        result.push("-");
        continue;
      }
      let sum = 0;
      for (let j = 0; j < dayCount; j++) {
        sum += data.values[i - j][1];
      }
      result.push(+(sum / dayCount).toFixed(3));
    }
    return result;
  };

  const calculateVWAP = (data) => {
    const result = [];
    let cumulativeVolume = 0;
    let cumulativeValue = 0;
    for (let i = 0; i < data.values.length; i++) {
      cumulativeVolume += data.values[i][4];
      cumulativeValue += data.values[i][1] * data.values[i][4];
      result.push((cumulativeValue / cumulativeVolume).toFixed(2));
    }
    return result;
  };

  useEffect(() => {
    const chartDom = document.getElementById("echarts-main");
    const myChart = echarts.init(chartDom);

    commonApiGet(
      `/stocks/getCandles?symbol=${encodeURIComponent(
        symbol
      )}&interval=${selectedOption}&exchange=${exchange}&fromdate=${formatDateAO(
        dtpvalue[0]
      )}&todate=${formatDateAO(dtpvalue[1])}`,
      "EQUITY_PAGE_CHART_PREV_VIS",
      false,
      false
    ).then((response) => {
      const rawData = response.data;
      const data = splitData(rawData.data);

      const option = {
        width: "94%",
        animation: true,
        legend: {
          bottom: 10,
          left: "center",
          data: [name, "MA50", "MA200", "VWAP"],
          selected: {
            [name]: true,
            MA50: false,
            MA200: false,
            VWAP: true,
          },
          textStyle: {
            color: theme === "dark" ? themedColor.dark : themedColor.light,
          },
        },
        tooltip: {
          // trigerOn: "click",
          // show: false,
          // showContent: false,
          className: "customEchartohlcTooltip glassmorfy-it general-nmphc-des",
          trigger: "axis",
          
          axisPointer: {
            type: "cross",
          },
          borderWidth: 1,
          borderColor: "#ccc",
          padding: 10,
          textStyle: {
            color: "#000",
            // size: '10px'
            fontSize: "6",
            // lineHeight: '0'
          },
          position: function (pos, params, el, elRect, size) {
            const obj = { top: 10, left: 10 };
            // obj[["left", "right"][+(pos[0] < size.viewSize[0] / 2)]] = 30;
            return obj;
          },
        },
        axisPointer: {
          link: [{ xAxisIndex: "all" }],
          label: { backgroundColor: "#777" },
        },
        toolbox: {
          feature: {
            dataZoom: { yAxisIndex: false },
            brush: { type: ["lineX", "clear"] },
            saveAsImage: {},
          },
        },
        brush: {
          xAxisIndex: "all",
          brushLink: "all",
          outOfBrush: { colorAlpha: 0.1 },
        },
        visualMap: {
          show: false,
          seriesIndex: 5,
          dimension: 2,
          pieces: [
            { value: 1, color: downColor },
            { value: -1, color: upColor },
          ],
        },
        grid: [
          { left: "3%", right: "0%", height: "50%" },
          { left: "3%", right: "0%", top: "63%", height: "16%" },
        ],
        xAxis: [
          {
            type: "category",
            data: data.categoryData,
            boundaryGap: false,
            axisLabel: { show: false },
            axisLine: { onZero: false },
            splitLine: { show: false },
            min: "dataMin",
            max: "dataMax",
            axisPointer: { z: 100, show: true },
          },
          {
            type: "category",
            gridIndex: 1,
            data: data.categoryData,
            boundaryGap: false,
            axisLine: { onZero: false },
            axisTick: { show: false },
            splitLine: { show: false },
            axisLabel: { show: false },
            min: "dataMin",
            max: "dataMax",
            axisPointer: {
              type: "shadow",
              label: { show: false },
              triggerTooltip: true,
              handle: {
                show: true,
                margin: 30,
                color: themedColor[theme],
              },
            },
          },
        ],
        yAxis: [
          {
            scale: true,
            splitArea: { show: false },
            axisLabel: { show: false },
            splitLine: { show: true, lineStyle: { color: "#a0a0a025" } }, // Change color here
          },
          {
            scale: true,
            gridIndex: 1,
            splitNumber: 2,
            axisLabel: { show: false },
            axisLine: { show: false },
            axisTick: { show: false },
            splitLine: { show: true, lineStyle: { color: "#a0a0a025" } }, // Change color here
          },
        ],
        dataZoom: [
          {
            type: "slider",
            xAxisIndex: [0, 1],
            realtime: false,
            start: 90,
            end: 100,
            top: 25,
            height: 20,
            handleIcon:
              "path://M10.7,11.9H9.3c-4.9,0.3-8.8,4.4-8.8,9.4c0,5,3.9,9.1,8.8,9.4h1.3c4.9-0.3,8.8-4.4,8.8-9.4C19.5,16.3,15.6,12.2,10.7,11.9z M13.3,24.4H6.7V23h6.6V24.4z M13.3,19.6H6.7v-1.4h6.6V19.6z",
            handleSize: "120%",
          },
        ],
        series: [
          {
            name: name,
            type: "candlestick",
            data: data.values,
            itemStyle: {
              color: upColor,
              color0: downColor,
              borderColor: undefined,
              borderColor0: undefined,
            },
          },
          {
            name: "MA50",
            type: "line",
            data: calculateMA(50, data),
            smooth: true,
            lineStyle: { opacity: 0.5 },
          },
          {
            name: "MA200",
            type: "line",
            data: calculateMA(200, data),
            smooth: true,
            lineStyle: { opacity: 0.5 },
          },
          {
            name: "VWAP",
            type: "line",
            data: calculateVWAP(data),
            smooth: true,
            lineStyle: {
              normal: { opacity: 0.5 },
            },
          },
          {
            name: "Volume",
            type: "bar",
            xAxisIndex: 1,
            yAxisIndex: 1,
            data: data.volumes,
            itemStyle: {
              color: function (params) {
                return data.values[params.dataIndex][1] >
                  data.values[params.dataIndex][0]
                  ? upColor
                  : downColor;
              },
            },
          },
        ],
      };

      myChart.setOption(option);
    });

    return () => {
      myChart.dispose(); // Cleanup function to dispose of chart
    };
  }, [theme, symbol, selectedOption, dtpvalue]);

  const [dropdownOptionOpen, setDropdownOptionOpen] = useState(false);
  const ddootoggle = () => setDropdownOptionOpen((prevState) => !prevState);

  return (
    <div>
      <div
        style={{
          top: 20,
          position: "absolute",
          left: 20,
          zIndex: 5,
          display: "flex",
        }}
      >
        <ButtonDropdown
          isOpen={dropdownOptionOpen}
          toggle={ddootoggle}
          className="tab-weight"
        >
          <DropdownToggle
            caret
            size="sm"
            color="transparent"
            className="general-nmphc-des text-color-themed mt-1 me-2"
            style={{
              fontSize: "12px",
            }}
          >
            {selectedOption.replaceAll("_", " ")}
          </DropdownToggle>
          <DropdownMenu
            className="general-nmphc-des non-trans-bg"
            style={{
              maxHeight: "220px",
              overflowY: "scroll",
            }}
          >
            {Object.keys(options).map((val, key) => (
              <DropdownItem
                className="bg-set-on-active text-color-themed"
                key={`QF_EQ_CHARTPREVIEW_${key}`}
                onClick={() => {
                  setSelectedOption(val);
                }}
                style={{
                  fontSize: "12px",
                }}
                disabled={val === selectedOption ? true : false}
              >
                {val.replaceAll("_", " ")}
              </DropdownItem>
            ))}
          </DropdownMenu>
        </ButtonDropdown>
        <div className="mt-1">
          <DateTimePicker onChange={ondtpChange} value={dtpvalue} />
        </div>
      </div>
      <br />
      <br />
      <br />
      <div
        id="echarts-main"
        style={{ width: "100%", height: isMobile() ? "300px" : "500px" }}
      />
    </div>
  );
};

export default VisPreviewChart;
