import React, { useEffect, useState } from "react";
import { commonApiGet } from "../../utils/api";
import { notificationTopup } from "../../utils/NotificationTopup";
import { Link } from "react-router-dom";
import {
  ButtonDropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap";
import CommonCardView from "../vision/CommonCardView";
import VisOops from "../vision/VisOops";
import QFLoader from "../vision/QFLoader";

const NewsCorp = () => {
  const [newsData, setNewsData] = useState([]);
  const [option, setOption] = useState("ca");
  const [dropdownOptionOpen, setDropdownOptionOpen] = useState(false);
  const ddootoggle = () => setDropdownOptionOpen((prevState) => !prevState);
  const [loaderShow, setLoaderShow] = useState(true);
  const [errorShow, setErrorShow] = useState(false);

  const optionController = {
    ca: "Corporate Action",
    cann: "Corporate Announcement",
    evc: "Event Calendar",
    eqbm: "Equity Board Meeting",
  };
  useEffect(() => {
    (async () => {
      await commonApiGet(`/market/market_news?reqType=${option}`)
        .then((res) => {
          setNewsData(res.data);
          setLoaderShow(false);
          setErrorShow(false);
        })
        .catch((err) => {
          notificationTopup(err.message);
          setErrorShow(true);
          setLoaderShow(false);
        });
    })();
  }, [option]);
  return (
    <CommonCardView
      id="commonCard_newsCorp"
      header="News"
      headerAlign="left"
      style={{
        position: "relative",
      }}
      headerInfo={{
        show: true,
        desc: "NEWS",
        id: "news-corp",
      }}
    >
      <ButtonDropdown
        isOpen={dropdownOptionOpen}
        toggle={ddootoggle}
        className="tab-weight absolute-tabGrp"
      >
        <DropdownToggle
          caret
          size="sm"
          color="transparent"
          className="general-nmphc-des text-color-themed mt-1"
          style={{
            fontSize: "12px",
          }}
        >
          {optionController[option].toUpperCase()}
        </DropdownToggle>
        <DropdownMenu
          className="general-nmphc-des non-trans-bg"
          style={{
            maxHeight: "220px",
            overflowY: "scroll",
          }}
        >
          {Object.keys(optionController).map((val, key) => (
            <DropdownItem
              className="bg-set-on-active text-color-themed"
              key={`dd_news_section_corp_dd_${key}`}
              onClick={() => {
                setLoaderShow(true);
                setNewsData([]);
                setOption(val);
              }}
              style={{
                fontSize: "12px",
              }}
              disabled={val === option ? true : false}
            >
              {optionController[val]}
            </DropdownItem>
          ))}
        </DropdownMenu>
      </ButtonDropdown>
      {errorShow ? (
        <VisOops />
      ) : loaderShow ? (
        <QFLoader />
      ) : (
        <div
          className="row no-scrbar h-min-full"
          style={{
            flexWrap: "nowrap",
            overflowY: "scroll",
          }}
        >
          {option === "cann" &&
            newsData.length > 0 &&
            newsData.map((val, index) => (
              <div
                className="col-md-5 p-3 h-min-full"
                style={{
                  maxWidth: "90%",
                }}
                key={`news_news_${option}_${index}`}
              >
                <div className="general-nmphc-des p-3">
                  <h6 className="p-2">{val["sm_name"].substr(0, 25)}</h6>

                  <div
                    className="left-border-div-sec m-0 mt-1 p-3"
                    style={{
                      height: "260px",
                      overflowY: "scroll",
                    }}
                  >
                    <div className="row">
                      <div className="col-6">{val.symbol}</div>
                      <div className="col-6">{val.an_dt.split(" ")[0]}</div>
                    </div>
                    <hr />
                    {val.desc}
                    <hr />
                    {val.attchmntText}

                    {val.attchmntFile.includes(".pdf") && (
                      <>
                        <hr />
                        <Link
                          to={val.attchmntFile}
                          target="_blank"
                          style={{
                            color: "var(--text-color)",
                            textDecoration: "none",
                          }}
                        >
                          <i className="fas fa-file"></i> Attachment
                        </Link>
                      </>
                    )}
                  </div>
                </div>
              </div>
            ))}
          {option === "ca" &&
            newsData.length > 0 &&
            newsData.map((val, index) => (
              <div
                className="col-md-5 p-3 h-min-full"
                style={{
                  maxWidth: "90%",
                }}
                key={`news_news_${option}_${index}`}
              >
                <div className="general-nmphc-des p-3">
                  <h6 className="p-2">{val["comp"].substr(0, 25)}</h6>

                  <div
                    className="left-border-div-sec m-0 mt-1 p-3"
                    style={{
                      height: "260px",
                      overflowY: "scroll",
                    }}
                  >
                    {val.subject}
                    <hr />
                    <div className="row">
                      <div className="col-6">RecDate</div>
                      <div className="col-6">{val.recDate}</div>
                    </div>
                    <hr />
                    <div className="row">
                      <div className="col-6">ExDate</div>
                      <div className="col-6">{val.exDate}</div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          {option === "evc" &&
            newsData.length > 0 &&
            newsData.map((val, index) => (
              <div
                className="col-md-5 p-3 h-min-full"
                style={{
                  maxWidth: "90%",
                }}
                key={`news_news_${option}_${index}`}
              >
                <div className="general-nmphc-des p-3">
                  <h6 className="p-2">{val["company"].substr(0, 25)}</h6>

                  <div
                    className="left-border-div-sec m-0 mt-1 p-3"
                    style={{
                      height: "260px",
                      overflowY: "scroll",
                    }}
                  >
                    {val.symbol}
                    <hr />
                    {val.purpose}
                    <hr />
                    {val.bm_desc}
                    <hr />
                    {val.date}
                  </div>
                </div>
              </div>
            ))}

          {option === "eqbm" &&
            newsData.length > 0 &&
            newsData.map((val, index) => (
              <div
                className="col-md-5 p-3 h-min-full"
                style={{
                  maxWidth: "90%",
                }}
                key={`news_news_${option}_${index}`}
              >
                <div className="general-nmphc-des p-3">
                  <h6 className="p-2">{val["sm_name"].substr(0, 25)}</h6>

                  <div
                    className="left-border-div-sec m-0 mt-1 p-3"
                    style={{
                      height: "260px",
                      overflowY: "scroll",
                    }}
                  >
                    <div className="row">
                      <div className="col-6">{val.bm_symbol}</div>
                      <div className="col-6">
                        {val.bm_timestamp.split(" ")[0]}
                      </div>
                    </div>
                    <hr />
                    {val.bm_purpose}
                    <hr />
                    {val.bm_desc}
                    <hr />
                    <div className="row">
                      <div className="col-6">{val.bm_date}</div>
                      <div className="col-6">
                        {val.attachment.includes(".pdf") && (
                          <>
                            <Link
                              to={val.attachment}
                              target="_blank"
                              style={{
                                color: "var(--text-color)",
                                textDecoration: "none",
                              }}
                            >
                              <i className="fas fa-file"></i> Attachment
                            </Link>
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
        </div>
      )}
    </CommonCardView>
  );
};

export default NewsCorp;
